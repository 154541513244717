import React, {useEffect} from 'react';
import {Grid, Typography, Box} from "@mui/material";
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";

import {connect} from "react-redux";
import {getDashboard} from "../../redux/actions/userDataActions";

import styles from './Index.module.css'

const Index = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    props.getDashboard();
  }, [])

  return (
    <Container page={"index"}>
      <>
        <Typography className={styles.dashboardText}>Dashboard</Typography>

        <Grid item md={12} xs={12} className={styles.countBorderBottomView} mt={1} mb={3}/>

        <Grid container item md={12} xs={12}>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/product')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalProducts} Products</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/product?status=active')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalActiveProducts} Active
                Products</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/product?status=inactive')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalInactiveProducts} Inactive
                Products</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/product?status=removed')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalRemovedProducts} Removed
                Products</Typography>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} className={styles.countBorderBottomView} mb={2}/>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/orders')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalOrders} Orders</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/orders?status=pending')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalPendingOrders} Pending
                Orders</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/orders?status=unshipped')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalUnshippedOrders} Unshipped
                Orders</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/orders?status=shipped')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalShippedOrders} Shipped
                Orders</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/orders?status=delivered')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalDeliveredOrders} Delivered
                Orders</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/orders?status=cancelled')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalCancelledOrders} Cancelled
                Orders</Typography>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} className={styles.countBorderBottomView} mb={2}/>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/seller')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalSellers} Sellers</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/seller?status=active')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalActiveSellers} Active Sellers</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/seller?status=suspended')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalSuspendedSellers} Suspended Sellers</Typography>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} className={styles.countBorderBottomView} mb={2}/>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/customers')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalBuyers} Buyers</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/customers?status=active')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalActiveBuyers} Active Buyers</Typography>
            </Grid>
          </Grid>

          <Grid item md={2.6} xs={12} mb={2}>
            <Grid item md={11} xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  className={styles.cardDiv}
                  onClick={() => navigate('/customers?status=suspended')}>
              <Typography className={styles.titleText}>{props?.dashboardDetail?.totalSuspendedBuyers} Suspended Buyers</Typography>
            </Grid>
          </Grid>

        </Grid>
      </>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    dashboardDetail: state.userData.dashboardDetail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDashboard: data => dispatch(getDashboard(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
