import React from 'react';
import {Box, Grid, Typography} from "@mui/material";

import FormTextInput from "./FormTextInput";
import ImageUpload from "./ImageUpload";

import {ReactComponent as CloseIcon} from "../assets/img/close_black.svg";

const UploadDocument = (props) => {

  const onRemoveDocument = (id) => {
    props.onRemoveDocument(id)
  }

  return (
    <Grid container item md={12} xs={12}
          display={'flex'} flexDirection={'row'} alignItems={'center'}
          justifyContent={'space-between'}
          mt={2}>

      <Grid container item md={5} xs={5}>
        <FormTextInput
          page={'UploadDocuments'}
          value={props?.documentName}
          setValue={(val) => props?.setDocumentName(props?.documentId, val)}
          error={props?.errorDocumentName}
          textInputViewStyle={style.textInputViewStyle}
          required={true}
        />
      </Grid>

      <Grid container item md={3} xs={3}>
        <ImageUpload
          fileType={'pdf'}
          maxFiles={1}
          image={props?.displayDocumentImage}
          error={props?.errorDocumentImage}
          onRemove={() => props?.onRemoveImage(props?.documentId)}
          onChange={(val) => props?.onChangeImage(props?.documentId, val)}/>
      </Grid>

      <Grid
        style={{cursor: 'pointer'}}
        container item md={3} xs={3} onClick={() => onRemoveDocument(props?.documentId)}>
        <CloseIcon height={20} width={20}/>
      </Grid>

    </Grid>
  )
};

export default UploadDocument;


const style = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
};


