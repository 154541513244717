import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

import styles from './ProductImageView.module.css'

import FormButton from "./FormButton";
import AlertDialogBox from "./AlertDialogBox";
import UploadMainProductImageModal from "./UploadMainProductImageModal";

import {ReactComponent as DeleteIcon} from "../assets/img/delete.svg";
import {ReactComponent as DeclineIcon} from "../assets/img/decline.svg"

import {connect} from "react-redux";
import {
  updateMainProductImage,
  deleteProductImage, updateApiStatus
} from "../redux/actions/userDataActions";

const buttonList = {
  deleted: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  }
};

const ProductImageView = (props) => {
  const [productUid, setProductUid] = useState('')
  const [productImageId, setProductImageId] = useState('')
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false)
  const [displayMainProductImage, setDisplayMainProductImage] = useState('')
  const [imageModalVisible, setImageModalVisible] = useState(false)

  useEffect(() => {
    if (props?.data) {
      let product_uid = props?.data?.uid
      if (product_uid) {
        setProductUid(product_uid);
      }
      if (props?.data?.mainImageList?.url) {
        setDisplayMainProductImage(props?.data?.mainImageList?.url)
      }
    }
  }, [props?.data])

  useEffect(() => {
    if (props.apiStatus == 'DELETE_PRODUCT_IMAGE_SUCCESS') {
      setConfirmBoxVisible(false);
      setProductImageId('');
      props.updateApiStatus('')
    } else if (props.apiStatus == 'UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS') {
      setImageModalVisible(false);
    }
  }, [props.apiStatus])

  const onUpdateMainImage = () => {
    setImageModalVisible(true)
  };

  const onDeleteProductImage = (id) => {
    setProductImageId(id);
    setConfirmBoxVisible(true);
  };

  const onConfirmClick = () => {
    let data = {
      productUid: productUid,
      productImageId: productImageId
    }
    props.deleteProductImage(JSON.stringify(data))
  }

  const onCancelClick = () => {
    setConfirmBoxVisible(false);
    setProductImageId('');
  }

  const onCloseImageModal = () => {
    setImageModalVisible(false);
  }

  const onSubmitImage = (data) => {
    props.updateMainProductImage(data)
  }

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Image</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Image ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item?.mainImageList?.url ?
              <TableRow className={styles.tableRow}>
                <TableCell>
                  <Typography className={styles.mainText} mb={1}>Main</Typography>
                  <img src={item?.mainImageList?.url} height={50} width={50}/>
                </TableCell>
                <TableCell>{item?.mainImageList?.image.split(".")?.[0]}</TableCell>
                <TableCell>{item?.mainImageList?.timestamp}</TableCell>
                <TableCell>
                  <FormButton
                    btnType={'btn5'}
                    title={'Update'}
                    onSubmit={onUpdateMainImage}
                  />
                </TableCell>
              </TableRow>
              : null}
            {item?.imageList?.length > 0 ?
              item?.imageList?.map((img, index) => {
                return (
                  <TableRow key={index} className={styles.tableRow}>
                    <TableCell>
                      <img src={img.url} height={50} width={50}/>
                    </TableCell>
                    <TableCell>{img.image.split(".")?.[0]}</TableCell>
                    <TableCell>{img.timestamp}</TableCell>
                    <TableCell
                      style={{cursor: 'pointer'}}
                      onClick={() => onDeleteProductImage(img.id)}>
                      <DeleteIcon width={20} height={20}/>
                    </TableCell>
                  </TableRow>
                );
              }) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <UploadMainProductImageModal
        open={imageModalVisible}
        productUid={productUid}
        display={displayMainProductImage}
        onClose={onCloseImageModal}
        onSubmit={onSubmitImage}/>

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={'deleted'}
        icon={buttonList['deleted']['icon']}
        content={buttonList['deleted']['content']}
        firstBtn={buttonList['deleted']['firstBtn']}
        secondBtn={buttonList['deleted']['secondBtn']}
        btnLoading={props.btnLoading}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    btnLoading: state.userData.btnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMainProductImage: data => dispatch(updateMainProductImage(data)),
    deleteProductImage: data => dispatch(deleteProductImage(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImageView);
