import React, {useEffect} from 'react'
import {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import dayjs from "dayjs";

import FormButton from "../components/FormButton";
import FormTextInput from '../components/FormTextInput';
import FormDatePicker from '../components/FormDatePicker';

import {ReactComponent as CloseIcon} from "../assets/img/close.svg";

const AddShippingCostModal = (props) => {
  const [shippingCost, setShippingCost] = useState("");
  const [loadingPort, setLoadingPort] = useState("");
  const [dischargePort, setDischargePort] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(dayjs());
  const [carrier, setCarrier] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!props.open) {
      setShippingCost('');
      setLoadingPort('');
      setDischargePort('');
      setDeliveryDate(dayjs());
      setCarrier('');
      setError(null)
    }
  }, [props.open]);

  const onCloseClick = () => {
    props.onClose();
  };

  const onSubmitBtnClick = () => {
    let errorText = {};

    if (shippingCost == '') {
      errorText = {shippingCost: 'Shipping Cost is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(shippingCost)) {
      errorText = {...errorText, shippingCost: 'Invalid Shipping Cost'};
    }
    if (loadingPort == '') {
      errorText = {...errorText, loadingPort: 'Port of Loading is required'};
    }
    if (dischargePort == '') {
      errorText = {...errorText, dischargePort: 'Port of Discharge is required'};
    }
    if (deliveryDate == '' || deliveryDate == null) {
      errorText = {...errorText, deliveryDate: 'Date of Delivery is required'};
    }
    if (carrier == '') {
      errorText = {...errorText, carrier: 'Carrier is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      cartUid: props?.cartUid,
      shippingCost: shippingCost,
      loadingPort: loadingPort,
      dischargePort: dischargePort,
      deliveryDate: dayjs(deliveryDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      carrier: carrier,
    };
    props.onSubmit(data);
  };

  return (
    <Modal
      open={props.open}
      onClose={onCloseClick}
      style={styles.modelView}
      hideBackdrop={true}>
      <Box sx={styles.modalSize}>
        <Box sx={styles.headerView}>
          <Typography align="center" sx={styles.headerTitle}>
            Add Shipping Cost
          </Typography>
          <Box sx={styles.closeBtnView} onClick={props.onClose}>
            <CloseIcon width={23} height={23}/>
          </Box>
        </Box>

        <Grid container item md={12} xs={12}
              display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
          <Grid mt={3}
                container item md={12} xs={12}>
            <FormTextInput
              page={'AddShippingCostModal'}
              value={shippingCost}
              setValue={setShippingCost}
              // label={'Shipping Cost (in ' + props?.actualCurrencySymbol + ')'}
              label={'Shipping Cost (in ₹)'}
              error={error?.shippingCost}
              required={true}
            />
          </Grid>

          <Grid mt={3}
                container item md={5.8} xs={12}>
            <FormTextInput
              page={'AddShippingCostModal'}
              value={loadingPort}
              setValue={setLoadingPort}
              label={'Port of Loading'}
              error={error?.loadingPort}
              required={true}
            />
          </Grid>

          <Grid mt={3}
                container item md={5.8} xs={12}>
            <FormTextInput
              page={'AddShippingCostModal'}
              value={dischargePort}
              setValue={setDischargePort}
              label={'Port of Discharge'}
              error={error?.dischargePort}
              required={true}
            />
          </Grid>

          <Grid mt={3}
                container item md={5.8} xs={12}>
            <FormDatePicker
              page={"AddShippingCostModal"}
              value={deliveryDate}
              minDate={dayjs()}
              label={'Date of Delivery'}
              inputFormat="DD-MM-YYYY"
              onChange={(newValue) => {
                setDeliveryDate(newValue);
              }}
              error={error?.deliveryDate}
              required={true}
            />
          </Grid>

          <Grid mt={3}
                container item md={5.8} xs={12}>
            <FormTextInput
              page={'AddShippingCostModal'}
              value={carrier}
              setValue={setCarrier}
              label={'carrier'}
              error={error?.carrier}
              required={true}
            />
          </Grid>

        </Grid>

        <Box display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box mr={2}>
            <FormButton
              btnType={'cancel'}
              title={"Cancel"}
              btnStyleView={{height: 35}}
              onSubmit={onCloseClick}
            />
          </Box>
          <Box>
            <FormButton
              loading={props.loading}
              title={"Submit"}
              btnType={'btn5'}
              btnStyleView={{height: 35}}
              onSubmit={onSubmitBtnClick}
            />
          </Box>
        </Box>

      </Box>
    </Modal>
  )
}

export default AddShippingCostModal

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalSize: {
    backgroundColor: '#FFF',
    width: {md: '50%', xs: '90%'},
    borderRadius: 2,
    padding: 4
  },
  headerView: {
    position: 'relative',
  },
  headerTitle: {
    fontFamily: 'InterBold',
    fontSize: 20
  },
  closeBtnView: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    top: 0,
    bottom: 0,
  },
};
