import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

import styles from './OrderPackingDetailView.module.css'

const OrderPackingDetailView = (props) => {

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Packing</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Packing Material</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.packageMeasurementTitleText}>Package Net Weight</Typography>
                <Typography className={styles.packageMeasurementSubText} ml={1}>Package Gross Weight</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.packageMeasurementTitleText}>Package Measurements</Typography>
                <Typography className={styles.packageMeasurementSubText} ml={1}>Package Volume</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Total Number of Packages</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Cargo Gross Weight</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Cargo Gross Measurement</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.package_net_weight ? item?.package_net_weight + item?.unit + ' ' + item?.packing_material : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.packing_material ? item?.packing_material : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>Package Net
                  Weight: {item?.package_net_weight ? (item?.package_net_weight + item?.unit) : '-'}</Typography>
                <Typography className={styles.subValueText} mt={1}>Package Gross
                  Weight: {item?.package_gross_weight ? (item?.package_gross_weight + item?.unit) : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.length ? (item.length + 'cm X ' + item.width + 'cm X ' + item.height + 'cm') : ''}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  {item?.length ? (((item?.length * item?.width * item?.height) / 1000000)) : '0'} m<sup>3</sup>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.quantity ? item?.quantity : 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.package_gross_weight ? (item?.package_gross_weight * item?.quantity + ' ' + item?.unit) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.length ? (((item?.length * item?.width * item?.height) / 1000000) * item?.quantity) : '0'} m<sup>3</sup>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default OrderPackingDetailView;
