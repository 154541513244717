import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';

import FormButton from "./FormButton";

import styles from './CartReviewPendingList.module.css'

import {getCurrencyConversionCalc} from "../assets/functions/common";

import {COUNTRIES} from "../constants/countries";

const CartReviewPendingList = (props) => {
  const [productCount, setProductCount] = useState(0);
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [actualPrice, setActualPrice] = useState(0);
  const [actualPricePerKg, setActualPricePerKg] = useState(0);
  const [actualTotalPrice, setActualTotalPrice] = useState(0);
  const [actualCurrencySymbol, setActualCurrencySymbol] = useState('')
  const [showError, setShowError] = useState('');

  useEffect(() => {
    if (props.data) {
      setShowError(props.data.error);
    }
  }, [props.data.error]);

  useEffect(() => {
    if (props.data) {
      setProductCount(props.data.quantity);
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let currency_symbol = '';
        let price_val = 0;
        let price_per_kg = 0;
        let total_price = 0;
        let actual_currency_symbol = '';
        let actual_price_val = 0;
        let actual_price_per_kg = 0;
        let actual_total_price = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: 0,
          currency: props?.data?.seller_currency,
          admin_currency: props?.data?.admin_currency,
          admin_currency2: props?.data?.admin_currency2,
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          actual_price_val = data?.price;
          actual_price_per_kg = data?.price_per_kg;
          actual_total_price = data?.total_price;
          actual_currency_symbol = data?.currency_symbol;
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          currency_symbol = data?.admin_currency_symbol;
        }
        let order_quantity = props?.data?.quantity * props?.data?.package_net_weight;

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setCurrencySymbol(currency_symbol)
        setActualPrice(actual_price_val);
        setActualTotalPrice(actual_total_price)
        setActualPricePerKg(actual_price_per_kg);
        setActualCurrencySymbol(actual_currency_symbol)
        setOrderQuantity(order_quantity);

        // let price_val = 0;
        // let total_price = 0;
        // let price_per_kg = 0;
        // let order_quantity = 0;
        // if (props?.data?.price_per_package != '' && props?.data?.package_net_weight != '') {
        //   price_val = (parseFloat((((props?.data?.price_per_package / props?.data?.package_net_weight) * props?.data?.referral_fee) / 100) + (props?.data?.price_per_package / props?.data?.package_net_weight)) * props?.data?.package_net_weight)?.toFixed(2)
        //   order_quantity = props.data.quantity * props?.data?.package_net_weight;
        //   price_per_kg = price_val / props?.data?.package_net_weight;
        //   total_price = props.data.quantity * price_val
        //
        //   setPrice(price_val);
        //   setTotalPrice(total_price);
        //   setPricePerKg(price_per_kg);
        //   setOrderQuantity(order_quantity);
        // }
      }
    }
  }, [props.data, props?.currencyRateList])

  const onDeleteCartClick = (cart_uid) => {
    props.onDeleteCart(cart_uid)
  };

  let item = props.data;

  return (


    <>
      <TableCell>
        <Typography className={styles.productNameText}>#{item.cart_uid}</Typography>
        {showError ?
          <Box mt={1}>
            <Typography className={styles.moqErrorText}>{showError}</Typography>
          </Box>
          : null}
      </TableCell>
      <TableCell className={styles.dataView}>
        {item.product_image ?
          <Grid item md={4} xs={12} className={styles.imgView}>
            <img src={item.product_image} className={styles.imgStyle}/>
          </Grid>
          : null}
        <Typography className={styles.productNameTitleText}>
          {(item?.variety ? item.variety : 'NIL Variety')
          + ', ' + (item?.name ? item.name : 'NIL')
          + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
          + ', ' + (item?.package_net_weight ?
            (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
        <Typography className={styles.subPricePerKgText} mt={1}>({currencySymbol}{pricePerKg?.toFixed(2)} / Kg)
          ({currencySymbol}{price} / Package)</Typography>
        <Typography className={styles.hsCodeText} mt={1}>HS Code: {item?.hs_code ? item.hs_code : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.productNameText}>{item?.user_name_entity ? item?.user_name_entity : ''}</Typography>
        <Typography
          className={styles.productNameText}>{item?.user_country ? '(' + COUNTRIES.filter(
          (x) => x.code == item?.user_country
        )?.[0]?.label + ')' : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.productNameText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}</Typography>
        <Typography
          className={styles.productNameText}>{item?.seller_country ? '(' + COUNTRIES.filter(
          (x) => x.code == item?.seller_country
        )?.[0]?.label + ')' : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.timestampText}>{item?.timestamp ? moment(item.timestamp, 'hh:mm A DD-MM-YYYY').format('hh:mm A') : '-'}</Typography>
        <Typography
          className={styles.timestampText}>{item?.timestamp ? moment(item.timestamp, 'hh:mm A DD-MM-YYYY').format('DD-MM-YYYY') : '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.productCountText}>Required Packages: {productCount}</Typography>
        <Typography className={styles.orderQuantityText} mt={1}>Order Quantity
          : {productCount * item?.package_net_weight} kg</Typography>
      </TableCell>
      <TableCell>
        <Grid item md={12} xs={12}>
          <Typography
            className={styles.subPriceText}>
            {actualCurrencySymbol}{actualTotalPrice?.toFixed(2)}
          </Typography>
          <Typography className={styles.subPricePerKgText}
                      pl={1}>{actualCurrencySymbol}{actualPricePerKg?.toFixed(2)} /
            kg</Typography>

          <Box pl={3}>
            <Typography
              className={styles.subPriceText}>
              ({currencySymbol}{totalPrice?.toFixed(2)})
            </Typography>
            <Typography className={styles.subPricePerKgText} pl={1}>({currencySymbol}{pricePerKg?.toFixed(2)} /
              kg)</Typography>
          </Box>

        </Grid>
      </TableCell>
      <TableCell>
        <Box className={styles.pointerView}>
          <FormButton
            title={'Delete'}
            btnType={'btn7'}
            width={'100%'}
            onSubmit={() => onDeleteCartClick(item.cart_uid)}
          />
        </Box>
      </TableCell>

    </>
  )
};

export default CartReviewPendingList;
