const initialState = {
  username: '',
  userToken: '',
  loginLoading: false,
  vendorList: null,
  sellerDetail: null,
  sellerActionLoading: false,
  vendorTotalPages: 0,
  vendorTotalCount: 0,
  productCategoryList: [],
  productLoading: false,
  productList: null,
  productDetail: null,
  productTotalPages: 0,
  productTotalCount: 0,
  productActionLoading: false,
  categoryLoading: false,
  categoryList: [],
  categoryTotalPages: 0,
  subCategoryLoading: false,
  subCategoryList: null,
  subCategoryTotalPages: 0,
  customerList: [],
  customerTotalPages: 0,
  customerTotalCount: 0,
  customerDetail: null,
  customerActionLoading: false,
  ordersList: [],
  ordersTotalPages: 0,
  orderDetail: null,
  listLoading: false,
  subLoading: false,
  sellerProductTotalPages: 0,
  cartReviewPendingList: [],
  cartReviewPendingTotalPages: 0,
  cartShippingCostPendingList: [],
  cartShippingCostPendingTotalPages: 0,
  cartCheckoutPendingList: [],
  cartCheckoutPendingTotalPages: 0,
  cartLoading: false,
  shippingCostLoading: false,
  pendingOrderCount: 0,
  unshippedOrderCount: 0,
  shippedOrderCount: 0,
  deliveredOrderCount: 0,
  cancelledOrderCount: 0,
  orderTotalCount: 0,
  orderTotalPages: 0,
  orderLoading: false,
  btnLoading: false,
  beneficiaryDetails: null,
  currencyRateList: null,
  dashboardDetail: null,
  cartDetail: null,
  msg: {open: false, message: '', msgType: ''},
  apiStatus: "",
};

const userDataReducer = (state = initialState, action) => {

  switch (action.type) {

    //login
    case 'LOGIN_USER':
      return {
        ...state,
        loginLoading: true,
      };

    case 'LOGIN_USER_SUCCESS':
      return {
        ...state,
        username: action.payload.username,
        userToken: action.payload.userToken,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        loginLoading: false,
      };

    case 'LOGIN_USER_FAIL':
      return {
        ...state,
        loginLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    //seller
    case 'LIST_VENDOR':
      return {
        ...state,
        listLoading: true,
      };

    case 'LIST_VENDOR_SUCCESS':
      return {
        ...state,
        vendorList: action.payload.data,
        vendorTotalPages: action.payload.total_pages,
        vendorTotalCount: action.payload.total_count,
        listLoading: false,
      };

    case 'LIST_VENDOR_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'GET_SELLER_DETAIL':
      return {
        ...state,
        sellerDetail: null,
        listLoading: true,
      };

    case 'GET_SELLER_DETAIL_SUCCESS':
      return {
        ...state,
        sellerDetail: action.payload.data,
        listLoading: false,
      };

    case 'GET_SELLER_DETAIL_FAIL':
      return {
        ...state,
        sellerDetail: null,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'UPDATE_SELLER_STATUS':
      return {
        ...state,
        sellerActionLoading: true,
      };

    case 'UPDATE_SELLER_STATUS_SUCCESS':
      let data = [...state.vendorList];
      let vendor_total_count = state.vendorTotalCount;
      let sellerIndex = data.findIndex((x) => (x.uid == action.payload.sellerUid));
      if (sellerIndex >= 0) {
        if (action.payload.updatedStatus == 'approved') {
          data[sellerIndex].status = 'active';
        } else {
          data[sellerIndex].status = action.payload.updatedStatus;
        }
        data[sellerIndex].tgsin = action.payload.tgsin;
        if (action.payload?.isRemoveRow) {
          vendor_total_count = vendor_total_count > 0 ? parseInt(vendor_total_count) - 1 : 0;
          data.splice(sellerIndex, 1);
        }
      }
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerActionLoading: false,
        vendorTotalCount: vendor_total_count,
        vendorList: data
      };

    case 'UPDATE_SELLER_STATUS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerActionLoading: false,
      };

    //category
    case 'LIST_PRODUCT_CATEGORY':
      return {
        ...state,
        // listLoading: true,
      };

    case 'LIST_PRODUCT_CATEGORY_SUCCESS':
      return {
        ...state,
        productCategoryList: action.payload.data,
        // listLoading: false,
      };

    case 'LIST_PRODUCT_CATEGORY_FAIL':
      return {
        ...state,
        // listLoading: false,
      };

    case 'LIST_PRODUCT':
      return {
        ...state,
        listLoading: true,
      };

    case 'LIST_PRODUCT_SUCCESS':
      return {
        ...state,
        productList: action.payload.data,
        productTotalPages: action.payload.total_pages,
        productTotalCount: action.payload.total_count,
        listLoading: false,
      };

    case 'LIST_PRODUCT_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'GET_PRODUCT_DETAIL':
      return {
        ...state,
        listLoading: true,
        productDetail: null,
        apiStatus: 'GET_PRODUCT_DETAIL',
      };

    case 'GET_PRODUCT_DETAIL_SUCCESS':
      return {
        ...state,
        productDetail: action.payload.data,
        listLoading: false,
        apiStatus: 'GET_PRODUCT_DETAIL_SUCCESS',
      };

    case 'GET_PRODUCT_DETAIL_FAIL':
      return {
        ...state,
        productDetail: null,
        listLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_PRODUCT_DETAIL_FAIL',
      };

    case 'UPDATE_PRODUCT_STATUS':
      return {
        ...state,
        productActionLoading: false,
        apiStatus: 'UPDATE_PRODUCT_STATUS'
      };

    case 'UPDATE_PRODUCT_STATUS_SUCCESS':
      let product_data = [...state.productList];
      let product_total_count = state.productTotalCount;
      let productDataIndex = product_data.findIndex((x) => (x.uid == action.payload.productUid));
      if (productDataIndex >= 0) {
        if (action.payload?.productStatus != '') {
          product_data.splice(productDataIndex, 1);
          product_total_count = parseInt(product_total_count) - 1;
        } else {
          if (action.payload.updatedStatus == 'removed') {
            product_data.splice(productDataIndex, 1);
            product_total_count = parseInt(product_total_count) - 1;
          } else {
            product_data[productDataIndex].status = action.payload.updatedStatus == 'approved' ? 'active' : action.payload.updatedStatus;
            product_data[productDataIndex].timestamp = action.payload.timestamp;
          }
        }
      }
      let product_detail_data = {...state.productDetail};
      product_detail_data = {
        ...product_detail_data,
        status: action.payload.updatedStatus == 'approved' ? 'active' : action.payload.updatedStatus,
        timestamp: action.payload.timestamp,
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productActionLoading: false,
        productList: product_data,
        productDetail: product_detail_data,
        productTotalCount: product_total_count,
        apiStatus: 'UPDATE_PRODUCT_STATUS_SUCCESS'
      };

    case 'UPDATE_PRODUCT_STATUS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        productActionLoading: false,
        apiStatus: 'UPDATE_PRODUCT_STATUS_FAIL'
      };

    case 'ADD_PRODUCT_IMAGE':
      return {
        ...state,
        btnLoading: false,
        apiStatus: 'AA_PRODUCT_IMAGE'
      };

    case 'ADD_PRODUCT_IMAGE_SUCCESS':
      let add_product_detail_image_data = {...state.productDetail}

      add_product_detail_image_data = {
        ...add_product_detail_image_data,
        imageList: [
          ...add_product_detail_image_data?.imageList,
          action.payload.data?.image,
        ],
      }
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        productDetail: add_product_detail_image_data,
        apiStatus: 'ADD_PRODUCT_IMAGE_SUCCESS'
      };

    case 'ADD_PRODUCT_IMAGE_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'ADD_PRODUCT_IMAGE_FAIL'
      };

    case 'DELETE_PRODUCT_IMAGE':
      return {
        ...state,
        btnLoading: false,
        apiStatus: 'DELETE_PRODUCT_IMAGE'
      };

    case 'DELETE_PRODUCT_IMAGE_SUCCESS':
      let delete_product_image_detail_data = {...state.productDetail};
      let image_list = [...delete_product_image_detail_data?.imageList];
      let deletedImageIndex = image_list.findIndex((x) => (x.id == action.payload?.data?.productImageId));
      if (deletedImageIndex >= 0) {
        image_list.splice(deletedImageIndex, 1);
      }
      delete_product_image_detail_data = {
        ...delete_product_image_detail_data,
        imageList: image_list,
      }
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        productDetail: delete_product_image_detail_data,
        apiStatus: 'DELETE_PRODUCT_IMAGE_SUCCESS'
      };

    case 'DELETE_PRODUCT_IMAGE_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'DELETE_PRODUCT_IMAGE_FAIL'
      };

    case 'UPDATE_MAIN_PRODUCT_IMAGE':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPDATE_MAIN_PRODUCT_IMAGE'
      };

    case 'UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS':
      let product_detail_main_img_data = {...state.productDetail};

      product_detail_main_img_data = {
        ...product_detail_main_img_data,
        mainImageList: action.payload.data,
      };
      return {
        ...state,
        btnLoading: false,
        productDetail: product_detail_main_img_data,
        apiStatus: 'UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS',
        msg: {open: true, message: action.payload.msg, msgType: 'success'}
      };

    case 'UPDATE_MAIN_PRODUCT_IMAGE_FAIL':
      return {
        ...state,
        btnLoading: false,
        apiStatus: 'UPDATE_MAIN_PRODUCT_IMAGE_FAIL',
        msg: {open: true, message: action.payload.msg, msgType: 'error'}
      };

    case 'GET_ALL_CATEGORY_LIST':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_ALL_CATEGORY_LIST_SUCCESS':
      return {
        ...state,
        listLoading: false,
        allCategoryList: action.payload.data,
      };

    case 'GET_ALL_CATEGORY_LIST_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    //category
    case 'ADD_CATEGORY':
      return {
        ...state,
        categoryLoading: true,
      };

    case 'ADD_CATEGORY_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        categoryList: [action.payload.data, ...state.categoryList],
        categoryLoading: false,
      };

    case 'ADD_CATEGORY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        categoryLoading: false,
      };

    case 'LIST_CATEGORY':
      return {
        ...state,
        listLoading: true,
      };

    case 'LIST_CATEGORY_SUCCESS':
      return {
        ...state,
        categoryList: action.payload.data,
        categoryTotalPages: action.payload.total_pages,
        listLoading: false,
      };

    case 'LIST_CATEGORY_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'UPDATE_CATEGORY_FEATURED':
      return {
        ...state,
        listLoading: true,
      };

    case 'UPDATE_CATEGORY_FEATURED_SUCCESS':
      return {
        ...state,
        listLoading: false,
      };

    case 'UPDATE_CATEGORY_FEATURED_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'DELETE_CATEGORY':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'DELETE_CATEGORY'
      };

    case 'DELETE_CATEGORY_SUCCESS':
      let category_list = [...state.categoryList];
      let categoryIndex = category_list.findIndex((x) => x.uid == action.payload?.categoryUid);
      if (categoryIndex >= 0) {
        category_list.splice(categoryIndex, 1)
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        categoryList: category_list,
        btnLoading: false,
        apiStatus: 'DELETE_CATEGORY_SUCCESS'
      };

    case 'DELETE_CATEGORY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'DELETE_CATEGORY_FAIL'
      };

    //subcategory
    case 'ADD_SUB_CATEGORY':
      return {
        ...state,
        subCategoryLoading: true,
      };

    case 'ADD_SUB_CATEGORY_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        subCategoryList: [action.payload.data, ...state.subCategoryList],
        subCategoryLoading: false,
      };

    case 'ADD_SUB_CATEGORY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        subCategoryLoading: false,
      };

    case 'LIST_SUB_CATEGORY':
      return {
        ...state,
        listLoading: true,
      };

    case 'LIST_SUB_CATEGORY_SUCCESS':
      return {
        ...state,
        subCategoryList: action.payload.data,
        subCategoryTotalPages: action.payload.total_pages,
        listLoading: false,
      };

    case 'LIST_SUB_CATEGORY_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'DELETE_SUB_CATEGORY':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'DELETE_SUB_CATEGORY'
      };

    case 'DELETE_SUB_CATEGORY_SUCCESS':
      let sub_category_list = [...state.subCategoryList];
      let subCategoryIndex = sub_category_list.findIndex((x) => x.uid == action.payload?.subCategoryUid);
      if (subCategoryIndex >= 0) {
        sub_category_list.splice(subCategoryIndex, 1)
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        subCategoryList: sub_category_list,
        btnLoading: false,
        apiStatus: 'DELETE_SUB_CATEGORY_SUCCESS'
      };

    case 'DELETE_SUB_CATEGORY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'DELETE_SUB_CATEGORY_FAIL'
      };

    case 'LIST_CUSTOMER':
      return {
        ...state,
        listLoading: true,
      };

    case 'LIST_CUSTOMER_SUCCESS':
      return {
        ...state,
        customerList: action.payload.data,
        customerTotalPages: action.payload.total_pages,
        customerTotalCount: action.payload.total_count,
        listLoading: false,
      };

    case 'LIST_CUSTOMER_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'GET_CUSTOMER_DETAIL':
      return {
        ...state,
        customerDetail: null,
        listLoading: true,
      };

    case 'GET_CUSTOMER_DETAIL_SUCCESS':
      return {
        ...state,
        customerDetail: action.payload.data,
        listLoading: false,
      };

    case 'GET_CUSTOMER_DETAIL_FAIL':
      return {
        ...state,
        customerDetail: null,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'UPDATE_CUSTOMER_STATUS':
      return {
        ...state,
        customerActionLoading: true,
      };

    case 'UPDATE_CUSTOMER_STATUS_SUCCESS':
      let customer_data = [...state.customerList];
      let customer_total_count = state?.customerTotalCount;
      let customerIndex = customer_data.findIndex((x) => (x.uid == action.payload.userUid));
      if (customerIndex >= 0) {
        if (action.payload.updatedStatus == 'approved') {
          customer_data[customerIndex].status = 'active';
        } else {
          customer_data[customerIndex].status = action.payload.updatedStatus;
        }
        customer_data[customerIndex].tgbin = action.payload.tgbin;
        if (action.payload?.isRemoveRow) {
          customer_total_count = customer_total_count > 0 ? parseInt(customer_total_count) - 1 : 0;
          customer_data.splice(customerIndex, 1);
        }
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        customerActionLoading: false,
        customerTotalCount: customer_total_count,
        customerList: customer_data
      };

    case 'UPDATE_CUSTOMER_STATUS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        customerActionLoading: false,
      };

    //dashboard
    case 'GET_DASHBOARD':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_DASHBOARD_SUCCESS':
      return {
        ...state,
        dashboardDetail: action.payload.data,
        listLoading: false,
      };

    case 'GET_DASHBOARD_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'LIST_CART_REVIEW_PENDING':
      return {
        ...state,
        cartLoading: true,
      };

    case 'LIST_CART_REVIEW_PENDING_SUCCESS':
      let auto_cancelled_cart_count = action?.payload?.auto_cancelled_cart_count
      let checkout_cancel_pending_count = state?.checkoutPendingCount;
      if(auto_cancelled_cart_count > 0) {
        checkout_cancel_pending_count = checkout_cancel_pending_count > 0 ? (parseInt(checkout_cancel_pending_count) - auto_cancelled_cart_count) : 0
      }

      return {
        ...state,
        cartReviewPendingList: action.payload.data,
        cartReviewPendingTotalPages: action.payload?.total_pages,
        checkoutPendingCount: checkout_cancel_pending_count,
        cartLoading: false,
      };

    case 'LIST_CART_REVIEW_PENDING_FAIL':
      return {
        ...state,
        cartLoading: false,
      };

    case 'LIST_CART_SHIPPING_COST_PENDING':
      return {
        ...state,
        cartLoading: true,
      };

    case 'LIST_CART_SHIPPING_COST_PENDING_SUCCESS':
      let shipping_auto_cancelled_cart_count = action?.payload?.auto_cancelled_cart_count
      let shipping_checkout_cancel_pending_count = state?.checkoutPendingCount;
      if(shipping_auto_cancelled_cart_count > 0) {
        shipping_checkout_cancel_pending_count = shipping_checkout_cancel_pending_count > 0 ? (parseInt(shipping_checkout_cancel_pending_count) - shipping_auto_cancelled_cart_count) : 0
      }

      return {
        ...state,
        cartShippingCostPendingList: action.payload.data,
        cartShippingCostPendingTotalPages: action.payload?.total_pages,
        checkoutPendingCount: shipping_checkout_cancel_pending_count,
        cartLoading: false,
      };

    case 'LIST_CART_SHIPPING_COST_PENDING_FAIL':
      return {
        ...state,
        cartLoading: false,
      };

    case 'LIST_CART_CHECKOUT_PENDING':
      return {
        ...state,
        cartLoading: true,
      };

    case 'LIST_CART_CHECKOUT_PENDING_SUCCESS':
      let checkout_auto_cancelled_cart_count = action?.payload?.auto_cancelled_cart_count
      let checkout_checkout_cancel_pending_count = state?.checkoutPendingCount;
      if(checkout_auto_cancelled_cart_count > 0) {
        checkout_checkout_cancel_pending_count = checkout_checkout_cancel_pending_count > 0 ? (parseInt(checkout_checkout_cancel_pending_count) - checkout_auto_cancelled_cart_count) : 0
      }

      return {
        ...state,
        cartCheckoutPendingList: action.payload.data,
        cartCheckoutPendingTotalPages: action.payload.total_pages,
        checkoutPendingCount: checkout_checkout_cancel_pending_count,
        cartLoading: false,
      };

    case 'LIST_CART_CHECKOUT_PENDING_FAIL':
      return {
        ...state,
        cartLoading: false,
      };

    case 'DELETE_CART':
      return {
        ...state,
        cartLoading: true,
        apiStatus: 'DELETE_CART'
      };

    case 'DELETE_CART_SUCCESS':
      let cart_shipping_cost_pending_data = [...state.cartShippingCostPendingList];
      let shipping_cost_pending_count = state.shippingCostPendingCount;
      if (action.payload.data?.currentStatus == 'shipping_cost_pending') {
        let cartIndex = cart_shipping_cost_pending_data.findIndex((x) => (x.cart_uid == action.payload?.data?.cartUid));
        if (cartIndex >= 0) {
          cart_shipping_cost_pending_data.splice(cartIndex, 1);
          if (shipping_cost_pending_count > 0) {
            shipping_cost_pending_count = parseInt(shipping_cost_pending_count) - 1
          }
        }
      }
      let checkout_pending_count = state.checkoutPendingCount;
      let cart_checkout_pending_data = [...state.cartCheckoutPendingList];
      if (action.payload.data?.currentStatus == 'checkout_pending') {
        let cartCheckoutIndex = cart_checkout_pending_data.findIndex((x) => (x.cart_uid == action.payload?.data?.cartUid));
        if (cartCheckoutIndex >= 0) {
          cart_checkout_pending_data.splice(cartCheckoutIndex, 1);
          if (checkout_pending_count > 0) {
            checkout_pending_count = parseInt(checkout_pending_count) - 1
          }
        }
      }
      let review_pending_count = state.reviewPendingCount;
      let cart_review_pending_data = [...state.cartReviewPendingList];
      if (action.payload.data?.currentStatus == 'review_pending') {
        let cartReviewIndex = cart_review_pending_data.findIndex((x) => (x.cart_uid == action.payload?.data?.cartUid));
        if (cartReviewIndex >= 0) {
          cart_review_pending_data.splice(cartReviewIndex, 1);
          if (review_pending_count > 0) {
            review_pending_count = parseInt(review_pending_count) - 1
          }
        }
      }

      return {
        ...state,
        cartLoading: false,
        cartShippingCostPendingList: cart_shipping_cost_pending_data,
        cartCheckoutPendingList: cart_checkout_pending_data,
        cartReviewPendingList: cart_review_pending_data,
        shippingCostPendingCount: shipping_cost_pending_count,
        checkoutPendingCount: checkout_pending_count,
        reviewPendingCount: review_pending_count,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'DELETE_CART_SUCCESS'
      };

    case 'DELETE_CART_FAIL':
      return {
        ...state,
        cartLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'DELETE_CART_FAIL',
      };

    case 'GET_CART_DETAIL':
      return {
        ...state,
        cartLoading: true,
        apiStatus: 'GET_CART_DETAIL'
      };

    case 'GET_CART_DETAIL_SUCCESS':
      return {
        ...state,
        cartLoading: false,
        cartDetail: action.payload.data,
        apiStatus: 'GET_CART_DETAIL_SUCCESS'
      };

    case 'GET_CART_DETAIL_FAIL':
      return {
        ...state,
        cartLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_CART_DETAIL_FAIL'
      };

    case 'ADD_CART_SHIPPING_COST':
      return {
        ...state,
        shippingCostLoading: true,
        apiStatus: 'ADD_CART_SHIPPING_COST'
      };

    case 'ADD_CART_SHIPPING_COST_SUCCESS':
      let add_cart_shipping_cost_pending_data = [...state.cartShippingCostPendingList];
      let cart_checkout_pending_count = state.checkoutPendingCount;
      let cart_shipping_cost_pending_count = state.shippingCostPendingCount;
      let cartIndex = add_cart_shipping_cost_pending_data.findIndex((x) => (x.cart_uid == action.payload?.data?.cartUid));
      if (cartIndex >= 0) {
        add_cart_shipping_cost_pending_data.splice(cartIndex, 1);
        cart_checkout_pending_count = parseInt(cart_checkout_pending_count) + 1
        if (cart_shipping_cost_pending_count > 0) {
          cart_shipping_cost_pending_count = parseInt(cart_shipping_cost_pending_count) - 1
        }
      }

      return {
        ...state,
        shippingCostLoading: false,
        cartShippingCostPendingList: add_cart_shipping_cost_pending_data,
        checkoutPendingCount: cart_checkout_pending_count,
        shippingCostPendingCount: cart_shipping_cost_pending_count,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'ADD_CART_SHIPPING_COST_SUCCESS'
      };

    case 'ADD_CART_SHIPPING_COST_FAIL':
      return {
        ...state,
        shippingCostLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'ADD_CART_SHIPPING_COST_FAIL',
      };

    case 'GET_CART_COUNT':
      return {
        ...state,
        apiStatus: 'GET_CART_COUNT',
      };

    case 'GET_CART_COUNT_SUCCESS':
      return {
        ...state,
        reviewPendingCount: action.payload.data?.review_pending_count,
        shippingCostPendingCount: action.payload.data?.shipping_cost_pending_count,
        checkoutPendingCount: action.payload.data?.checkout_pending_count,
        apiStatus: 'GET_CART_COUNT_SUCCESS',
      };

    case 'GET_CART_COUNT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_CART_COUNT_FAIL',
      };

    //orders
    case 'LIST_ORDER':
      return {
        ...state,
        orderLoading: true,
      };

    case 'LIST_ORDER_SUCCESS':
      let pending_order_count = state?.pendingOrderCount;
      let cancelled_order_count = state?.cancelledOrderCount;
      let auto_cancelled_order_count = action?.payload?.auto_cancelled_order_count;

      if (auto_cancelled_order_count > 0) {
        pending_order_count = pending_order_count > 0 ? (parseInt(pending_order_count) - parseInt(auto_cancelled_order_count)) : 0
        cancelled_order_count = parseInt(cancelled_order_count) + parseInt(auto_cancelled_order_count)
      }

      return {
        ...state,
        orderList: action.payload.data,
        orderTotalPages: action.payload?.total_pages,
        orderTotalCount: action.payload?.total_count,
        pendingOrderCount: pending_order_count,
        cancelledOrderCount: cancelled_order_count,
        orderLoading: false,
      };

    case 'LIST_ORDER_FAIL':
      return {
        ...state,
        orderLoading: false,
      };

    case 'ADD_ORDER_BOOKING_DETAILS':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'ADD_ORDER_BOOKING_DETAILS'
      };

    case 'ADD_ORDER_BOOKING_DETAILS_SUCCESS':
      return {
        ...state,
        btnLoading: false,
        apiStatus: 'ADD_ORDER_BOOKING_DETAILS_SUCCESS',
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
      };

    case 'ADD_ORDER_BOOKING_DETAILS_FAIL':
      return {
        ...state,
        btnLoading: false,
        apiStatus: 'ADD_ORDER_BOOKING_DETAILS_FAIL',
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'GET_ORDER_DETAIL':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'GET_ORDER_DETAIL'
      };

    case 'GET_ORDER_DETAIL_SUCCESS':
      return {
        ...state,
        orderDetail: action.payload.data,
        listLoading: false,
        apiStatus: 'GET_ORDER_DETAIL_SUCCESS'
      };

    case 'GET_ORDER_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'GET_ORDER_DETAIL_FAIL'
      };

    case 'CONFIRM_PENDING_ORDER':
      return {
        ...state,
        btnLoading: true,
      };

    case 'CONFIRM_PENDING_ORDER_SUCCESS':
      let order_list = [...state.orderList]
      let orderIndex = order_list.findIndex((x) => x.order_uid == action.payload?.data?.orderUid);
      if (orderIndex >= 0) {
        order_list.splice(orderIndex, 1)
      }
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        orderList: order_list,
        pendingOrderCount: state.pendingOrderCount > 0 ? parseInt(state.pendingOrderCount) - 1 : 0,
        unshippedOrderCount: parseInt(state.unshippedOrderCount) + 1,
        orderTotalCount: state.orderTotalCount > 0 ? parseInt(state.orderTotalCount) - 1 : 0,
        btnLoading: false,
      };

    case 'CONFIRM_PENDING_ORDER_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
      };

    case 'UPLOAD_ORDER_DOCUMENTS':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPLOAD_ORDER_DOCUMENTS'
      };

    case 'UPLOAD_ORDER_DOCUMENTS_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        apiStatus: 'UPLOAD_ORDER_DOCUMENTS_SUCCESS'
      };

    case 'UPLOAD_ORDER_DOCUMENTS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'UPLOAD_ORDER_DOCUMENTS_FAIL'
      };

    case 'CONFIRM_ORDER_PAYMENT':
      return {
        ...state,
        btnLoading: true,
      };

    case 'CONFIRM_ORDER_PAYMENT_SUCCESS':
      let order_list_pay = [...state.orderList]
      let orderPayIndex = order_list_pay.findIndex((x) => x.order_uid == action.payload?.data?.orderUid);
      if (orderPayIndex >= 0) {
        order_list_pay[orderPayIndex]['payment_status'] = 'paid'
      }
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        orderList: order_list_pay,
        btnLoading: false,
      };

    case 'CONFIRM_ORDER_PAYMENT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
      };

    case 'UPLOAD_ORDER_TRACK':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPLOAD_ORDER_TRACK'
      };

    case 'UPLOAD_ORDER_TRACK_SUCCESS':
      let track_order_list = [...state.orderList]
      let trackOrderIndex = track_order_list.findIndex((x) => x.order_uid == action.payload?.data?.orderUid);
      if (trackOrderIndex >= 0) {
        track_order_list[trackOrderIndex]['track_order'] = action.payload?.data?.trackLink
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        orderList: track_order_list,
        btnLoading: false,
        apiStatus: 'UPLOAD_ORDER_TRACK_SUCCESS'
      };

    case 'UPLOAD_ORDER_TRACK_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'UPLOAD_ORDER_TRACK_FAIL'
      };

    case 'CONFIRM_ORDER_DELIVERY':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'CONFIRM_ORDER_DELIVERY'
      };

    case 'CONFIRM_ORDER_DELIVERY_SUCCESS':
      let confirm_order_list = [...state.orderList]
      let confirmOrderIndex = confirm_order_list.findIndex((x) => x.order_uid == action.payload?.data?.orderUid);
      if (confirmOrderIndex >= 0) {
        confirm_order_list.splice(confirmOrderIndex, 1)
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        orderList: confirm_order_list,
        shippedOrderCount: state.shippedOrderCount > 0 ? parseInt(state.shippedOrderCount) - 1 : 0,
        deliveredOrderCount: state.deliveredOrderCount > 0 ? parseInt(state.deliveredOrderCount) + 1 : 0,
        btnLoading: false,
        apiStatus: 'CONFIRM_ORDER_DELIVERY_SUCCESS'
      };

    case 'CONFIRM_ORDER_DELIVERY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        btnLoading: false,
        apiStatus: 'CONFIRM_ORDER_DELIVERY_FAIL'
      };

    case 'GET_ORDER_COUNT':
      return {
        ...state,
        apiStatus: 'GET_ORDER_COUNT'
      };

    case 'GET_ORDER_COUNT_SUCCESS':
      return {
        ...state,
        apiStatus: 'GET_ORDER_COUNT_SUCCESS',
        pendingOrderCount: action.payload.data?.pending_order_count,
        unshippedOrderCount: action.payload.data?.unshipped_order_count,
        shippedOrderCount: action.payload.data?.shipped_order_count,
        deliveredOrderCount: action.payload.data?.delivered_order_count,
        cancelledOrderCount: action.payload.data?.cancelled_order_count,
      };

    case 'GET_ORDER_COUNT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_ORDER_COUNT_FAIL'
      };

    case 'MESSAGE_TO_SELLER':
      return {
        ...state,
        orderBtnLoading: true,
      };

    case 'MESSAGE_TO_SELLER_SUCCESS':
      let order_detail_message = {...state.orderDetail}
      order_detail_message = {
        ...order_detail_message,
        message_to_seller: action.payload?.data?.message,
      };
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        orderDetail: order_detail_message,
        orderBtnLoading: false,
      };

    case 'MESSAGE_TO_SELLER_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        orderBtnLoading: false,
      };

    //beneficiary details
    case 'GET_BENEFICIARY_DETAILS':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'GET_BENEFICIARY_DETAILS'
      };

    case 'GET_BENEFICIARY_DETAILS_SUCCESS':
      return {
        ...state,
        beneficiaryDetails: action.payload.data,
        listLoading: false,
        apiStatus: 'GET_BENEFICIARY_DETAILS_SUCCESS'
      };

    case 'GET_BENEFICIARY_DETAILS_FAIL':
      return {
        ...state,
        listLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_BENEFICIARY_DETAILS_FAIL'
      };

    case 'ADD_BENEFICIARY_DETAILS':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'ADD_BENEFICIARY_DETAILS'
      };

    case 'ADD_BENEFICIARY_DETAILS_SUCCESS':
      return {
        ...state,
        beneficiaryDetails: action.payload.data,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'ADD_BENEFICIARY_DETAILS_SUCCESS'
      };

    case 'ADD_BENEFICIARY_DETAILS_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'ADD_BENEFICIARY_DETAILS_FAIL'
      };

    case 'UPDATE_BENEFICIARY_DETAILS':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPDATE_BENEFICIARY_DETAILS'
      };

    case 'UPDATE_BENEFICIARY_DETAILS_SUCCESS':
      return {
        ...state,
        beneficiaryDetails: action.payload.data,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'UPDATE_BENEFICIARY_DETAILS_SUCCESS'
      };

    case 'UPDATE_BENEFICIARY_DETAILS_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'UPDATE_BENEFICIARY_DETAILS_FAIL'
      };

    case 'GET_CURRENCY_RATE_LIST':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_CURRENCY_RATE_LIST_SUCCESS':
      return {
        ...state,
        currencyRateList: action.payload.data,
        listLoading: false,
      };

    case 'GET_CURRENCY_RATE_LIST_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'UPDATE_LIST_LOADING':
      return {
        ...state,
        listLoading: action.payload,
      };

    case 'ALERT_MSG_EMPTY':
      return {
        ...state,
        msg: {open: false, message: '', msgType: ''},
      };

    case 'UPDATE_ALERT_MSG':
      return {
        ...state,
        msg: {open: action.payload.open, message: action.payload.message, msgType: action.payload.msgType},
      };

    case 'UPDATE_API_STATUS':
      return {
        ...state,
        apiStatus: action.payload,
      };

    case 'LOG_OUT':
      return {
        ...state,
        username: '',
        userToken: '',
        loginLoading: false,
        vendorList: null,
        sellerDetail: null,
        sellerActionLoading: false,
        vendorTotalPages: 0,
        vendorTotalCount: 0,
        sellerProductTotalPages: 0,
        productCategoryList: [],
        productLoading: false,
        productList: null,
        productDetail: null,
        productTotalPages: 0,
        productTotalCount: 0,
        productActionLoading: false,
        categoryLoading: false,
        categoryList: [],
        categoryTotalPages: 0,
        subCategoryLoading: false,
        subCategoryList: null,
        subCategoryTotalPages: 0,
        customerList: [],
        customerTotalPages: 0,
        customerTotalCount: 0,
        customerDetail: null,
        customerActionLoading: false,
        ordersList: [],
        ordersTotalPages: 0,
        orderDetail: null,
        cartReviewPendingList: [],
        cartReviewPendingTotalPages: 0,
        cartShippingCostPendingList: [],
        cartShippingCostPendingTotalPages: 0,
        cartCheckoutPendingList: [],
        cartCheckoutPendingTotalPages: 0,
        orderTotalCount: 0,
        orderTotalPages: 0,
        cartLoading: false,
        orderLoading: false,
        listLoading: false,
        subLoading: false,
        beneficiaryDetails: null,
        pendingOrderCount: 0,
        unshippedOrderCount: 0,
        shippedOrderCount: 0,
        deliveredOrderCount: 0,
        cancelledOrderCount: 0,
        btnLoading: false,
        currencyRateList: null,
        dashboardDetail: null,
        cartDetail: null,
        shippingCostLoading: false,
        msg: {open: false, message: '', msgType: ''},
        apiStatus: "",
      };
  }
  return state;
};

export default userDataReducer;
