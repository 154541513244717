import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

import {AppBar, Toolbar, IconButton, Avatar, Box, Tooltip, Menu, MenuItem, Typography} from '@mui/material';

import AlertMsg from "./AlertMsg";

import {ReactComponent as MenuIcon} from "../assets/img/menu.svg";

import {THEME_COLOR5} from '../constants'

const settings = ['Sign Out'];

const Navbar = (props) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const onOpen = () => {
    setOpen(true)
  };

  const onClose = () => {
    setOpen(false)
  };

  const onNavClick = () => {
    navigate('/sign_out')
  };

  return (
    <AppBar position="fixed"
            sx={{
              boxShadow: '0px 10px 30px 0px rgba(82, 63, 105, 0.05)',
              zIndex: 5,
            }}>
      <Toolbar sx={styles.mainNav}>
        <Box>
          <IconButton onClick={onOpen}>
            <MenuIcon height={20} width={20}/>
          </IconButton>
          <Menu
            disableScrollLock={true}
            sx={{mt: '45px'}}
            id="menu-appbar"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={onClose}>
            {settings.map((item) => (
              <MenuItem key={item} onClick={onNavClick}>
                <Typography textAlign="center" style={styles.settingsText}>{item}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>

      <AlertMsg/>
    </AppBar>
  );
};

export default Navbar;

const styles = {
  mainNav: {
    backgroundColor: THEME_COLOR5,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  settingsText: {
    fontSize: 13,
    fontFamily: 'InterBold',
  }
};
