import {AUTHENTICATION_FAILED, ENV} from '../constants';
import axios from 'axios';

import {store} from '../redux/store';
import {logOut} from "../redux/actions/userDataActions";

let apiUrl = '';

if (ENV === 'PROD') {
  apiUrl = 'https://api.trebbs.com/admin/';
} else {
  apiUrl = 'http://localhost:8080/admin/';
}

function userToken() {
  const state = store.getState();
  let token = state.userData.userToken;
  return token;
}

export async function adminLogin(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('login', config);
}

export async function listVendor(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_vendor', config);
}

export async function getSellerDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_seller_detail', config);
}

export async function updateSellerStatus(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_seller_status', config);
}

export async function listProduct(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_product', config);
}

export async function getProductDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_product_detail', config);
}

export async function updateProductStatus(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_product_status', config);
}

export async function addProductImage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_product_image', config);
}

export async function deleteProductImage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_product_image', config);
}

export async function updateMainProductImage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_main_product_image', config);
}

export async function addCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_category', config);
}

export async function listCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_category', config);
}

export async function deleteCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_category', config);
}

export async function updateCategoryFeatured(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_category_featured', config);
}

export async function addSubCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_sub_category', config);
}

export async function listSubCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_sub_category', config);
}

export async function deleteSubCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_sub_category', config);
}

export async function listProductCategory() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('list_product_category', config);
}

export async function listCustomer(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_user', config);
}

export async function getCustomerDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_user_detail', config);
}

export async function updateCustomerStatus(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_user_status', config);
}

export async function getDashboardData() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_dashboard_page ', config);
}

export async function listCart(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_cart_list ', config);
}

export async function deleteCart(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_cart ', config);
}

export async function getCartDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_cart_detail ', config);
}

export async function addCartShippingCost(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_cart_shipping_cost ', config);
}

export async function listOrder(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('get_orders_list ', config);
}

export async function addOrderBookingDetails(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_order_booking_details', config);
}

export async function getOrderDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_order_detail ', config);
}

export async function getBeneficiaryDetails() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };
  return callApi('get_beneficiary_details ', config);
}

export async function addBeneficiaryDetails(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('add_beneficiary_details ', config);
}

export async function updateBeneficiaryDetails(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('update_beneficiary_details ', config);
}

export async function confirmPendingOrder(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('confirm_pending_order ', config);
}

export async function confirmOrderPayment(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('confirm_order_payment ', config);
}

export async function uploadOrderDocuments(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('upload_order_documents', config);
}

export async function uploadOrderTrack(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('upload_order_track', config);
}

export async function confirmOrderDelivery(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('confirm_order_delivery', config);
}

export async function getOrderCount() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_order_count', config);
}

export async function messageToSeller(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('message_to_seller', config);
}

export async function allCategoryList() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('all_category_list', config);
}

export async function getCartCount(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_cart_count', config);
}

export async function getCurrencyRateList() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_currency_rate_list', config);
}


/**
 * Private functions
 */
async function callApi(endpoint, config) {
  const url = `${apiUrl}${endpoint}`;

  console.log('Url: ', url);
  console.log('Config: ', config);

  return axios({
    ...config,
    url: url,
  })
    .then(async (response) => {
      console.log('-----API RESPONSE-----', response.data);
      if (response.data.msg === AUTHENTICATION_FAILED) {
        await store.dispatch(logOut(''));
        return {status: 'Failed', msg: response.data.msg};
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
      console.log(
        'There has been a problem with your fetch operation: ' + error.message,
      );
      if (error?.message) {
        return {
          status: 'Failed',
          msg: error.message,
        };
      }
      return {
        status: 'Failed',
        msg: 'Api Error',
      };
    });
}
