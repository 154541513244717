import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";

export const priceCalculation = (price_per_package, package_net_weight, referral_fee, quantity, shipping_cost) => {
  let data = null;
  if (price_per_package != '' && package_net_weight != '') {
    let price_val = (parseFloat((((price_per_package / package_net_weight) * referral_fee) / 100) + (price_per_package / package_net_weight)) * package_net_weight)
    let price_per_kg = price_val / package_net_weight;
    let total_price = quantity * price_val;
    let cargo_price = (parseFloat(total_price) + parseFloat(shipping_cost));
    data = {
      price_val: price_val,
      price_per_kg: price_per_kg,
      total_price: total_price,
      cargo_price: cargo_price,
    }
  }
  return data;
};

export const getCurrencyConversionCalc = (data_list, rate_list = null) => {
  let data = null;
  if (data_list?.price_per_package != '' && data_list?.package_net_weight != ''
    && data_list?.admin_currency != '' && data_list?.currency != '') {
    let price_val = (parseFloat((((data_list?.price_per_package / data_list?.package_net_weight) * data_list?.referral_fee) / 100) + (data_list?.price_per_package / data_list?.package_net_weight)) * data_list?.package_net_weight)
    let price_per_kg_without_referral_fee = parseFloat(data_list?.price_per_package / data_list?.package_net_weight);
    let price_per_kg_referral_fee = parseFloat((price_per_kg_without_referral_fee * data_list?.referral_fee) / 100);
    let price_per_package_without_referral_fee = data_list?.price_per_package;
    let referral_fee_per_package = price_per_kg_referral_fee * data_list?.package_net_weight;
    let currency_val = data_list?.currency;
    let currency_val2 = data_list?.currency;
    let price = price_val;
    let price_per_kg = price_val / data_list?.package_net_weight;
    let total_price = data_list?.quantity * price_val;
    let total_price_without_referral_fee = data_list?.quantity * price_per_package_without_referral_fee;
    let total_referral_fee = data_list?.quantity * referral_fee_per_package;
    let shipping_cost = 0;
    let cargo_price = total_price;

    if (data_list?.shipping_cost != 0) {
      shipping_cost = data_list?.shipping_cost;
      cargo_price = (parseFloat(total_price) + parseFloat(data_list?.shipping_cost));
    }

    let original_price = price_val;
    let original_price_per_kg_without_referral_fee = price_per_kg_without_referral_fee;
    let original_price_per_kg_referral_fee = price_per_kg_referral_fee;
    let original_price_per_package_without_referral_fee = price_per_package_without_referral_fee;
    let original_referral_fee_per_package = referral_fee_per_package;
    let original_total_price_without_referral_fee = total_price_without_referral_fee;
    let original_total_referral_fee = total_referral_fee;
    let original_shipping_cost = shipping_cost;
    let original_price_per_kg = price_per_kg;
    let original_cargo_price = cargo_price;
    let original_total_price = total_price;

    let converted_price = price_val;
    let converted_price_per_kg_without_referral_fee = price_per_kg_without_referral_fee;
    let converted_price_per_kg_referral_fee = price_per_kg_referral_fee;
    let converted_price_per_package_without_referral_fee = price_per_package_without_referral_fee;
    let converted_referral_fee_per_package = referral_fee_per_package;
    let converted_total_price_without_referral_fee = total_price_without_referral_fee;
    let converted_total_referral_fee = total_referral_fee;
    let converted_shipping_cost = shipping_cost;
    let converted_price_per_kg = price_per_kg;
    let converted_cargo_price = cargo_price;
    let converted_total_price = total_price;

    let rate = 0;
    let rate2 = 0;
    let rate3 = 0;
    let shipping_cost_inr_to_usd_rate = 0
    if (data_list?.admin_rate) {
      rate = data_list?.admin_rate;
      rate3 = data_list?.usd_rate

      if(rate_list != null) {
        if(rate_list?.['rates']) {
          if(data_list?.admin_currency2) {
            shipping_cost_inr_to_usd_rate = rate_list['rates'][data_list?.admin_currency2];
          }
        }
      }
    } else {
      if(rate_list == null) {
        if(data_list?.rate_list != null) {
          rate = data_list?.rate_list['rates'][data_list?.admin_currency];
          if(data_list?.admin_currency2) {
            rate3 = data_list?.rate_list['rates'][data_list?.admin_currency2];
          }
        }
      } else {
        if(rate_list?.['rates']) {
          rate = 1 / rate_list['rates'][data_list?.currency];
          if(data_list?.admin_currency2) {
            rate2 = rate_list['rates'][data_list?.admin_currency2];
          }
        }
      }
    }
    if (rate != 0) {
      let total = rate * original_price;
      converted_price_per_kg_without_referral_fee = rate * original_price_per_kg_without_referral_fee;
      converted_price_per_kg_referral_fee = rate * original_price_per_kg_referral_fee;
      converted_price_per_package_without_referral_fee = rate * original_price_per_package_without_referral_fee;
      converted_referral_fee_per_package = rate * original_referral_fee_per_package;
      converted_total_price_without_referral_fee = rate * original_total_price_without_referral_fee;
      converted_total_referral_fee = rate * original_total_referral_fee;

      converted_price = total;
      if (data_list?.shipping_cost != 0) {
        converted_shipping_cost = data_list?.shipping_cost
      }
      currency_val = data_list?.admin_currency;

      converted_price_per_kg = converted_price / data_list?.package_net_weight;
      converted_total_price = data_list?.quantity * converted_price;
      converted_cargo_price = converted_total_price;
      if (data_list?.shipping_cost != 0) {
        converted_cargo_price = (parseFloat(converted_total_price) + parseFloat(converted_shipping_cost));
      }

      if(rate2 != 0 || rate3 != 0) {
        let total = (rate2 != 0 ? converted_price : original_price);
        if (rate2 != 0) {
          total = rate2 * converted_price;
          price_per_kg_without_referral_fee = rate2 * converted_price_per_kg_without_referral_fee;
          price_per_kg_referral_fee = rate2 * converted_price_per_kg_referral_fee;
          price_per_package_without_referral_fee = rate2 * converted_price_per_package_without_referral_fee;
          referral_fee_per_package = rate2 * converted_referral_fee_per_package;
          total_price_without_referral_fee = rate2 * converted_total_price_without_referral_fee;
          total_referral_fee = rate2 * converted_total_referral_fee;
        } else if (rate3 != 0) {
          total = rate3 * original_price;
          price_per_kg_without_referral_fee = rate3 * original_price_per_kg_without_referral_fee;
          price_per_kg_referral_fee = rate3 * original_price_per_kg_referral_fee;
          price_per_package_without_referral_fee = rate3 * original_price_per_package_without_referral_fee;
          referral_fee_per_package = rate3 * original_referral_fee_per_package;
          total_price_without_referral_fee = rate3 * original_total_price_without_referral_fee;
          total_referral_fee = rate3 * original_total_referral_fee;
        }
        price = total;
        if (data_list?.shipping_cost != 0) {
          shipping_cost = rate2 * data_list?.shipping_cost
        }
        if(shipping_cost_inr_to_usd_rate != 0) {
          shipping_cost = shipping_cost_inr_to_usd_rate * data_list?.shipping_cost
        }
        price_per_kg = price / data_list?.package_net_weight;
        total_price = data_list?.quantity * price;
        cargo_price = total_price;
        if (data_list?.shipping_cost != 0) {
          cargo_price = (parseFloat(total_price) + parseFloat(shipping_cost));
        }
        currency_val2 = data_list?.admin_currency2
      }

    }

    let admin_currency_symbol = getSymbolFromCurrency(currency_val)
    let currency_symbol = getSymbolFromCurrency(currency_val2)

    data = {
      admin_currency_symbol: admin_currency_symbol,
      converted_price: converted_price,
      converted_price_per_kg_without_referral_fee: converted_price_per_kg_without_referral_fee,
      converted_price_per_kg_referral_fee: converted_price_per_kg_referral_fee,
      converted_price_per_package_without_referral_fee: converted_price_per_package_without_referral_fee,
      converted_referral_fee_per_package: converted_referral_fee_per_package,
      converted_price_per_kg: converted_price_per_kg,
      converted_total_price_without_referral_fee: converted_total_price_without_referral_fee,
      converted_total_referral_fee: converted_total_referral_fee,
      converted_total_price: converted_total_price,
      converted_shipping_cost: converted_shipping_cost,
      converted_cargo_price: converted_cargo_price,
      currency_symbol: currency_symbol,
      price: price,
      price_per_kg_without_referral_fee: price_per_kg_without_referral_fee,
      price_per_kg_referral_fee: price_per_kg_referral_fee,
      price_per_package_without_referral_fee: price_per_package_without_referral_fee,
      referral_fee_per_package: referral_fee_per_package,
      total_price_without_referral_fee: total_price_without_referral_fee,
      total_referral_fee: total_referral_fee,
      price_per_kg: price_per_kg,
      total_price: total_price,
      shipping_cost: shipping_cost,
      cargo_price: cargo_price,
    }
  }
  return data;
};


export const unshippedOrderStatus = (unshipped_timestamp) => {
  let a = moment(unshipped_timestamp, "hh:mm:ss A DD-MM-Y");
  let b = moment();
  let hours = b.diff(a, "hours");
  if (hours >= 24) {
    return 'Pickup Scheduled'
  } else {
    return 'Confirmed'
  }
};
