import * as React from 'react';
import {useEffect} from "react";
import {useState} from 'react';

import {
  Box,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Avatar,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Container from "../../components/Container";
import CustomerContactDetails from '../../components/CustomerContactDetails';
import CustomerCorporateDetails from '../../components/CustomerCorporateDetails';
import CustomerImporterDetails from '../../components/CustomerImporterDetails';
import CustomerProductDetails from '../../components/CustomerProductDetails';

import {connect} from "react-redux";
import {getCustomerDetail} from "../../redux/actions/userDataActions";

import {THEME_COLOR2, THEME_COLOR5, THEME_COLOR9} from "../../constants";

import {COUNTRIES} from "../../constants/countries";

import {ReactComponent as TickIcon} from "../../assets/img/tick.svg";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pl={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const status_list = {
  pending: {
    color: THEME_COLOR9,
  },
  pending_verification: {
    color: THEME_COLOR9,
  },
  active: {
    color: '#4B9A0D',
  },
  inactive: {
    color: THEME_COLOR9,
  },
  suspended: {
    color: THEME_COLOR9,
  }
}

const CustomerDetail = (props) => {
  const [value, setValue] = useState(0);
  const [corporateDetail, setCorporateDetail] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);
  const [importerDetail, setImporterDetail] = useState(null);
  const [productDetail, setProductDetail] = useState(null);

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let user_id = params.get('id');
    let data = {
      user_uid: user_id
    };
    props.getCustomerDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (props?.customerDetail != null) {
      setCorporateDetail(props?.customerDetail?.corporateDetails ? props?.customerDetail.corporateDetails : null);
      setContactDetail(props?.customerDetail?.contactDetails ? props?.customerDetail.contactDetails : null)
      setImporterDetail({
        importLicenseDetails: props?.customerDetail?.importLicenseDetails ? props?.customerDetail.importLicenseDetails : null,
        documentDetails: props?.customerDetail?.documentDetails ? props?.customerDetail.documentDetails : null
      })
      setProductDetail(props?.customerDetail?.productDetails ? props?.customerDetail.productDetails : null)
    }
  }, [props?.customerDetail])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container page={"customers"}>
      <Grid container spacing={2}>

        <Grid item md={12} xs={12}>
          <Typography style={styles.mainTitleText}>Buyer Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} style={styles.borderBottomLine}/>

        <Grid item md={12} xs={12}>
          {/*<Card elevation={0}>*/}
          {/*  <CardContent>*/}
          <Grid item md={3} xs={12} mb={2} mt={1} p={2}
                style={styles.mainBoxView}
          >
            <Typography style={styles.countryTitleText}>
              {contactDetail?.corporateCountry ?
                COUNTRIES.filter(
                  (x) => x.code == contactDetail?.corporateCountry
                )?.[0]?.label
                : '-'}
            </Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={0.5}>
              {importerDetail?.documentDetails?.displayBusinessLogoImage ?
                <Box style={styles.logoImgView} mr={1}>
                  <img src={importerDetail?.documentDetails?.displayBusinessLogoImage} style={styles.logoImgStyle}/>
                </Box>
                : null}
              <Typography style={styles.nameTitleText}>
                {corporateDetail?.nameOfEntity ? corporateDetail?.nameOfEntity : ''}
              </Typography>
            </Box>
            <Box sx={styles.statusView} mt={0.5} display={'flex'}
                 flexDirection={'row'} alignItems={'center'}>
              {props?.customerDetail?.status == 'active' ?
                <Box pr={0.5}>
                  <TickIcon height={15} width={15}/>
                </Box>
                : null}
              <Typography color="text.secondary"
                          sx={[styles.statusText, {...status_list[props?.customerDetail?.status]}]}>
                {props?.customerDetail?.status == 'active' ? 'Active account' : 'Inactive Account'}
              </Typography>
            </Box>
            <Typography style={styles.tgbinTitleText}>
              TGBIN : {contactDetail?.tgbin ? contactDetail?.tgbin : '-'}
            </Typography>
          </Grid>

          {/*</CardContent>*/}

          {/*</Card>*/}
        </Grid>
        <Grid item md={12} xs={12}>

          <Box sx={{width: '100%'}}>
            <Box>
              <Tabs value={value} onChange={handleChange}
                    sx={{
                      "& .MuiTab-root": {
                        color: THEME_COLOR2,
                        fontSize: 15,
                        fontFamily: 'InterBold',
                        textTransform: 'capitalize',
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: THEME_COLOR9,
                      },
                      "& .MuiTab-root.Mui-selected": {
                        color: THEME_COLOR9,
                        fontSize: 15,
                        fontFamily: 'InterBold',
                        textTransform: 'capitalize'
                      }
                    }}
                    aria-label="basic tabs example">
                <Tab label="Contact Details" {...a11yProps(0)} />
                <Tab label="Corporate Details" {...a11yProps(1)} />
                <Tab label="Importer Details" {...a11yProps(2)} />
                <Tab label="Product Details" {...a11yProps(3)} />
                {/*<Tab label="Orders" {...a11yProps(4)} />*/}
              </Tabs>
            </Box>
          </Box>

          <TabPanel value={value} index={0}>
            <CustomerContactDetails
              contactDetail={contactDetail}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <CustomerCorporateDetails
              corporateDetail={corporateDetail}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <CustomerImporterDetails
              importerDetail={importerDetail}
            />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <CustomerProductDetails
              productDetail={productDetail}
            />
          </TabPanel>

        </Grid>
      </Grid>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    customerDetail: state.userData.customerDetail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomerDetail: data => dispatch(getCustomerDetail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);

const styles = {
  mainTitleText: {
    fontSize: 20,
    fontFamily: 'InterBold',
    color: '#000'
  },
  borderBottomLine: {
    borderBottom: '1px solid #aaa'
  },
  mainBoxView: {
    backgroundColor: THEME_COLOR5,
    borderRadius: 4,
    boxShadow: '0px 2px 3px #aaa',
  },
  countryTitleText: {
    fontSize: 13,
    color: '#000',
    fontFamily: 'InterMedium',
  },
  logoImgView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    overflow: 'hidden',
    border: '1px solid #aaa',
    borderRadius: 100,
    height: 30,
    width: 30,
  },
  logoImgStyle: {
    height: '50%',
    width: '50%',
  },
  nameTitleText: {
    color: THEME_COLOR2,
    fontFamily: 'InterBold',
    fontSize: 18,
    textTransform: 'capitalize'
  },
  tgbinTitleText: {
    color: THEME_COLOR2,
    fontFamily: 'InterBold',
    fontSize: 12,
  },
  statusView: {},
  statusText: {
    fontFamily: 'InterBold',
    fontSize: 13,
    textTransform: 'capitalize'
  },
};
