import React, {useEffect} from 'react'
import {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

import {Card, CardContent} from "@mui/material";
import ImageDownload from "./ImageDownload";

const SubTitleView = ({title, subtitle}) => {
  return (
    <Grid container item mt={2} display={"flex"} flexDirection={"row"} alignItems={"center"} md={12} xs={12}>
      <Grid container item md={5} xs={12}>
        <Typography color="text.secondary" style={styles.titleText}>
          {title}
        </Typography>
      </Grid>
      <Grid container item md={7} xs={12}>
        <Typography color="text.secondary" style={styles.subTitleText}>
          {subtitle ? subtitle : '-'}
        </Typography>
      </Grid>
    </Grid>
  )
};

const SellerExporterDetails = (props) => {

  const onDownloadClick = async (imgUrl, name) => {
    const image = await fetch(imgUrl);
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL;
    link.download = "" + name + "";
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  };

  return (
    <Grid item md={12} xs={12}>
      <Box>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        <Typography style={styles.mainTitle}>
          Export License Details
        </Typography>
        <SubTitleView title={'Exporter Name'} subtitle={props.exporterDetail?.exportLicenseDetails?.exporterName}/>
        <SubTitleView title={'Export License No'}
                      subtitle={props.exporterDetail?.exportLicenseDetails?.exportLicenseNo}/>

        {props.exporterDetail?.exportLicenseDetails?.displayExportLicenseDocument ?
          <Grid item md={4} xs={12} mt={3}>
            <Box>
              <Typography style={styles.titleText} color="text.secondary">
                Export License Document
              </Typography>
            </Box>
            <Box mt={2}>
              <ImageDownload
                maxFiles={1}
                fileName={props.exporterDetail?.exportLicenseDetails?.exportLicenseDocument}
                image={props.exporterDetail?.exportLicenseDetails?.displayExportLicenseDocument}
                fileType={'pdf'}
                onDownload={onDownloadClick}/>
            </Box>
          </Grid>
          : null}

        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

      <Box mt={5}>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        <Typography style={styles.mainTitle}>
          Export FSSAI Details
        </Typography>
        <SubTitleView title={'FSSAI Registration No'}
                      subtitle={props.exporterDetail?.exportFssaiDetails?.fssaiRegistrationNo}/>

        {props.exporterDetail?.exportFssaiDetails?.displayFssaiRegistrationDocument ?
          <Grid item md={4} xs={12} mt={3}>
            <Box>
              <Typography style={styles.titleText} color="text.secondary">
                FSSAI Registration Document
              </Typography>
            </Box>
            <Box mt={2}>
              <ImageDownload
                maxFiles={1}
                fileName={props.exporterDetail?.exportFssaiDetails?.fssaiRegistrationDocument}
                image={props.exporterDetail?.exportFssaiDetails?.displayFssaiRegistrationDocument}
                fileType={'pdf'}
                onDownload={onDownloadClick}/>
            </Box>
          </Grid>
          : null}

        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

      <Box mt={5}>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        <Typography style={styles.mainTitle}>
          Export RCMC Details
        </Typography>

        {props.exporterDetail?.exportRcmcDetails?.rcmcDetails.map((item, index) => {
          return (
            <>
              <SubTitleView title={'Board Name'} subtitle={item?.boardName}/>
              <SubTitleView title={'RCMC No'} subtitle={item?.rcmcNo}/>

              {item?.displayRcmcRegistrationDocument ?
                <Grid item md={4} xs={12} mt={3}>
                  <Box>
                    <Typography style={styles.titleText} color="text.secondary">
                      RCMC Registration Document
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <ImageDownload
                      maxFiles={1}
                      fileName={item?.rcmcRegistrationDocument}
                      image={item?.displayRcmcRegistrationDocument}
                      fileType={'pdf'}
                      onDownload={onDownloadClick}/>
                  </Box>
                </Grid>
                : null}
            </>
          )
        })}

        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

      <Box mt={5}>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        <Typography style={styles.mainTitle}>
          Export Tax Details
        </Typography>
        <SubTitleView title={'Business Name'} subtitle={props.exporterDetail?.exportTaxDetails?.businessName}/>
        <SubTitleView title={'Tax Registration No'}
                      subtitle={props.exporterDetail?.exportTaxDetails?.taxRegistrationNo}/>

        {props.exporterDetail?.exportTaxDetails?.displayTaxRegistrationDocument ?
          <Grid item md={4} xs={12} mt={3}>
            <Box>
              <Typography style={styles.titleText} color="text.secondary">
                Tax Registration Document
              </Typography>
            </Box>
            <Box mt={2}>
              <ImageDownload
                maxFiles={1}
                fileName={props.exporterDetail?.exportTaxDetails?.taxRegistrationDocument}
                image={props.exporterDetail?.exportTaxDetails?.displayTaxRegistrationDocument}
                fileType={'pdf'}
                onDownload={onDownloadClick}/>
            </Box>
          </Grid>
          : null}

        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

      <Box mt={5}>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        <Typography style={styles.mainTitle}>
          Bank Details
        </Typography>
        <SubTitleView title={'Account Holder Name'}
                      subtitle={props.exporterDetail?.bankDetails?.accountHolderName}/>
        <SubTitleView title={'Bank Name'} subtitle={props.exporterDetail?.bankDetails?.bankName}/>
        <SubTitleView title={'Account No'} subtitle={props.exporterDetail?.bankDetails?.accountNo}/>
        <SubTitleView title={'IFSC'} subtitle={props.exporterDetail?.bankDetails?.swiftCode}/>
        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

      <Box mt={5}>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        <Typography style={styles.mainTitle}>
          Documents
        </Typography>
        <Grid container item md={12} xs={12}>

          {props.exporterDetail?.documentDetails?.displaySignatureImage ?
            <Grid item md={4} xs={12} mt={3}>
              <Box>
                <Typography style={styles.titleText} color="text.secondary">
                  Signature of the authorized Person
                </Typography>
              </Box>
              <Box mt={2}>
                <ImageDownload
                  maxFiles={1}
                  fileName={props.exporterDetail?.documentDetails?.signatureImage}
                  image={props.exporterDetail?.documentDetails?.displaySignatureImage}
                  fileType={'image'}
                  onDownload={onDownloadClick}/>
              </Box>
            </Grid>
            : null}

          {props.exporterDetail?.documentDetails?.displayBusinessLogoImage ?
            <Grid item md={4} xs={12} mt={3}>
              <Box>
                <Typography style={styles.titleText} color="text.secondary">
                  Business Logo
                </Typography>
              </Box>
              <Box mt={2}>
                <ImageDownload
                  maxFiles={1}
                  fileName={props.exporterDetail?.documentDetails?.businessLogoImage}
                  image={props.exporterDetail?.documentDetails?.displayBusinessLogoImage}
                  fileType={'image'}
                  onDownload={onDownloadClick}/>
              </Box>
            </Grid>
            : null}

        </Grid>
        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>


    </Grid>
  )
}

export default SellerExporterDetails

const styles = {
  titleText: {
    fontFamily: 'InterBold',
    fontSize: 15,
  },
  subTitleText: {
    fontFamily: 'InterBold',
    fontSize: 13,
  },
  mainTitle: {
    fontFamily: 'InterBold',
    fontSize: 17,
    marginBottom: 20
  },
};
