import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import OrderTab from "../../components/OrderTab";

import styles from "./Orders.module.css";

import {connect} from "react-redux";
import {
  getAllCategoryList, getCurrencyRateList,
  getOrderCount
} from "../../redux/actions/userDataActions";

import {THEME_COLOR2, THEME_COLOR9} from '../../constants'
import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import FormSelectBox from "../../components/FormSelectBox";
import OrderTabList from "../../components/OrderTabList";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Orders = (props) => {
  const [searchOrderId, setSearchOrderId] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let order_status = params.get('status');
    if (order_status) {
      if(order_status == 'pending') {
        setValue(0)
      } else if(order_status == 'unshipped') {
        setValue(1)
      } else if(order_status == 'shipped') {
        setValue(2)
      } else if(order_status == 'delivered') {
        setValue(3)
      } else if(order_status == 'cancelled') {
        setValue(4)
      }
    }
  },[])

  useEffect(() => {
    props?.getCurrencyRateList()
  },[])

  useEffect(() => {
    props.getOrderCount()
  }, [])

  useEffect(() => {
    props.getAllCategoryList();
  }, []);

  useEffect(() => {
    if (props?.allCategoryList?.length > 0) {
      setCategoryList(props?.allCategoryList)
    }
  }, [props?.allCategoryList])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeCategory = (val) => {
    setCategory(val);
    setSubCategory('');
    let list = [...categoryList];
    let listIndex = list.findIndex((x) => x.value == val);
    if (listIndex >= 0) {
      setSubCategoryList(list[listIndex]['subCategories']);
    }
  };

  const onSearchBtnClick = () => {
    let filter = {...filterData}
    filter['searchOrderId'] = searchOrderId;
    setFilterData(filter);

  };

  const onRefreshBtnClick = () => {
    let filter = {...filterData}
    filter['category'] = category;
    filter['subCategory'] = subCategory;
    setFilterData(filter);
  };

  return (
    <Container page={"orders"}>

      <Grid item md={12} xs={12} className={styles.borderView} px={2} pt={2}>
        <Typography className={styles.manageOrderText} mb={2}>Manage Orders</Typography>
        <Tabs scrollButtons="on" variant="scrollable" value={value} onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  color: THEME_COLOR2,
                  fontSize: 15,
                  fontFamily: 'InterBold',
                  textTransform: 'capitalize',
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: THEME_COLOR9,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: THEME_COLOR9,
                  fontSize: 15,
                  fontFamily: 'InterBold',
                  textTransform: 'capitalize'
                }
              }}
              aria-label="basic tabs example">
          <Tab label={(props?.pendingOrderCount ? props.pendingOrderCount : 0) + ' Pending'} {...a11yProps(0)} />
          <Tab label={(props?.unshippedOrderCount ? props.unshippedOrderCount : 0) + ' Unshipped'} {...a11yProps(1)} />
          <Tab label={(props?.shippedOrderCount ? props.shippedOrderCount : 0) + ' Shipped'} {...a11yProps(2)} />
          <Tab label={(props?.deliveredOrderCount ? props.deliveredOrderCount : 0) + ' Delivered'} {...a11yProps(3)} />
          <Tab label={(props?.cancelledOrderCount ? props.cancelledOrderCount : 0) + ' Cancelled'} {...a11yProps(4)} />
        </Tabs>
      </Grid>

      <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={2}>
        <Grid item md={7} xs={7}/>
        <Grid item md={1} xs={1}
              className={styles.orderIdBoxView}
              display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography className={styles.orderIdText}>Order ID</Typography>
        </Grid>
        <Grid item md={3} xs={3} pl={1}>
          <FormTextInput
            page={'Orders'}
            value={searchOrderId}
            setValue={setSearchOrderId}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
        <Grid item md={1} xs={1} pl={1}>
          <FormButton
            btnType={'btn9'}
            width={'100%'}
            title={'Search'}
            onSubmit={onSearchBtnClick}
          />
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={4}>
        <Grid item md={1.5} xs={1.5} className={styles.orderCountView} display={'flex'} flexDirection={'row'}
              alignItems={'center'} justifyContent={'center'} px={1}>
          <Typography
            className={styles.orderCountText}>{props?.orderTotalCount ? props?.orderTotalCount : 0}</Typography>
          <Typography className={styles.orderTitleText} pl={1}>
            {props?.orderTotalCount > 1 ? 'Orders' : 'Order'}
          </Typography>
        </Grid>
        <Grid item md={3.5} xs={3.5}/>
        <Grid item md={3} xs={3}>
          <FormSelectBox
            page={'Orders'}
            value={category}
            setValue={(val) => onChangeCategory(val)}
            placeholderColor={'#000'}
            placeholderColorOpacity={'1'}
            textInputView={style.textInputViewStyle}
            data={categoryList}
            placeholder={'Category'}
          />
        </Grid>
        <Grid item md={3} xs={3} pl={1}>
          <FormSelectBox
            page={'Orders'}
            value={subCategory}
            setValue={setSubCategory}
            placeholderColor={'#000'}
            placeholderColorOpacity={'1'}
            textInputView={style.textInputViewStyle}
            data={subCategoryList}
            placeholder={'Sub Category'}
          />
        </Grid>
        <Grid item md={1} xs={1} pl={1}>
          <FormButton
            btnType={'btn8'}
            width={'100%'}
            title={'Refresh'}
            onSubmit={onRefreshBtnClick}
          />
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} className={styles.borderBottomView} mt={1}/>

      <Grid item md={12} xs={12}>
        <TabPanel value={value} index={0}>
          <OrderTab
            tab={'pending'}
            currencyRateList={props?.currencyRateList}
            filterData={filterData}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <OrderTab
            tab={'unshipped'}
            currencyRateList={props?.currencyRateList}
            filterData={filterData}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <OrderTab
            tab={'shipped'}
            currencyRateList={props?.currencyRateList}
            filterData={filterData}
          />
        </TabPanel>


        <TabPanel value={value} index={3}>
          <OrderTab
            tab={'delivered'}
            currencyRateList={props?.currencyRateList}
            filterData={filterData}
          />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <OrderTab
            tab={'cancelled'}
            currencyRateList={props?.currencyRateList}
            filterData={filterData}
          />
        </TabPanel>
      </Grid>

      <AlertMsg/>

    </Container>
  )
};


const mapStateToProps = (state) => {
  return {
    pendingOrderCount: state.userData.pendingOrderCount,
    unshippedOrderCount: state.userData.unshippedOrderCount,
    shippedOrderCount: state.userData.shippedOrderCount,
    deliveredOrderCount: state.userData.deliveredOrderCount,
    cancelledOrderCount: state.userData.cancelledOrderCount,
    allCategoryList: state.userData.allCategoryList,
    orderTotalCount: state.userData.orderTotalCount,
    currencyRateList: state.userData.currencyRateList,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderCount: data => dispatch(getOrderCount(data)),
    getAllCategoryList: (data) => dispatch(getAllCategoryList(data)),
    getCurrencyRateList: (data) => dispatch(getCurrencyRateList(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);

const style = {
  textInputViewStyle: {
    marginTop: 0
  },
};
