import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {getCurrencyConversionCalc} from '../assets/functions/common'
import ProductViewAction from "./ProductViewAction";

import {COUNTRIES} from "../constants/countries";
import {THEME_COLOR2, THEME_COLOR9} from "../constants";

const status_list = {
  pending: {
    color: THEME_COLOR9,
  },
  active: {
    color: THEME_COLOR2,
  },
  inactive: {
    color: THEME_COLOR9,
  },
  removed: {
    color: THEME_COLOR9,
  }
}

const ProductView = (props) => {
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [pricePerKgWithoutReferralFee, setPricePerKgWithoutReferralFee] = useState(0);
  const [pricePerKgReferralFee, setPricePerKgReferralFee] = useState(0);
  const [pricePerPackageWithoutReferralFee, setPricePerPackageWithoutReferralFee] = useState(0);
  const [referralFeePerPackage, setReferralFeePerPackage] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [convertedPrice, setConvertedPrice] = useState(0);
  const [convertedPricePerKg, setConvertedPricePerKg] = useState(0);
  const [convertedTotalPrice, setConvertedTotalPrice] = useState(0);
  const [convertedShippingCost, setConvertedShippingCost] = useState('')
  const [convertedCargoPrice, setConvertedCargoPrice] = useState(0);
  const [convertedPricePerKgWithoutReferralFee, setConvertedPricePerKgWithoutReferralFee] = useState(0);
  const [convertedPricePerKgReferralFee, setConvertedPricePerKgReferralFee] = useState(0);
  const [convertedPricePerPackageWithoutReferralFee, setConvertedPricePerPackageWithoutReferralFee] = useState(0);
  const [convertedReferralFeePerPackage, setConvertedReferralFeePerPackage] = useState(0);
  const [adminCurrencySymbol, setAdminCurrencySymbol] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    if (props.data) {
      let currency_symbol = '';
      let price = 0;
      let price_per_kg = 0;
      let total_price = 0;
      let shipping_cost = 0;
      let cargo_price = 0;
      let price_per_kg_without_referral_fee = 0;
      let price_per_kg_referral_fee = 0;
      let price_per_package_without_referral_fee = 0;
      let referral_fee_per_package = 0;
      let admin_currency_symbol = '';
      let converted_price = 0;
      let converted_price_per_kg = 0;
      let converted_total_price = 0;
      let converted_shipping_cost = 0;
      let converted_cargo_price = 0;
      let converted_price_per_kg_without_referral_fee = 0;
      let converted_price_per_kg_referral_fee = 0;
      let converted_price_per_package_without_referral_fee = 0;
      let converted_referral_fee_per_package = 0;

      let data_list = {
        price_per_package: props?.data?.pricePerPackage,
        package_net_weight: props?.data?.packageNetWeight,
        referral_fee: props?.data?.referralFee,
        quantity: props?.data?.quantity,
        shipping_cost: props?.data?.shippingCost,
        currency: props?.data?.sellerCurrency,
        admin_currency: props?.data?.adminCurrency,
        admin_currency2: props?.data?.adminCurrency2
      }

      let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
      if (data != null) {
        price = data?.price;
        price_per_kg = data?.price_per_kg;
        total_price = data?.total_price;
        shipping_cost = data?.shipping_cost;
        cargo_price = data?.cargo_price;
        price_per_kg_without_referral_fee = data?.price_per_kg_without_referral_fee;
        price_per_kg_referral_fee = data?.price_per_kg_referral_fee;
        price_per_package_without_referral_fee = data?.price_per_package_without_referral_fee;
        referral_fee_per_package = data?.referral_fee_per_package;
        currency_symbol = data?.currency_symbol;
        converted_price = data?.converted_price;
        converted_price_per_kg = data?.converted_price_per_kg;
        converted_total_price = data?.converted_total_price;
        converted_shipping_cost = data?.converted_shipping_cost;
        converted_cargo_price = data?.converted_cargo_price;
        converted_price_per_kg_without_referral_fee = data?.converted_price_per_kg_without_referral_fee;
        converted_price_per_kg_referral_fee = data?.converted_price_per_kg_referral_fee;
        converted_price_per_package_without_referral_fee = data?.converted_price_per_package_without_referral_fee;
        converted_referral_fee_per_package = data?.converted_referral_fee_per_package;
        admin_currency_symbol = data?.admin_currency_symbol;
      }

      setPrice(price);
      setTotalPrice(total_price);
      setPricePerKg(price_per_kg);
      setCargoPrice(cargo_price)
      setShippingCost(shipping_cost)
      setPricePerKgWithoutReferralFee(price_per_kg_without_referral_fee)
      setPricePerKgReferralFee(price_per_kg_referral_fee)
      setPricePerPackageWithoutReferralFee(price_per_package_without_referral_fee)
      setReferralFeePerPackage(referral_fee_per_package)
      setCurrencySymbol(currency_symbol)
      setConvertedPrice(converted_price);
      setConvertedTotalPrice(converted_total_price)
      setConvertedPricePerKg(converted_price_per_kg);
      setConvertedCargoPrice(converted_cargo_price)
      setConvertedShippingCost(converted_shipping_cost)
      setConvertedPricePerKgWithoutReferralFee(converted_price_per_kg_without_referral_fee);
      setConvertedPricePerKgReferralFee(converted_price_per_kg_referral_fee)
      setConvertedPricePerPackageWithoutReferralFee(converted_price_per_package_without_referral_fee)
      setConvertedReferralFeePerPackage(converted_referral_fee_per_package)
      setAdminCurrencySymbol(admin_currency_symbol)
    }
  }, [props.data, props?.currencyRateList])

  let item = props?.data;

  return (
    <>
      <TableRow>
        <TableCell>
          {
            COUNTRIES.filter(
              (x) => x.code == item.seller_country
            )?.[0]?.label
          }
          {/*{item.seller_county}*/}
        </TableCell>
        <TableCell>
          {item?.sellerNameEntity ? (item.sellerNameEntity) : ''} {item?.tgsin ? '(' + item.tgsin + ')' : ''}
        </TableCell>
        <TableCell>{item.sku ? item.sku : "-"}</TableCell>
        <TableCell>
          {item.product} (
          {item.hsCode + " - " + item.hsCodeTitle})
        </TableCell>
        <TableCell>
          {item.pickupLocation ? item.pickupLocation : "-"}
        </TableCell>
        <TableCell>
          {(item.packageNetWeight && item.packingMaterial) ? item.packageNetWeight + ' kg ' + item.packingMaterial : '-'}
        </TableCell>
        <TableCell>
          <Typography sx={styles.valueText}>
            Price/Kg:
            {adminCurrencySymbol}{convertedPricePerKgWithoutReferralFee ? convertedPricePerKgWithoutReferralFee?.toFixed(2)
            : 0}
          </Typography>
          <Typography sx={styles.valueText} mt={1}>
            Referral Fee: {adminCurrencySymbol}{convertedPricePerKgReferralFee ? convertedPricePerKgReferralFee?.toFixed(2) : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={styles.valueText}>Price/Package: {currencySymbol}{pricePerPackageWithoutReferralFee ? pricePerPackageWithoutReferralFee?.toFixed(2) : 0}</Typography>
          <Typography sx={styles.valueText}
                      pl={3}>({adminCurrencySymbol}{convertedPricePerPackageWithoutReferralFee ? convertedPricePerPackageWithoutReferralFee?.toFixed(2) : 0})</Typography>
          <Typography sx={styles.valueText} mt={1}>Referral
            Fee: {currencySymbol}{referralFeePerPackage ? referralFeePerPackage?.toFixed(2) : 0}</Typography>
          <Typography sx={styles.valueText}
                      pl={3}>({adminCurrencySymbol}{convertedReferralFeePerPackage ? convertedReferralFeePerPackage?.toFixed(2) : 0})</Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={styles.valueText}>{item.createdTimestamp ? 'Created Timestamp: ' + item.createdTimestamp : ''}</Typography>
          <Typography sx={styles.valueText}
                      mt={1}>{item.timestamp ? 'Status Changed Date: ' + item.timestamp : ''}</Typography>
        </TableCell>
        <TableCell>
          <Typography sx={[styles.statusText, status_list[item?.status]]}>{item.status}</Typography>
        </TableCell>
        {item?.status != 'removed' ?
          <TableCell>
            <ProductViewAction
              page={'list'}
              item={item}
            />
          </TableCell>
          : null}
      </TableRow>
    </>
  )
}

export default ProductView;

const styles = {
  statusText: {
    fontFamily: 'InterBold',
    fontSize: 12,
    textTransform: 'capitalize'
  },
  valueText: {
    color: '#000',
    fontSize: 12,
    fontFamily: 'InterMedium'
  }
};

