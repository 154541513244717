import React, { useEffect } from 'react'
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import FormButton from "../components/FormButton";
import ImageUpload from "../components/ImageUpload"

import { ReactComponent as CloseIcon } from "../assets/img/close.svg";

const UploadMainProductImageModal = (props) => {
  const [image, setImage] = useState(null);
  const [displayImage, setDisplayImage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!props.open && props?.display) {
      setImage(null);
      setDisplayImage(props?.display);
      setError(null)
    }
  }, [props.open, props?.display]);

  const onCloseClick = () => {
    props.onClose();
  };

  const onSubmitBtnClick = () => {
    let errorText = {};
    if (image == null) {
      errorText = { image: 'Image is required' };
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('productUid', props?.productUid);
    formData.append('main_product_image', image);

    props.onSubmit(formData);
  };

  const onRemoveImage = () => {
    setImage(null);
    setDisplayImage('');
  };

  const onChangeImage = (fileList) => {
    setImage(fileList[0].file);
    setDisplayImage(fileList[0].image);
  };

  return (
    <Modal
      open={props.open}
      onClose={onCloseClick}
      style={styles.modelView}
      hideBackdrop={true}>
      <Box sx={styles.modalSize}>
        <Box sx={styles.headerView}>
          <Typography align="center" sx={styles.headerTitle}>
            Upload Main Image
          </Typography>
          <Box sx={styles.closeBtnView} onClick={props.onClose}>
            <CloseIcon width={23} height={23} />
          </Box>
        </Box>

        <div>
          <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12} mt={4}
                container>
            <Typography sx={styles.labelText}>Image</Typography>
            <Box pl={1}>
              <Typography sx={styles.requiredText}>*</Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12} mt={2} container>
            <ImageUpload
              maxFiles={1}
              image={displayImage}
              error={error?.image}
              onRemove={onRemoveImage}
              onChange={onChangeImage} />
          </Grid>
        </div>

        <Box display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box mr={2}>
            <FormButton
              btnType={'cancel'}
              title={"Cancel"}
              btnStyleView={{ height: 35 }}
              onSubmit={onCloseClick}
            />
          </Box>
          <Box>
            <FormButton
              btnType={'btn5'}
              title={"Upload"}
              btnStyleView={{ height: 35 }}
              onSubmit={onSubmitBtnClick}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UploadMainProductImageModal

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalSize: {
    backgroundColor: '#FFF',
    width: { md: '30%', xs: '90%' },
    borderRadius: 2,
    padding: 4
  },
  headerView: {
    position: 'relative',
  },
  headerTitle: {
    fontFamily: 'InterBold',
    fontSize: 20
  },
  closeBtnView: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    top: 0,
    bottom: 0,
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  labelText: {
    color: '#000',
    fontSize: 15,
    fontFamily: 'InterMedium',
  },
};
