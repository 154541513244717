import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField} from '@mui/material';

import styles from "./ReviewDeliveryAddress.module.css"

const ReviewDeliveryAddress = (props) => {

  return (

    <Grid container item md={12} xs={12} className={styles.addressMainView} p={3}
          mt={1}>
      <Grid item md={12} xs={12} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>Address Line1</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.delivery_address?.address_line_1}</Typography>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} mt={2} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>Address Line2</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.delivery_address?.address_line_2}</Typography>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} mt={2} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>City</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.delivery_address?.city}</Typography>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} mt={2} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>State/Province</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.delivery_address?.state_province}</Typography>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} mt={2} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>Zip Code</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.delivery_address?.zip_code}</Typography>
        </Grid>
      </Grid>

    </Grid>

  )
};

export default ReviewDeliveryAddress;
