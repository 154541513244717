import React, {useEffect} from 'react'
import {useState} from 'react';
import Grid from '@mui/material/Grid';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";

import AddSubCategoryModal from '../../components/AddSubCategoryModal';
import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import Page from "../../components/Page";
import AlertDialogBox from "../../components/AlertDialogBox";

import '../../assets/css/custom.css';

import {connect} from "react-redux";
import {addSubCategory, listSubCategory, updateApiStatus, deleteSubCategory} from "../../redux/actions/userDataActions";

import {ReactComponent as DeclineIcon} from "../../assets/img/decline.svg";

const buttonList = {
  removed: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  },
};

const SubCategory = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [open, setOpen] = useState(false);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [subCategoryUid, setSubCategoryUid] = useState('');

  useEffect(() => {
    getSubCategoryList(1);
  }, []);

  useEffect(() => {
    if(props?.apiStatus == 'DELETE_SUB_CATEGORY_SUCCESS') {
      setSubCategoryUid('')
      setConfirmBoxVisible(false)
      props?.updateApiStatus('')
    } else if(props?.apiStatus == 'DELETE_SUB_CATEGORY_FAIL') {
      setSubCategoryUid('')
      setConfirmBoxVisible(false)
      props?.updateApiStatus('')
    }
  },[props?.apiStatus])

  const getSubCategoryList = (page) => {
    let data = {
      page: page
    };
    props.listSubCategory(JSON.stringify(data))
    setPageNum(page)
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmitBtnClick = (data) => {
    props.addSubCategory(data)
    setOpen(false);
  };

  const onDeleteSubCategory = (uid) => {
    setSubCategoryUid(uid);
    setConfirmBoxVisible(true);
  };

  const onConfirmClick = () => {
    let data = {
      subCategoryUid: subCategoryUid
    };
    props?.deleteSubCategory(JSON.stringify(data))
  };

  const onCancelClick = () => {
    setSubCategoryUid('');
    setConfirmBoxVisible(false);
  };

  return (
    <Container page={"subcategory"}>
      <Grid item md={12} xs={12}
            container
            display={"flex"}
            alignItems={"center"}>
        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
          <h2>Sub Category</h2>
          <FormButton
            btnType={'btn5'}
            onSubmit={handleOpen}
            title={"Add Sub Category"}
          />
        </Grid>

        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              mt={3}
              style={styles.cardDiv}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Sub Category</TableCell>
                  <TableCell>category</TableCell>
                  <TableCell>Referral Fee/Kg (%)</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.subCategoryList != null ?
                  props.subCategoryList.map((item, index) => (
                    <TableRow
                      key={index}>
                      <TableCell>
                        <img src={item.image} height={50} width={50} style={styles.listImageView}/>
                      </TableCell>
                      <TableCell>{item.subCategory}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.referralFee + '%'}</TableCell>
                      <TableCell>{item.timestamp}</TableCell>
                      <TableCell>
                        <Box sx={styles.pointerView}>
                          <FormButton
                            title={'Delete'}
                            btnType={'btn7'}
                            width={'50%'}
                            onSubmit={() => onDeleteSubCategory(item.uid)}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
            {props.subCategoryTotalPages > 0 ?
              <Page
                totalPages={props.subCategoryTotalPages}
                pageNum={pageNum}
                onSelectPage={getSubCategoryList}/>
              : null}
          </Box>
        </Grid>
      </Grid>
      <div>
        <AddSubCategoryModal
          open={open}
          onClose={handleClose}
          onSubmit={onSubmitBtnClick}/>
      </div>

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={'removed'}
        icon={buttonList['removed']['icon']}
        content={buttonList['removed']['content']}
        firstBtn={buttonList['removed']['firstBtn']}
        secondBtn={buttonList['removed']['secondBtn']}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    subCategoryLoading: state.userData.subCategoryLoading,
    subCategoryTotalPages: state.userData.subCategoryTotalPages,
    subCategoryList: state.userData.subCategoryList,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSubCategory: data => dispatch(addSubCategory(data)),
    listSubCategory: data => dispatch(listSubCategory(data)),
    deleteSubCategory: data => dispatch(deleteSubCategory(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);

const styles = {
  cardDiv: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFF',
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  listImageView: {
    borderRadius: 10
  },
  pointerView: {
    cursor: 'pointer'
  }
};
