import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useNavigate} from "react-router-dom";

import Page from "./Page";
import CartShippingCostPendingList from "./CartShippingCostPendingList";
import AlertDialogBox from "./AlertDialogBox";
import AddShippingCostModal from "./AddShippingCostModal";

import {connect} from "react-redux";
import {listCartShippingCostPending, addCartShippingCost, deleteCart, updateApiStatus} from "../redux/actions/userDataActions";

import styles from './CartShippingCostPending.module.css'

import {ReactComponent as DeclineIcon} from "../assets/img/decline.svg"
import {CircularProgress} from "@mui/material";
import CartCheckoutPendingList from "./CartCheckoutPendingList";

const buttonList = {
  removed: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  }
};


const CartShippingCostPending = (props) => {
  const [cartData, setCartData] = useState([]);
  const [cartUid, setCartUid] = useState('');
  const [type, setType] = useState('');
  const [actualCurrencySymbol, setActualCurrencySymbol] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.type == 'detail') {
    } else {
      getCartShippingCostPendingList(1);
    }
  }, []);

  useEffect(() => {
    if(props?.data != null && props?.type == 'detail') {
      setCartData([props?.data]);
    }
  },[props?.data, props?.type])

  useEffect(() => {
    if (props.apiStatus == 'DELETE_CART_SUCCESS') {
      setType('');
      setCartUid('');
      setConfirmBoxVisible(false);
      setActualCurrencySymbol('');
      props.updateApiStatus();
      if (props?.type == 'detail') {
        navigate('/cart')
      }
    } else if (props.apiStatus == 'ADD_CART_SHIPPING_COST_SUCCESS') {
      setCartUid('')
      setModalVisible(false);
      setActualCurrencySymbol('');
      if (props?.type == 'detail') {
        navigate('/cart')
      }
    }
  }, [props.apiStatus])

  useEffect(() => {
    if (props?.type == 'list') {
      setCartData(props?.cartShippingCostPendingList);
    }
  }, [props.cartShippingCostPendingList])

  const getCartShippingCostPendingList = (page) => {
    let data = {
      page: page,
      status: 'shipping_cost_pending'
    };
    props.listCartShippingCostPending(JSON.stringify(data))
    setPageNum(page)
  };

  const onPageSelect = (page) => {
    getCartShippingCostPendingList(page);
  };

  const onDeleteCart = (cart_uid) => {
    setType('removed');
    setCartUid(cart_uid);
    setConfirmBoxVisible(true);
  };

  const onConfirmClick = () => {
    let data = {
      cartUid: cartUid,
      currentStatus: 'shipping_cost_pending'
    };
    props.deleteCart(JSON.stringify(data))
  };

  const onCancelClick = () => {
    setType('');
    setCartUid('');
    setConfirmBoxVisible(false);
    setActualCurrencySymbol('')
  };

  const onViewCartDetail = (cart_uid) => {
    navigate("/cart_shipping_cost_pending_detail/" + cart_uid);
  };

  const onAddShipping = (cart_uid, actualCurrencySymbol) => {
    setActualCurrencySymbol(actualCurrencySymbol)
    setCartUid(cart_uid);
    setModalVisible(true);
  };

  const onSubmitShippingCostModal = (data) => {
    props.addCartShippingCost(JSON.stringify(data))
  };

  const onCloseShippingCostModal = () => {
    setCartUid('')
    setModalVisible(false);
    setActualCurrencySymbol('')
  };

  if (props.cartLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cart ID</TableCell>
              <TableCell className={styles.dataView}>Product Details</TableCell>
              <TableCell className={styles.dataView}>Delivery Address</TableCell>
              <TableCell className={styles.dataView}>Shipping Details</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>Total Product Price</TableCell>
              <TableCell>Total Shipping Cost</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartData?.length > 0 ?
              cartData?.map((item, index) => (
                <TableRow
                  className={styles.tableRow}
                  key={index}>
                  <CartShippingCostPendingList
                    key={index}
                    index={index}
                    data={item}
                    onDeleteCart={onDeleteCart}
                    onViewCartDetail={onViewCartDetail}
                    onAddShipping={onAddShipping}
                    type={props?.type}
                    currencyRateList={props?.currencyRateList}
                  />
                </TableRow>
              )) : null}
          </TableBody>
        </Table>
      </TableContainer>


      {props?.type == 'list' ?
        <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
          {props.cartShippingCostPendingTotalPages > 0 ?
            <Page
              totalPages={props.cartShippingCostPendingTotalPages}
              pageNum={pageNum}
              onSelectPage={onPageSelect}/>
            : null}
        </Box>
        : null}

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={type}
        icon={type != '' ? buttonList[type]['icon'] : ''}
        content={type != '' ? buttonList[type]['content'] : ''}
        firstBtn={type != '' ? buttonList[type]['firstBtn'] : ''}
        secondBtn={type != '' ? buttonList[type]['secondBtn'] : ''}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />

      <AddShippingCostModal
        open={modalVisible}
        actualCurrencySymbol={actualCurrencySymbol}
        cartUid={cartUid}
        loading={props.shippingCostLoading}
        onClose={onCloseShippingCostModal}
        onSubmit={onSubmitShippingCostModal}/>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cartShippingCostPendingTotalPages: state.userData.cartShippingCostPendingTotalPages,
    cartShippingCostPendingList: state.userData.cartShippingCostPendingList,
    shippingCostLoading: state.userData.shippingCostLoading,
    cartLoading: state.userData.cartLoading,
    apiStatus: state.userData.apiStatus,
    currencyRateList: state.userData.currencyRateList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listCartShippingCostPending: data => dispatch(listCartShippingCostPending(data)),
    deleteCart: data => dispatch(deleteCart(data)),
    addCartShippingCost: data => dispatch(addCartShippingCost(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartShippingCostPending);
