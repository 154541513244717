import React from 'react';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { THEME_COLOR5 } from "../constants";

const Page = (props) => {

  const theme = createTheme({
    palette: {
      theme_color_5: {
        main: THEME_COLOR5,
      },
    },
  });

  const handleChange = (event, value) => {
    props.onSelectPage(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Pagination
        sx={{
          '& .MuiPaginationItem-root.Mui-selected': {
            color: '#000',
            fontFamily: 'InterMedium',
            ":hover": {
              backgroundColor: THEME_COLOR5,
              color: "#000"
            }
          },
          '& .MuiPaginationItem-root': {
            ":hover": {
              color: THEME_COLOR5,
            }
          }
        }}
        count={props.totalPages}
        page={props.pageNum}
        onChange={handleChange}
        color="theme_color_5"
        shape={"rounded"} />
    </ThemeProvider>
  );
};

export default Page;
