import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField} from '@mui/material';

import styles from "./ReviewPaymentMethod.module.css"

const ReviewPaymentMethod = (props) => {

  return (
    <Grid container item md={12} xs={12} className={styles.mainView} p={3}
          mt={1}>
      <Grid item md={12} xs={12} display={'flex'}
            flexDirection={'column'} justifyContent={'center'}>
        {props?.data?.shipping_details?.payment_term ?
          <>
            <Typography className={styles.paymentTermText}>{props?.data?.shipping_details?.payment_term}</Typography>
            <Typography className={styles.paymentTermDescText}
                        mt={1}>({props?.data?.shipping_details?.payment_term_description})</Typography>
          </>
          : <>
            <Typography className={styles.paymentTermText}>No Payment Selected</Typography>
          </>}
      </Grid>

    </Grid>
  )
};

export default ReviewPaymentMethod;
