import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const FormTextInput = (props) => {

  return (
    <Grid item md={12} xs={12}
          container
          display={"flex"}>
      {props.label ?
        <Grid display={"flex"}
              flexDirection={"row"} item md={12} xs={12}
              container>
          <Typography sx={[styles.labelText, props?.labelTextStyle]}>{props.label}</Typography>
          {props?.required ?
            <Box pl={1}>
              <Typography sx={styles.requiredText}>*</Typography>
            </Box>
            : null}
        </Grid>
        : null}
      <Grid sx={[styles.textInputView, props?.textInputViewStyle]} item md={12} xs={12}
            container>
        <TextField
          disabled={props?.disabled}
          placeholder={props?.placeholder}
          required={props?.required ? true : false}
          fullWidth
          variant={props?.variant ? props?.variant : "standard"}
          value={props.value}
          type={props?.type ? props.type : 'text'}
          onChange={e => props.setValue(e.target.value)}
          sx={{
            '& .MuiInput-root': {
              '&:before, :after, :hover:not(.Mui-disabled):before': {
                borderBottom: 0,
              },
            },
            input: {
              "&::placeholder": {
                color: '#a1a5b7',
                opacity: 1,
                fontFamily: 'InterMedium',
              },
            },
          }}
          inputProps={{
            autoComplete: 'none',
            sx: styles.textInputValue,
          }}
        />
      </Grid>
      {props.error ?
        <Box mt={1} ml={0.5} styles={styles.errorView}>
          <Typography sx={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Grid>
  )
}

export default FormTextInput;

const styles = {
  labelText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputView: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
    borderRadius: 2,
    py: 0.8,
    marginTop: 1,
  },
  textInputValue: {
    color: '#000',
    fontSize: 13,
    fontFamily: 'InterMedium',
    px: 1.5,
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'InterMedium',
  }
};
