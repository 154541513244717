import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';

import SignIn from "./containers/Auth/SignIn";
import SignOut from "./containers/Auth/SignOut";
import Index from "./containers/Dashboard/Index";
import Vendor from "./containers/Vendor/Vendor";
import SellerDetail from './containers/Vendor/SellerDetail';
import Product from './containers/Product/Product';
import ProductDetail from "./containers/Product/ProductDetail";
import Category from './containers/Category/Category';
import Subcategory from "./containers/SubCategory/Subcategory";
import Customers from './containers/Customers/Customers';
import CustomerDetail from './containers/Customers/CustomerDetail';
import Orders from './containers/Order/Orders';
import OrderDetail from './containers/Order/OrderDetail';
import Cart from './containers/Cart/Cart';
import CartShippingCostPendingDetail from "./containers/Cart/CartShippingCostPendingDetail";
import BeneficiaryDetails from "./containers/BeneficiaryDetails/BeneficiaryDetails";
import BookingDetails from "./containers/Order/BookingDetails";
import UploadDocuments from "./containers/Order/UploadDocuments";

const App = props => {

  let routes = (
    <Routes>
      <Route exact path="/sign_in" element={<SignIn {...props} />} />
      <Route exact path="/" element={<Navigate replace to="/sign_in" />} />
      <Route path="*" element={<Navigate replace to="/sign_in" />} />
    </Routes>
  );

  if (props.userToken) {
    routes = (
      <Routes>
        <Route exact path="/" element={<Navigate replace to="/index" />} />
        <Route exact path="/sign_out" element={<SignOut {...props} />} />
        <Route exact path="/index" element={<Index {...props} />} />
        <Route exact path="/seller" element={<Vendor {...props} />} />
        <Route exact path="/seller_detail" element={<SellerDetail {...props} />} />
        <Route exact path="/product" element={<Product {...props} />} />
        <Route exact path="/product/:id" element={<ProductDetail {...props} />} />
        <Route exact path="/category" element={<Category {...props} />} />
        <Route exact path="/subcategory" element={<Subcategory {...props} />} />
        <Route exact path="/customers" element={<Customers {...props} />} />
        <Route exact path="/customer_detail" element={<CustomerDetail {...props} />} />
        <Route exact path="/orders" element={<Orders {...props} />} />
        <Route exact path="/order_detail/:id" element={<OrderDetail {...props} />} />
        <Route exact path="/cart" element={<Cart {...props} />} />
        <Route exact path="/cart_shipping_cost_pending_detail/:id" element={<CartShippingCostPendingDetail {...props} />} />
        <Route exact path="/beneficiary_details" element={<BeneficiaryDetails {...props} />} />
        <Route exact path="/booking_details/:id" element={<BookingDetails {...props} />} />
        <Route exact path="/upload_documents/:id" element={<UploadDocuments {...props} />} />
        <Route path="/" element={<Navigate replace to="/" />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return (
    <BrowserRouter>
      {routes}
    </BrowserRouter>
  );
}


const mapStateToProps = state => {
  return {
    userToken: state.userData.userToken,
  }
};

export default connect(
  mapStateToProps,
  null
)(App);
