import React, {useEffect} from 'react'
import {useState} from 'react';
import Box from '@mui/material/Box';
import {Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import FormButton from "../components/FormButton";
import FormTextInput from '../components/FormTextInput';
import ImageUpload from "../components/ImageUpload"
import FormSelectBox from './FormSelectBox';

import {connect} from "react-redux";
import {listProductCategory} from "../redux/actions/userDataActions";

import {ReactComponent as CloseIcon} from "../assets/img/close.svg";

const AddSubCategoryModal = (props) => {
  const [subCategory, setSubCategory] = useState("")
  const [category, setCategory] = useState("")
  const [categoryImage, setCategoryImage] = useState(null);
  const [displayImage, setDisplayImage] = useState('');
  const [referralFee, setReferralFee] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!props.open) {
      setSubCategory('');
      setCategory('')
      setCategoryImage(null);
      setDisplayImage('');
      setReferralFee('');
      setError(null)
    } else {
      getProductCategoryList();
    }
  }, [props.open]);

  const getProductCategoryList = () => {
    props.listProductCategory();
  };

  const onSubmitBtnClick = () => {
    let errorText = {};
    if (categoryImage == null) {
      errorText = {image: 'Image is required'};
    }
    if (subCategory == '') {
      errorText = {...errorText, subCategory: 'Sub Category is required'};
    }
    if (category == '') {
      errorText = {...errorText, category: 'Category is required'};
    }
    if (referralFee == '') {
      errorText = {...errorText, referralFee: 'Referral Fee is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(referralFee)) {
      errorText = {...errorText, referralFee: 'Invalid Referral Fee'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('subCategory', subCategory);
    formData.append('category', category);
    formData.append('sub_category_image', categoryImage);
    formData.append('referral_fee', referralFee);

    props.onSubmit(formData);
  };

  const onRemoveImage = () => {
    setCategoryImage(null);
    setDisplayImage('');
  };

  const onChangeImage = (fileList) => {
    setCategoryImage(fileList[0].file);
    setDisplayImage(fileList[0].image);
  };

  const onCloseClick = () => {
    props.onClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={onCloseClick}
      style={styles.modelView}
      hideBackdrop={true}>
      <Box sx={styles.modalSize}>
        <Box sx={styles.headerView}>
          <Typography align="center" sx={styles.headerTitle}>
            Add Sub Category
          </Typography>
          <Box sx={styles.closeBtnView} onClick={props.onClose}>
            <CloseIcon width={23} height={23}/>
          </Box>
        </Box>

        <div>
          <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12} mt={4}
                container>
            <Typography sx={styles.labelText}>Image</Typography>
            <Box pl={1}>
              <Typography sx={styles.requiredText}>*</Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12} mt={2} container>
            <ImageUpload
              maxFiles={1}
              image={displayImage}
              error={error?.image}
              onRemove={onRemoveImage}
              onChange={onChangeImage}
            />
          </Grid>
        </div>

        <Grid mt={3}>
          <FormTextInput
            page={'add_Subcategory'}
            value={subCategory}
            setValue={setSubCategory}
            label={'Sub Category'}
            error={error?.subCategory}
            required={true}
          />
        </Grid>

        <Grid mt={3}>
          <FormSelectBox
            page={'add_Subcategory'}
            value={category}
            setValue={setCategory}
            label={'Category'}
            data={props?.productCategoryList ? props.productCategoryList : []}
            placeholder={'Select Category'}
            error={error?.category}
            required={true}
          />
        </Grid>

        <Grid mt={3}>
          <FormTextInput
            page={'add_Subcategory'}
            value={referralFee}
            setValue={setReferralFee}
            label={'Referral Fee/Kg (%)'}
            error={error?.referralFee}
            required={true}
          />
        </Grid>

        <Box display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box mr={2}>
            <FormButton
              btnType={'cancel'}
              title={"Cancel"}
              btnStyleView={{height: 35}}
              onSubmit={onCloseClick}
            />
          </Box>
          <Box>
            <FormButton
              btnType={'btn5'}
              title={"Submit"}
              btnStyleView={{height: 35}}
              onSubmit={onSubmitBtnClick}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    productCategoryList: state.userData.productCategoryList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listProductCategory: data => dispatch(listProductCategory(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubCategoryModal);

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalSize: {
    backgroundColor: '#FFF',
    width: {md: '40%', xs: '90%'},
    borderRadius: 2,
    padding: 4
  },
  headerView: {
    position: 'relative',
  },
  headerTitle: {
    fontFamily: 'InterBold',
    fontSize: 20
  },
  closeBtnView: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    top: 0,
    bottom: 0,
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  labelText: {
    color: '#000',
    fontSize: 15,
    fontFamily: 'InterMedium',
  },
};
