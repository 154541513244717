import { put, takeLatest, call } from "redux-saga/effects";

import {
  LOGIN_USER,
  LIST_VENDOR,
  GET_SELLER_DETAIL,
  UPDATE_SELLER_STATUS,
  LIST_PRODUCT,
  ADD_CATEGORY,
  LIST_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_FEATURED,
  ADD_SUB_CATEGORY,
  LIST_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  LIST_PRODUCT_CATEGORY,
  LIST_CUSTOMER,
  GET_CUSTOMER_DETAIL,
  UPDATE_CUSTOMER_STATUS,
  GET_ORDER_DETAIL,
  GET_DASHBOARD,
  GET_PRODUCT_DETAIL,
  UPDATE_PRODUCT_STATUS,
  ADD_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  UPDATE_MAIN_PRODUCT_IMAGE,
  LIST_CART_REVIEW_PENDING,
  LIST_CART_SHIPPING_COST_PENDING,
  LIST_CART_CHECKOUT_PENDING,
  DELETE_CART,
  GET_CART_DETAIL,
  ADD_CART_SHIPPING_COST,
  GET_CART_COUNT,
  LIST_ORDER,
  GET_BENEFICIARY_DETAILS,
  ADD_BENEFICIARY_DETAILS,
  UPDATE_BENEFICIARY_DETAILS,
  ADD_ORDER_BOOKING_DETAILS,
  CONFIRM_PENDING_ORDER,
  UPLOAD_ORDER_DOCUMENTS,
  CONFIRM_ORDER_PAYMENT,
  UPLOAD_ORDER_TRACK,
  CONFIRM_ORDER_DELIVERY,
  GET_ORDER_COUNT,
  MESSAGE_TO_SELLER,
  GET_ALL_CATEGORY_LIST,
  GET_CURRENCY_RATE_LIST
} from "../actions/types";
import {
  loginUserSuccess,
  loginUserFail,
  listVendorSuccess,
  listVendorFail,
  getSellerDetailSuccess,
  getSellerDetailFail,
  updateSellerStatusSuccess,
  updateSellerStatusFail,
  listProductSuccess,
  listProductFail,
  addCategorySuccess,
  addCategoryFail,
  listCategorySuccess,
  listCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
  updateCategoryFeaturedSuccess,
  updateCategoryFeaturedFail,
  addSubCategorySuccess,
  addSubCategoryFail,
  listSubCategorySuccess,
  listSubCategoryFail,
  deleteSubCategorySuccess,
  deleteSubCategoryFail,
  listProductCategorySuccess,
  listProductCategoryFail,
  listCustomerSuccess,
  listCustomerFail,
  getCustomerDetailSuccess,
  getCustomerDetailFail,
  updateCustomerStatusSuccess,
  updateCustomerStatusFail,
  getDashboardSuccess,
  getDashboardFail,
  getProductDetailSuccess,
  getProductDetailFail,
  updateProductStatusSuccess,
  updateProductStatusFail,
  addProductImageSuccess,
  addProductImageFail,
  deleteProductImageSuccess,
  deleteProductImageFail,
  updateMainProductImageSuccess,
  updateMainProductImageFail,
  listCartReviewPendingSuccess,
  listCartReviewPendingFail,
  listCartShippingCostPendingSuccess,
  listCartShippingCostPendingFail,
  listCartCheckoutPendingSuccess,
  listCartCheckoutPendingFail,
  deleteCartSuccess,
  deleteCartFail,
  getCartDetailSuccess,
  getCartDetailFail,
  addCartShippingCostSuccess,
  addCartShippingCostFail,
  getCartCountSuccess,
  getCartCountFail,
  listOrderSuccess,
  listOrderFail,
  getOrderDetailSuccess,
  getOrderDetailFail,
  getBeneficiaryDetailsSuccess,
  getBeneficiaryDetailsFail,
  addBeneficiaryDetailsSuccess,
  addBeneficiaryDetailsFail,
  updateBeneficiaryDetailsSuccess,
  updateBeneficiaryDetailsFail,
  addOrderBookingDetailsSuccess,
  addOrderBookingDetailsFail,
  confirmPendingOrderSuccess,
  confirmPendingOrderFail,
  confirmOrderPaymentSuccess,
  confirmOrderPaymentFail,
  uploadOrderDocumentsSuccess,
  uploadOrderDocumentsFail,
  uploadOrderTrackSuccess,
  uploadOrderTrackFail,
  confirmOrderDeliverySuccess,
  confirmOrderDeliveryFail,
  getOrderCountSuccess,
  getOrderCountFail,
  messageToSellerSuccess,
  messageToSellerFail,
  getAllCategoryListSuccess,
  getAllCategoryListFail,
  getCurrencyRateListSuccess,
  getCurrencyRateListFail
} from "../actions/userDataActions";

import * as Api from "../../api";

function* onLoginUser(data) {
  try {
    const response = yield call(Api.adminLogin, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(
        loginUserSuccess({ ...response, username: payload_data.username })
      );
    } else {
      yield put(loginUserFail(response));
    }
  } catch (error) {
    yield put(loginUserFail({ msg: "Failed" }));
  }
}

function* onListVendor(data) {
  try {
    const response = yield call(Api.listVendor, data.payload);
    if (response.status == "Success") {
      yield put(listVendorSuccess(response));
    } else {
      yield put(listVendorFail(response));
    }
  } catch (error) {
    yield put(listVendorFail("Failed"));
  }
}

function* onGetSellerDetail(data) {
  try {
    const response = yield call(Api.getSellerDetail, data.payload);
    if (response.status == "Success") {
      yield put(getSellerDetailSuccess(response));
    } else {
      yield put(getSellerDetailFail(response));
      window.location.href = "seller";
    }
  } catch (error) {
    yield put(getSellerDetailFail("Failed"));
    window.location.href = "seller";
  }
}

function* onUpdateSellerStatus(data) {
  try {
    const response = yield call(Api.updateSellerStatus, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerStatusSuccess({...response, isRemoveRow: data?.isRemoveRow}));
    } else {
      yield put(updateSellerStatusFail(response));
    }
  } catch (error) {
    yield put(updateSellerStatusFail("Failed"));
  }
}

function* onListProduct(data) {
  try {
    const response = yield call(Api.listProduct, data.payload);
    if (response.status == "Success") {
      yield put(listProductSuccess(response));
    } else {
      yield put(listProductFail(response));
    }
  } catch (error) {
    yield put(listProductFail("Failed"));
  }
}

function* onGetProductDetail(data) {
  try {
    const response = yield call(Api.getProductDetail, data.payload);
    if (response.status == "Success") {
      yield put(getProductDetailSuccess(response));
    } else {
      yield put(getProductDetailFail(response));
    }
  } catch (error) {
    yield put(getProductDetailFail("Failed"));
  }
}

function* onUpdateProductStatus(data) {
  try {
    const response = yield call(Api.updateProductStatus, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(updateProductStatusSuccess({ ...response, productStatus: payload_data.status }));
    } else {
      yield put(updateProductStatusFail(response));
    }
  } catch (error) {
    yield put(updateProductStatusFail("Failed"));
  }
}

function* onAddProductImage(data) {
  try {
    const response = yield call(Api.addProductImage, data.payload);
    if (response.status == "Success") {
      yield put(addProductImageSuccess(response));
    } else {
      yield put(addProductImageFail(response));
    }
  } catch (error) {
    yield put(addProductImageFail("Failed"));
  }
}

function* onDeleteProductImage(data) {
  try {
    const response = yield call(Api.deleteProductImage, data.payload);
    if (response.status == "Success") {
      yield put(deleteProductImageSuccess(response));
    } else {
      yield put(deleteProductImageFail(response));
    }
  } catch (error) {
    yield put(deleteProductImageFail("Failed"));
  }
}

function* onUpdateMainProductImage(data) {
  try {
    const response = yield call(Api.updateMainProductImage, data.payload);
    if (response.status == "Success") {
      yield put(updateMainProductImageSuccess(response));
    } else {
      yield put(updateMainProductImageFail(response));
    }
  } catch (error) {
    yield put(updateMainProductImageFail({msg: "Failed"}));
  }
}

function* onAddCategory(data) {
  try {
    const response = yield call(Api.addCategory, data.payload);
    if (response.status == "Success") {
      yield put(addCategorySuccess(response));
    } else {
      yield put(addCategoryFail(response));
    }
  } catch (error) {
    yield put(addCategoryFail({ msg: "Failed" }));
  }
}

function* onListCategory(data) {
  try {
    const response = yield call(Api.listCategory, data.payload);
    if (response.status == "Success") {
      yield put(listCategorySuccess(response));
    } else {
      yield put(listCategoryFail(response));
    }
  } catch (error) {
    yield put(listCategoryFail("Failed"));
  }
}

function* onDeleteCategory(data) {
  try {
    const response = yield call(Api.deleteCategory, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteCategorySuccess({ ...response, categoryUid: payload_data.categoryUid }));
    } else {
      yield put(deleteCategoryFail(response));
    }
  } catch (error) {
    yield put(deleteCategoryFail("Failed"));
  }
}

function* onUpdateCategoryFeatured(data) {
  try {
    const response = yield call(Api.updateCategoryFeatured, data.payload);
    if (response.status == "Success") {
      yield put(updateCategoryFeaturedSuccess(response));
    } else {
      yield put(updateCategoryFeaturedFail(response));
    }
  } catch (error) {
    yield put(updateCategoryFeaturedFail({ msg: "Failed" }));
  }
}

function* onAddSubCategory(data) {
  try {
    const response = yield call(Api.addSubCategory, data.payload);
    if (response.status == "Success") {
      yield put(addSubCategorySuccess(response));
    } else {
      yield put(addSubCategoryFail(response));
    }
  } catch (error) {
    yield put(addSubCategoryFail({ msg: "Failed" }));
  }
}

function* onListSubCategory(data) {
  try {
    const response = yield call(Api.listSubCategory, data.payload);
    if (response.status == "Success") {
      yield put(listSubCategorySuccess(response));
    } else {
      yield put(listSubCategoryFail(response));
    }
  } catch (error) {
    yield put(listSubCategoryFail("Failed"));
  }
}

function* onDeleteSubCategory(data) {
  try {
    const response = yield call(Api.deleteSubCategory, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteSubCategorySuccess({ ...response, subCategoryUid: payload_data.subCategoryUid }));
    } else {
      yield put(deleteSubCategoryFail(response));
    }
  } catch (error) {
    yield put(deleteSubCategoryFail("Failed"));
  }
}

function* onListProductCategory() {
  try {
    const response = yield call(Api.listProductCategory);
    if (response.status == "Success") {
      yield put(listProductCategorySuccess(response));
    } else {
      yield put(listProductCategoryFail(response));
    }
  } catch (error) {
    yield put(listProductCategoryFail("Failed"));
  }
}

function* onListCustomer(data) {
  try {
    const response = yield call(Api.listCustomer, data.payload);
    if (response.status == "Success") {
      yield put(listCustomerSuccess(response));
    } else {
      yield put(listCustomerFail(response));
    }
  } catch (error) {
    yield put(listCustomerFail("Failed"));
  }
}

function* onGetCustomerDetail(data) {
  try {
    const response = yield call(Api.getCustomerDetail, data.payload);
    if (response.status == "Success") {
      yield put(getCustomerDetailSuccess(response));
    } else {
      yield put(getCustomerDetailFail(response));
      window.location.href = "customers";
    }
  } catch (error) {
    yield put(getCustomerDetailFail("Failed"));
    window.location.href = "customers";
  }
}

function* onUpdateCustomerStatus(data) {
  try {
    const response = yield call(Api.updateCustomerStatus, data.payload);
    if (response.status == "Success") {
      yield put(updateCustomerStatusSuccess({...response, isRemoveRow: data?.isRemoveRow}));
    } else {
      yield put(updateCustomerStatusFail(response));
    }
  } catch (error) {
    yield put(updateCustomerStatusFail("Failed"));
  }
}

function* onGetDashboardData() {
  try {
    const response = yield call(Api.getDashboardData);
    if (response.status == "Success") {
      yield put(getDashboardSuccess(response));
    } else {
      yield put(getDashboardFail(response));
    }
  } catch (error) {
    yield put(getDashboardFail("Failed"));
  }
}

function* onListCartReviewPending(data) {
  try {
    const response = yield call(Api.listCart, data.payload);
    if (response.status == "Success") {
      yield put(listCartReviewPendingSuccess(response));
    } else {
      yield put(listCartReviewPendingFail(response));
    }
  } catch (error) {
    yield put(listCartReviewPendingFail("Failed"));
  }
}

function* onListCartShippingCostPending(data) {
  try {
    const response = yield call(Api.listCart, data.payload);
    if (response.status == "Success") {
      yield put(listCartShippingCostPendingSuccess(response));
    } else {
      yield put(listCartShippingCostPendingFail(response));
    }
  } catch (error) {
    yield put(listCartShippingCostPendingFail("Failed"));
  }
}

function* onListCartCheckoutPending(data) {
  try {
    const response = yield call(Api.listCart, data.payload);
    if (response.status == "Success") {
      yield put(listCartCheckoutPendingSuccess(response));
    } else {
      yield put(listCartCheckoutPendingFail(response));
    }
  } catch (error) {
    yield put(listCartCheckoutPendingFail("Failed"));
  }
}

function* onDeleteCart(data) {
  try {
    const response = yield call(Api.deleteCart, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteCartSuccess({...response, data: payload_data}));
    } else {
      yield put(deleteCartFail(response));
    }
  } catch (error) {
    yield put(deleteCartFail("Failed"));
  }
}

function* onGetCartDetail(data) {
  try {
    const response = yield call(Api.getCartDetail, data.payload);
    if (response.status == "Success") {
      yield put(getCartDetailSuccess(response));
    } else {
      yield put(getCartDetailFail(response));
    }
  } catch (error) {
    yield put(getCartDetailFail("Failed"));
  }
}

function* onAddCartShippingCost(data) {
  try {
    const response = yield call(Api.addCartShippingCost, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(addCartShippingCostSuccess({...response, data: payload_data}));
    } else {
      yield put(addCartShippingCostFail(response));
    }
  } catch (error) {
    yield put(addCartShippingCostFail("Failed"));
  }
}

function* onGetCartCount(data) {
  try {
    const response = yield call(Api.getCartCount, data.payload);
    if (response.status == "Success") {
      yield put(getCartCountSuccess(response));
    } else {
      yield put(getCartCountFail(response));
    }
  } catch (error) {
    yield put(getCartCountFail("Failed"));
  }
}

function* onListOrder(data) {
  try {
    const response = yield call(Api.listOrder, data.payload);
    if (response.status == "Success") {
      yield put(listOrderSuccess(response));
    } else {
      yield put(listOrderFail(response));
    }
  } catch (error) {
    yield put(listOrderFail("Failed"));
  }
}

function* onAddOrderBookingDetails(data) {
  try {
    const response = yield call(Api.addOrderBookingDetails, data.payload);
    if (response.status == "Success") {
      yield put(addOrderBookingDetailsSuccess(response));
    } else {
      yield put(addOrderBookingDetailsFail(response));
    }
  } catch (error) {
    yield put(addOrderBookingDetailsFail("Failed"));
  }
}

function* onGetOrderDetail(data) {
  try {
    const response = yield call(Api.getOrderDetail, data.payload);
    if (response.status == "Success") {
      yield put(getOrderDetailSuccess(response));
    } else {
      yield put(getOrderDetailFail(response));
    }
  } catch (error) {
    yield put(getOrderDetailFail("Failed"));
  }
}

function* onGetBeneficiaryDetails() {
  try {
    const response = yield call(Api.getBeneficiaryDetails);
    if (response.status == "Success") {
      yield put(getBeneficiaryDetailsSuccess(response));
    } else {
      yield put(getBeneficiaryDetailsFail(response));
    }
  } catch (error) {
    yield put(getBeneficiaryDetailsFail("Failed"));
  }
}

function* onAddBeneficiaryDetails(data) {
  try {
    const response = yield call(Api.addBeneficiaryDetails, data.payload);
    if (response.status == "Success") {
      yield put(addBeneficiaryDetailsSuccess(response));
    } else {
      yield put(addBeneficiaryDetailsFail(response));
    }
  } catch (error) {
    yield put(addBeneficiaryDetailsFail("Failed"));
  }
}

function* onUpdateBeneficiaryDetails(data) {
  try {
    const response = yield call(Api.updateBeneficiaryDetails, data.payload);
    if (response.status == "Success") {
      yield put(updateBeneficiaryDetailsSuccess(response));
    } else {
      yield put(updateBeneficiaryDetailsFail(response));
    }
  } catch (error) {
    yield put(updateBeneficiaryDetailsFail("Failed"));
  }
}

function* onConfirmPendingOrder(data) {
  try {
    const response = yield call(Api.confirmPendingOrder, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(confirmPendingOrderSuccess({...response, data: payload_data}));
    } else {
      yield put(confirmPendingOrderFail(response));
    }
  } catch (error) {
    yield put(confirmPendingOrderFail("Failed"));
  }
}

function* onConfirmOrderPayment(data) {
  try {
    const response = yield call(Api.confirmOrderPayment, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(confirmOrderPaymentSuccess({...response, data: payload_data}));
    } else {
      yield put(confirmOrderPaymentFail(response));
    }
  } catch (error) {
    yield put(confirmOrderPaymentFail("Failed"));
  }
}

function* onUploadOrderDocuments(data) {
  try {
    const response = yield call(Api.uploadOrderDocuments, data.payload);
    if (response.status == "Success") {
      yield put(uploadOrderDocumentsSuccess(response));
    } else {
      yield put(uploadOrderDocumentsFail(response));
    }
  } catch (error) {
    yield put(uploadOrderDocumentsFail("Failed"));
  }
}

function* onUploadOrderTrack(data) {
  try {
    const response = yield call(Api.uploadOrderTrack, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(uploadOrderTrackSuccess({...response, data: payload_data}));
    } else {
      yield put(uploadOrderTrackFail(response));
    }
  } catch (error) {
    yield put(uploadOrderTrackFail("Failed"));
  }
}

function* onConfirmOrderDelivery(data) {
  try {
    const response = yield call(Api.confirmOrderDelivery, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(confirmOrderDeliverySuccess({...response, data: payload_data}));
    } else {
      yield put(confirmOrderDeliveryFail(response));
    }
  } catch (error) {
    yield put(confirmOrderDeliveryFail("Failed"));
  }
}

function* onGetOrderCount() {
  try {
    const response = yield call(Api.getOrderCount);
    if (response.status == "Success") {
      yield put(getOrderCountSuccess(response));
    } else {
      yield put(getOrderCountFail(response));
    }
  } catch (error) {
    yield put(getOrderCountFail("Failed"));
  }
}

function* messageToSeller(data) {
  try {
    const response = yield call(Api.messageToSeller, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(messageToSellerSuccess({...response, data: payload_data}));
    } else {
      yield put(messageToSellerFail(response));
    }
  } catch (error) {
    yield put(messageToSellerFail("Failed"));
  }
}

function* allCategoryList() {
  try {
    const response = yield call(Api.allCategoryList);
    if (response.status == "Success") {
      yield put(getAllCategoryListSuccess(response));
    } else {
      yield put(getAllCategoryListFail(response));
    }
  } catch (error) {
    yield put(getAllCategoryListFail("Failed"));
  }
}

function* onGetCurrencyRateList() {
  try {
    const response = yield call(Api.getCurrencyRateList);
    if (response.status == "Success") {
      yield put(getCurrencyRateListSuccess(response));
    } else {
      yield put(getCurrencyRateListFail(response));
    }
  } catch (error) {
    yield put(getCurrencyRateListFail("Failed"));
  }
}

function* userDataSaga() {
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(LIST_VENDOR, onListVendor);
  yield takeLatest(GET_SELLER_DETAIL, onGetSellerDetail);
  yield takeLatest(UPDATE_SELLER_STATUS, onUpdateSellerStatus);
  yield takeLatest(LIST_PRODUCT, onListProduct);
  yield takeLatest(GET_PRODUCT_DETAIL, onGetProductDetail);
  yield takeLatest(UPDATE_PRODUCT_STATUS, onUpdateProductStatus);
  yield takeLatest(ADD_PRODUCT_IMAGE, onAddProductImage);
  yield takeLatest(DELETE_PRODUCT_IMAGE, onDeleteProductImage);
  yield takeLatest(UPDATE_MAIN_PRODUCT_IMAGE, onUpdateMainProductImage);
  yield takeLatest(ADD_CATEGORY, onAddCategory);
  yield takeLatest(LIST_CATEGORY, onListCategory);
  yield takeLatest(DELETE_CATEGORY, onDeleteCategory);
  yield takeLatest(UPDATE_CATEGORY_FEATURED, onUpdateCategoryFeatured);
  yield takeLatest(ADD_SUB_CATEGORY, onAddSubCategory);
  yield takeLatest(LIST_SUB_CATEGORY, onListSubCategory);
  yield takeLatest(DELETE_SUB_CATEGORY, onDeleteSubCategory);
  yield takeLatest(LIST_PRODUCT_CATEGORY, onListProductCategory);
  yield takeLatest(LIST_CUSTOMER, onListCustomer);
  yield takeLatest(GET_CUSTOMER_DETAIL, onGetCustomerDetail);
  yield takeLatest(UPDATE_CUSTOMER_STATUS, onUpdateCustomerStatus);
  yield takeLatest(GET_DASHBOARD, onGetDashboardData);
  yield takeLatest(LIST_CART_REVIEW_PENDING, onListCartReviewPending);
  yield takeLatest(LIST_CART_SHIPPING_COST_PENDING, onListCartShippingCostPending);
  yield takeLatest(LIST_CART_CHECKOUT_PENDING, onListCartCheckoutPending);
  yield takeLatest(DELETE_CART, onDeleteCart);
  yield takeLatest(GET_CART_DETAIL, onGetCartDetail);
  yield takeLatest(ADD_CART_SHIPPING_COST, onAddCartShippingCost);
  yield takeLatest(GET_CART_COUNT, onGetCartCount);
  yield takeLatest(LIST_ORDER, onListOrder);
  yield takeLatest(ADD_ORDER_BOOKING_DETAILS, onAddOrderBookingDetails);
  yield takeLatest(GET_ORDER_DETAIL, onGetOrderDetail);
  yield takeLatest(GET_BENEFICIARY_DETAILS, onGetBeneficiaryDetails);
  yield takeLatest(ADD_BENEFICIARY_DETAILS, onAddBeneficiaryDetails);
  yield takeLatest(UPDATE_BENEFICIARY_DETAILS, onUpdateBeneficiaryDetails);
  yield takeLatest(CONFIRM_PENDING_ORDER, onConfirmPendingOrder);
  yield takeLatest(CONFIRM_ORDER_PAYMENT, onConfirmOrderPayment);
  yield takeLatest(UPLOAD_ORDER_DOCUMENTS, onUploadOrderDocuments);
  yield takeLatest(UPLOAD_ORDER_TRACK, onUploadOrderTrack);
  yield takeLatest(CONFIRM_ORDER_DELIVERY, onConfirmOrderDelivery);
  yield takeLatest(GET_ORDER_COUNT, onGetOrderCount);
  yield takeLatest(MESSAGE_TO_SELLER, messageToSeller);
  yield takeLatest(GET_ALL_CATEGORY_LIST, allCategoryList);
  yield takeLatest(GET_CURRENCY_RATE_LIST, onGetCurrencyRateList);
}

export default userDataSaga;
