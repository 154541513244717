import React, {useState, useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormTextInput from "../../components/FormTextInput";
import FormSelectBox from "../../components/FormSelectBox";
import FormButton from "../../components/FormButton";
import ProductView from "../../components/ProductView";

import {connect} from "react-redux";
import {listProduct, getAllCategoryList, getCurrencyRateList} from "../../redux/actions/userDataActions";

import "../../assets/css/custom.css";
import styles from './Product.module.css'

const Product = (props) => {
  const [searchSku, setSearchSku] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [productStatus, setProductStatus] = useState('');
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let product_status = params.get('status');
    if (product_status) {
      setProductStatus(product_status)
    }
  }, [])

  useEffect(() => {
    props?.getCurrencyRateList()
  }, [])

  useEffect(() => {
    getProductList(1);
  }, [filterData, productStatus]);

  useEffect(() => {
    props.getAllCategoryList();
  }, []);

  useEffect(() => {
    if (props?.allCategoryList?.length > 0) {
      setCategoryList(props?.allCategoryList)
    }
  }, [props?.allCategoryList])

  const getProductList = (page) => {
    let data = {
      page: page,
      status: productStatus
    };
    if (filterData != null) {
      data = {
        page: page,
        searchSku: filterData?.searchSku ? filterData?.searchSku : '',
        category: (filterData?.category) ? filterData?.category : '',
        subCategory: (filterData?.category && filterData?.subCategory) ? filterData?.subCategory : '',
        status: productStatus
      };
    }

    props.listProduct(JSON.stringify(data));
    setPageNum(page);
  };

  const onChangeCategory = (val) => {
    setCategory(val);
    setSubCategory('');
    let list = [...categoryList];
    let listIndex = list.findIndex((x) => x.value == val);
    if (listIndex >= 0) {
      setSubCategoryList(list[listIndex]['subCategories']);
    }
  };

  const onSearchBtnClick = () => {
    let filter = {...filterData}
    filter['searchSku'] = searchSku;
    setFilterData(filter);

  };

  const onRefreshBtnClick = () => {
    let filter = {...filterData}
    filter['category'] = category;
    filter['subCategory'] = subCategory;
    setFilterData(filter);
  };

  return (
    <Container page={"product"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"center"}
      >
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography className={styles.manageInventoryText}>Products</Typography>
        </Grid>

        <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={2}>
          <Grid item md={7} xs={7}/>
          <Grid item md={1} xs={1}
                className={styles.skuBoxView}
                display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography className={styles.skuText}>SKU</Typography>
          </Grid>
          <Grid item md={3} xs={3} pl={1}>
            <FormTextInput
              page={'Product'}
              value={searchSku}
              setValue={setSearchSku}
              textInputViewStyle={style.textInputViewStyle}
            />
          </Grid>
          <Grid item md={1} xs={1} pl={1}>
            <FormButton
              btnType={'btn9'}
              width={'100%'}
              title={'Search'}
              onSubmit={onSearchBtnClick}
            />
          </Grid>
        </Grid>

        <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={4}>
          <Grid item md={1.5} xs={1.5} className={styles.productCountView} display={'flex'} flexDirection={'row'}
                alignItems={'center'} justifyContent={'center'} px={1}>
            <Typography
              className={styles.productCountText}>{props?.productTotalCount ? props?.productTotalCount : 0}</Typography>
            <Typography className={styles.productTitleText} pl={1}>
              {props?.productTotalCount > 1 ? 'Products' : 'Product'}
            </Typography>
          </Grid>
          <Grid item md={3.5} xs={3.5}/>
          <Grid item md={3} xs={3}>
            <FormSelectBox
              page={'Product'}
              value={category}
              setValue={(val) => onChangeCategory(val)}
              placeholderColor={'#000'}
              placeholderColorOpacity={'1'}
              textInputView={style.textInputViewStyle}
              data={categoryList}
              placeholder={'Category'}
            />
          </Grid>
          <Grid item md={3} xs={3} pl={1}>
            <FormSelectBox
              page={'Product'}
              value={subCategory}
              setValue={setSubCategory}
              placeholderColor={'#000'}
              placeholderColorOpacity={'1'}
              textInputView={style.textInputViewStyle}
              data={subCategoryList}
              placeholder={'Sub Category'}
            />
          </Grid>
          <Grid item md={1} xs={1} pl={1}>
            <FormButton
              btnType={'btn8'}
              width={'100%'}
              title={'Refresh'}
              onSubmit={onRefreshBtnClick}
            />
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          mt={3}
        >
          <TableContainer className={'tableContainer'}>
            <Table
              className={'table'}
              stickyHeader
              aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>Seller (TGSIN)</TableCell>
                  <TableCell>SKU (Product ID)</TableCell>
                  <TableCell>Product (HS Code)</TableCell>
                  <TableCell>Pickup Location</TableCell>
                  <TableCell>Packing</TableCell>
                  <TableCell>Price/Kg (Referral fee)</TableCell>
                  <TableCell>
                    EXW Price/Package (Referral fee/Package)
                  </TableCell>
                  <TableCell>Date Created (Status Changed Date)</TableCell>
                  <TableCell>Listing Status</TableCell>
                  {productStatus != 'removed' ?
                    <TableCell>Action</TableCell>
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>


                {props.productList != null
                  ? props.productList.map((item, index) => {
                    return (
                      <ProductView
                        key={index}
                        data={item}
                        status={item?.status}
                        currencyRateList={props?.currencyRateList}
                      />
                    );
                  })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
            {props.productTotalPages > 0 ? (
              <Page
                totalPages={props.productTotalPages}
                pageNum={pageNum}
                onSelectPage={getProductList}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>

    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    productTotalPages: state.userData.productTotalPages,
    productList: state.userData.productList,
    allCategoryList: state.userData.allCategoryList,
    productTotalCount: state.userData.productTotalCount,
    currencyRateList: state.userData.currencyRateList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listProduct: (data) => dispatch(listProduct(data)),
    getAllCategoryList: (data) => dispatch(getAllCategoryList(data)),
    getCurrencyRateList: (data) => dispatch(getCurrencyRateList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

const style = {
  textInputViewStyle: {
    marginTop: 0
  },
};
