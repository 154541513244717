import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';

import FormButton from "./FormButton";

import {getCurrencyConversionCalc} from "../assets/functions/common";

import styles from './CartCheckoutPendingList.module.css'

import {COUNTRIES} from "../constants/countries";

const CartCheckoutPendingList = (props) => {
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [actualPrice, setActualPrice] = useState(0);
  const [actualPricePerKg, setActualPricePerKg] = useState(0);
  const [actualTotalPrice, setActualTotalPrice] = useState(0);
  const [actualCargoPrice, setActualCargoPrice] = useState(0);
  const [actualShippingCost, setActualShippingCost] = useState(0);
  const [actualCurrencySymbol, setActualCurrencySymbol] = useState('')
  const [showError, setShowError] = useState('');

  useEffect(() => {
    if (props.data) {
      setShowError(props.data?.error);
    }
  }, [props.data?.error])

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let currency_symbol = '';
        let price_val = 0;
        let price_per_kg = 0;
        let total_price = 0;
        let cargo_price = 0;
        let shipping_cost = 0;
        let actual_currency_symbol = '';
        let actual_price_val = 0;
        let actual_price_per_kg = 0;
        let actual_total_price = 0;
        let actual_cargo_price = 0;
        let actual_shipping_cost = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props.data?.shipping_details?.shipping_cost,
          currency: props?.data?.seller_currency,
          admin_currency: props?.data?.admin_currency,
          admin_currency2: props?.data?.admin_currency2,
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          actual_price_val = data?.price;
          actual_price_per_kg = data?.price_per_kg;
          actual_total_price = data?.total_price;
          actual_cargo_price = data?.cargo_price;
          actual_shipping_cost = data?.shipping_cost;
          actual_currency_symbol = data?.currency_symbol;
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          cargo_price = data?.converted_cargo_price;
          shipping_cost = data?.converted_shipping_cost;
          currency_symbol = data?.admin_currency_symbol;
        }

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setCargoPrice(cargo_price)
        setShippingCost(shipping_cost)
        setCurrencySymbol(currency_symbol)

        setActualPrice(actual_price_val);
        setActualTotalPrice(actual_total_price)
        setActualPricePerKg(actual_price_per_kg);
        setActualCargoPrice(actual_cargo_price)
        setActualShippingCost(actual_shipping_cost)
        setActualCurrencySymbol(actual_currency_symbol)

        // let price_val = 0;
        // let total_price = 0;
        // let price_per_kg = 0;
        // let shipping_cost = 0;
        // let cargo_price = 0;
        // if (props?.data?.price_per_package != '' && props?.data?.package_net_weight != '') {
        //   price_val = (parseFloat((((props?.data?.price_per_package / props?.data?.package_net_weight) * props?.data?.referral_fee) / 100) + (props?.data?.price_per_package / props?.data?.package_net_weight)) * props?.data?.package_net_weight)?.toFixed(2)
        //   price_per_kg = price_val / props?.data?.package_net_weight;
        //   total_price = props.data?.quantity * price_val
        //   shipping_cost = props.data?.shipping_details?.shipping_cost;
        //   cargo_price = (parseFloat(total_price) + parseFloat(shipping_cost))?.toFixed(2);
        //
        //   setPrice(price_val);
        //   setTotalPrice(total_price);
        //   setCargoPrice(cargo_price);
        //   setPricePerKg(price_per_kg);
        // }
      }
    }
  }, [props.data, props?.currencyRateList])

  const onDeleteCartClick = (cart_uid) => {
    props.onDeleteCart(cart_uid)
  };

  let item = props.data;

  return (
    <>
      <TableCell>
        <Typography className={styles.productNameText}>#{item.cart_uid}</Typography>
        {showError ?
          <Box mt={1}>
            <Typography className={styles.moqErrorText}>{showError}</Typography>
          </Box>
          : null}
      </TableCell>
      <TableCell>
        <Typography className={styles.productNameText}>#{item?.shipping_details?.cargo_id}</Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        {item.product_image ?
          <Grid item md={4} xs={12} className={styles.imgView}>
            <img src={item.product_image} className={styles.imgStyle}/>
          </Grid>
          : null}
        <Typography className={styles.productNameTitleText}>
          {(item?.variety ? item.variety : 'NIL Variety')
          + ', ' + (item?.name ? item.name : 'NIL')
          + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
          + ', ' + (item?.package_net_weight ?
            (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
            : 'NIL Packing')
          + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
        </Typography>
        <Typography className={styles.subPricePerKgText} mt={1}>( {currencySymbol}{pricePerKg?.toFixed(2)} / Kg)
          ( {currencySymbol}{price} / Package)</Typography>
        <Typography className={styles.hsCodeText} mt={1}>HS Code: {item?.hs_code ? item.hs_code : ''}</Typography>
        <Typography className={styles.productNameText}>Seller: {item?.seller_name_entity ? item?.seller_name_entity : ''}</Typography>
        <Typography
          className={styles.productNameText}>{item?.seller_country ? '(' + COUNTRIES.filter(
          (x) => x.code == item?.seller_country
        )?.[0]?.label + ')' : ''}</Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.deliveryAddressText}>
          {item?.user_name_entity ?
            item?.user_name_entity + ', ' + (COUNTRIES.filter(
            (x) => x.code == item?.user_country
            )?.[0]?.label)
            : ''}
        </Typography>
        <Typography className={styles.deliveryAddressText}>
          {item?.delivery_address?.state_province ?
            (item?.delivery_address?.address_line_1
              + ',' + item?.delivery_address?.address_line_2
              + ',' + item?.delivery_address?.city
              + ',' + item?.delivery_address?.state_province
              + ',' + item?.delivery_address?.zip_code
            )
            : '-'}
        </Typography>
      </TableCell>
      <TableCell className={styles.dataView}>
        <Typography className={styles.shippingDetailText}>
          Total number of packages : {item?.quantity ? item?.quantity : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Total product quantity
          : {item?.package_net_weight ? (item?.quantity * item?.package_net_weight + (item?.unit ? item.unit : '')) : 0}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping method : {item?.shipping_details?.shipping_mode ? item?.shipping_details?.shipping_mode : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Container type : {item?.shipping_details?.container_type ? item?.shipping_details?.container_type : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Cargo ID : {item?.shipping_details?.cargo_id ? item?.shipping_details?.cargo_id : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Total Cargo
          Weight: {item?.package_gross_weight ? (item?.package_gross_weight * item?.quantity + ' ' + item?.unit) : '-'}
        </Typography>
        <Typography className={styles.shippingDetailText} mt={0.7}>
          Shipping date
          : {item?.shipping_details?.shipping_date ? moment(item?.shipping_details?.shipping_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
        </Typography>
        {item?.shipping_details?.delivery_date ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Delivery date
            : {item?.shipping_details?.delivery_date ? moment(item?.shipping_details?.delivery_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.carrier ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Carrier: {item?.shipping_details?.carrier ? item?.shipping_details?.carrier : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.loading_port ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Port of loading: {item?.shipping_details?.loading_port ? item?.shipping_details?.loading_port : '-'}
          </Typography>
          : null}
        {item?.shipping_details?.discharge_port ?
          <Typography className={styles.shippingDetailText} mt={0.7}>
            Port of discharge: {item?.shipping_details?.discharge_port ? item?.shipping_details?.discharge_port : '-'}
          </Typography>
          : null}
      </TableCell>
      <TableCell>
        {item?.shipping_details?.payment_term ?
          <>
            <Typography
              className={styles.paymentTermText}>{item?.shipping_details?.payment_term}</Typography>
            <Typography
              className={styles.paymentTermDescText}>({item?.shipping_details?.payment_term_description})</Typography>
          </>
          : '-'}
      </TableCell>
      <TableCell>
        <Typography
          className={styles.subPriceText}>
          {actualCurrencySymbol}{actualTotalPrice?.toFixed(2)}
        </Typography>
        <Typography className={styles.subPricePerKgText}>{actualCurrencySymbol}{actualPricePerKg?.toFixed(2)} /
          kg</Typography>

        <Box pl={3}>
          <Typography
            className={styles.subPriceText}>
            ({currencySymbol}{totalPrice?.toFixed(2)})
          </Typography>
          <Typography className={styles.subPricePerKgText}>({currencySymbol}{pricePerKg?.toFixed(2)} / kg)</Typography>
        </Box>

      </TableCell>
      <TableCell>
        <Typography className={styles.calculationPendingText}>
          {actualCurrencySymbol} {actualShippingCost ? actualShippingCost?.toFixed(2) : '0'}
        </Typography>
        <Box pl={3}>
          <Typography className={styles.calculationPendingText}>
            ({currencySymbol} {shippingCost ? shippingCost?.toFixed(2) : '0'})
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography className={styles.calculationPendingText}>
          {actualCurrencySymbol} {actualCargoPrice ? actualCargoPrice?.toFixed(2) : '0'}
        </Typography>
        <Box pl={3}>
          <Typography className={styles.calculationPendingText}>
            ({currencySymbol} {cargoPrice ? cargoPrice?.toFixed(2) : '-'})
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography className={styles.calculationPendingText}>
          {actualCurrencySymbol} {item?.shipping_details?.payment_term_amount == 50 ?
          (actualCargoPrice * (50 / 100))?.toFixed(2)
          : actualCargoPrice}
        </Typography>
        <Box pl={3}>
          <Typography className={styles.calculationPendingText}>
            ({currencySymbol} {item?.shipping_details?.payment_term_amount == 50 ?
            (cargoPrice * (50 / 100))?.toFixed(2)
            : cargoPrice})
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.pointerView}>
          <FormButton
            title={'Delete'}
            btnType={'btn7'}
            width={'100%'}
            onSubmit={() => onDeleteCartClick(item.cart_uid)}
          />
        </Box>
      </TableCell>

    </>
  )
};

export default CartCheckoutPendingList;
