import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

import styles from './ProductShippingDetailView.module.css'

const ProductShippingDetailView = (props) => {

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>MOQ</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Processing Time</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Supply ability</Typography>
                <Typography className={styles.subTitleText} ml={1}>/Week</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Type of product</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Required Temperature</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Stuffing</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.moq ? item?.moq + 'Kg' : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.processingTime ? item?.processingTime + ' Days' : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.supplyAbilityPerWeek ? item?.supplyAbilityPerWeek + 'Kg' : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productType ? item?.productType : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.requiredTemperature ? (item?.requiredTemperature == 0 ? 'Not Required' : (item?.requiredTemperature + '°C')) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                {item?.productType ? item?.productType : '-'}
                <Typography
                  className={styles.valueText}>
                  {item?.stuffing ? item?.stuffing : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default ProductShippingDetailView;
