import React, {useEffect, useState} from 'react'
import Grid from '@mui/material/Grid';

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import FormTextArea from "../../components/FormTextArea";

import {connect} from "react-redux";
import {getBeneficiaryDetails, addBeneficiaryDetails, updateBeneficiaryDetails, updateApiStatus} from "../../redux/actions/userDataActions";

import styles from './BeneficiaryDetails.module.css'

const BeneficiaryDetails = (props) => {
  const [beneficiaryDetailUid, setBeneficiaryDetailUid] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    props.getBeneficiaryDetails();
  },[])

  useEffect(() => {
    if(props?.beneficiaryDetails != null) {
      let data = props?.beneficiaryDetails;
      setBeneficiaryDetailUid(data?.beneficiaryDetailUid);
      setAccountName(data?.accountName);
      setAccountNumber(data?.accountNumber);
      setBankName(data?.bankName);
      setSwiftCode(data?.swiftCode);
      setBankAddress(data?.bankAddress);
    }
  },[props?.beneficiaryDetails])

  useEffect(() => {
    if((props?.apiStatus == 'ADD_BENEFICIARY_DETAILS_SUCCESS') || (props?.apiStatus == 'UPDATE_BENEFICIARY_DETAILS_SUCCESS')) {
      setError(null)
      props.updateApiStatus('')
    }
  },[props?.apiStatus])

  const onUploadClick = () => {
    let errorText = {};
    if (accountName == '') {
      errorText = {...errorText, accountName: 'Account Name is required'};
    }
    if (accountNumber == '') {
      errorText = {...errorText, accountNumber: 'Account Number is required'};
    }
    if (bankName == '') {
      errorText = {...errorText, bankName: 'Bank Name is required'};
    }
    if (swiftCode == '') {
      errorText = {...errorText, swiftCode: 'Swift Code is required'};
    }
    if (bankAddress == '') {
      errorText = {...errorText, bankAddress: 'Bank Address is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    let data = {
      beneficiaryDetailUid: beneficiaryDetailUid,
      accountName: accountName,
      accountNumber: accountNumber,
      bankName: bankName,
      swiftCode: swiftCode,
      bankAddress: bankAddress,
    };
    if(beneficiaryDetailUid) {
      props.updateBeneficiaryDetails(JSON.stringify(data));
    } else {
      props.addBeneficiaryDetails(JSON.stringify(data));
    }
  };

  return (
    <Container page={"beneficiary_details"}>
      <Grid item md={12} xs={12}
            container
            display={"flex"}
            alignItems={"center"}>
        <Grid item md={12} xs={12}>
          <h2>Beneficiary Details</h2>
        </Grid>
        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={'space-between'}
              mt={3}
              className={styles.cardDiv}
        p={2}>

          <Grid container item md={5.8} xs={12}
                sx={style.textInputView} mt={2}>
            <FormTextInput
              page={'BeneficiaryDetails'}
              error={error?.accountName}
              value={accountName}
              setValue={setAccountName}
              label={'Account Name'}
              textInputViewStyle={style.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid container item md={5.8} xs={12}
                sx={style.textInputView} mt={2}>
            <FormTextInput
              page={'BeneficiaryDetails'}
              error={error?.accountNumber}
              value={accountNumber}
              setValue={setAccountNumber}
              label={'Account Number'}
              textInputViewStyle={style.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid container item md={5.8} xs={12}
                sx={style.textInputView} mt={2}>
            <FormTextInput
              page={'BeneficiaryDetails'}
              error={error?.bankName}
              value={bankName}
              setValue={setBankName}
              label={'Bank Name'}
              textInputViewStyle={style.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid container item md={5.8} xs={12}
                sx={style.textInputView} mt={2}>
            <FormTextInput
              page={'BeneficiaryDetails'}
              error={error?.swiftCode}
              value={swiftCode}
              setValue={setSwiftCode}
              label={'Swift Code'}
              textInputViewStyle={style.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid item md={5.8} xs={12} mt={3}
                container>
            <FormTextArea
              page={'BeneficiaryDetails'}
              error={error?.bankAddress}
              value={bankAddress}
              setValue={setBankAddress}
              label={'Bank Address'}
              textInputViewStyle={style.textInputViewStyle}
              required={true}
            />
          </Grid>

        </Grid>

        <Grid item md={12} xs={12} mt={3}
              container
              display={'flex'} justifyContent={'flex-end'}>
          <FormButton
            title={"Upload"}
            loading={props?.btnLoading}
            btnStyleView={{height: 35}}
            onSubmit={onUploadClick}
          />
        </Grid>
      </Grid>

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    beneficiaryDetails: state.userData.beneficiaryDetails,
    btnLoading: state.userData.btnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBeneficiaryDetails: data => dispatch(addBeneficiaryDetails(data)),
    updateBeneficiaryDetails: data => dispatch(updateBeneficiaryDetails(data)),
    getBeneficiaryDetails: data => dispatch(getBeneficiaryDetails(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryDetails);


const style = {
  textInputView: {
    width: '80%'
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
};
