import React, {useEffect} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {Card, CardContent} from "@mui/material";
import ImageDownload from "./ImageDownload";

const SubTitleView = ({title, subtitle}) => {
  return (
    <Grid container item mt={2} display={"flex"} flexDirection={"row"} alignItems={"center"} md={12} xs={12}>
      <Grid container item md={5} xs={12}>
        <Typography color="text.secondary" style={styles.titleText}>
          {title}
        </Typography>
      </Grid>
      <Grid container item md={7} xs={12}>
        <Typography color="text.secondary" style={styles.subTitleText}>
          {subtitle ? subtitle : '-'}
        </Typography>
      </Grid>
    </Grid>
  )
};

const CustomerImporterDetails = (props) => {

  const onDownloadClick = async (imgUrl, name) => {
    const image = await fetch(imgUrl);
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL;
    link.download = "" + name + "";
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  };

  return (
    <Grid item md={12} xs={12}>
      <Box>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
            <Typography style={styles.mainTitle}>
              Import License Details
            </Typography>
            <SubTitleView title={'Importer Name'} subtitle={props.importerDetail?.importLicenseDetails?.importerName}/>
            <SubTitleView title={'Import License No'}
                          subtitle={props.importerDetail?.importLicenseDetails?.importLicenseNo}/>
          {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

      <Box mt={5}>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
            <Typography style={styles.mainTitle}>
              Documents
            </Typography>
            <Grid container item md={12} xs={12}>

              {props.importerDetail?.documentDetails?.displaySignatureImage ?
                <Grid item md={4} xs={12} mt={3}>
                  <Box>
                    <Typography style={styles.titleText} color="text.secondary" >
                      Signature of the authorized Person
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <ImageDownload
                      maxFiles={1}
                      fileName={props.importerDetail?.documentDetails?.signatureImage}
                      image={props.importerDetail?.documentDetails?.displaySignatureImage}
                      fileType={'image'}
                      onDownload={onDownloadClick} />
                  </Box>
                </Grid>
                : null}

              {props.importerDetail?.documentDetails?.displayBusinessLogoImage ?
                <Grid item md={4} xs={12} mt={3}>
                  <Box>
                    <Typography style={styles.titleText} color="text.secondary" >
                      Business Logo
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <ImageDownload
                      maxFiles={1}
                      fileName={props.importerDetail?.documentDetails?.businessLogoImage}
                      image={props.importerDetail?.documentDetails?.displayBusinessLogoImage}
                      fileType={'image'}
                      onDownload={onDownloadClick} />
                  </Box>
                </Grid>
                : null}

            </Grid>
          {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>


    </Grid>
  )
}

export default CustomerImporterDetails

const styles = {
  titleText: {
    fontFamily: 'InterBold',
    fontSize: 15,
  },
  subTitleText: {
    fontFamily: 'InterBold',
    fontSize: 13,
  },
  mainTitle: {
    fontFamily: 'InterBold',
    fontSize: 17,
  },
};
