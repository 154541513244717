import * as actionTypes from './types';

//login
export const loginUser = data => ({
  type: actionTypes.LOGIN_USER,
  payload: data,
});

export const loginUserSuccess = data => ({
  type: actionTypes.LOGIN_USER_SUCCESS,
  payload: data,
});

export const loginUserFail = data => ({
  type: actionTypes.LOGIN_USER_FAIL,
  payload: data,
});

export const listVendor = data => ({
  type: actionTypes.LIST_VENDOR,
  payload: data,
});

export const listVendorSuccess = data => ({
  type: actionTypes.LIST_VENDOR_SUCCESS,
  payload: data,
});

export const listVendorFail = data => ({
  type: actionTypes.LIST_VENDOR_FAIL,
  payload: data,
});

export const getSellerDetail = data => ({
  type: actionTypes.GET_SELLER_DETAIL,
  payload: data,
});

export const getSellerDetailSuccess = data => ({
  type: actionTypes.GET_SELLER_DETAIL_SUCCESS,
  payload: data,
});

export const getSellerDetailFail = data => ({
  type: actionTypes.GET_SELLER_DETAIL_FAIL,
  payload: data,
});

export const updateSellerStatus = (data, isRemoveRow) => ({
  type: actionTypes.UPDATE_SELLER_STATUS,
  payload: data,
  isRemoveRow: isRemoveRow,
});

export const updateSellerStatusSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_STATUS_SUCCESS,
  payload: data,
});

export const updateSellerStatusFail = data => ({
  type: actionTypes.UPDATE_SELLER_STATUS_FAIL,
  payload: data,
});

//product
export const listProductCategory = () => ({
  type: actionTypes.LIST_PRODUCT_CATEGORY,
});

export const listProductCategorySuccess = data => ({
  type: actionTypes.LIST_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const listProductCategoryFail = data => ({
  type: actionTypes.LIST_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

export const listProduct = data => ({
  type: actionTypes.LIST_PRODUCT,
  payload: data,
});

export const listProductSuccess = data => ({
  type: actionTypes.LIST_PRODUCT_SUCCESS,
  payload: data,
});

export const listProductFail = data => ({
  type: actionTypes.LIST_PRODUCT_FAIL,
  payload: data,
});

export const getProductDetail = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL,
  payload: data,
});

export const getProductDetailSuccess = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL_SUCCESS,
  payload: data,
});

export const getProductDetailFail = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL_FAIL,
  payload: data,
});

export const updateProductStatus = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS,
  payload: data,
});

export const updateProductStatusSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS_SUCCESS,
  payload: data,
});

export const updateProductStatusFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS_FAIL,
  payload: data,
});

export const addProductImage = data => ({
  type: actionTypes.ADD_PRODUCT_IMAGE,
  payload: data,
});

export const addProductImageSuccess = data => ({
  type: actionTypes.ADD_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const addProductImageFail = data => ({
  type: actionTypes.ADD_PRODUCT_IMAGE_FAIL,
  payload: data,
});

export const deleteProductImage = data => ({
  type: actionTypes.DELETE_PRODUCT_IMAGE,
  payload: data,
});

export const deleteProductImageSuccess = data => ({
  type: actionTypes.DELETE_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const deleteProductImageFail = data => ({
  type: actionTypes.DELETE_PRODUCT_IMAGE_FAIL,
  payload: data,
});

export const updateMainProductImage = data => ({
  type: actionTypes.UPDATE_MAIN_PRODUCT_IMAGE,
  payload: data,
});

export const updateMainProductImageSuccess = data => ({
  type: actionTypes.UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const updateMainProductImageFail = data => ({
  type: actionTypes.UPDATE_MAIN_PRODUCT_IMAGE_FAIL,
  payload: data,
});

export const getAllCategoryList = () => ({
  type: actionTypes.GET_ALL_CATEGORY_LIST,
});

export const getAllCategoryListSuccess = data => ({
  type: actionTypes.GET_ALL_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const getAllCategoryListFail = data => ({
  type: actionTypes.GET_ALL_CATEGORY_LIST_FAIL,
  payload: data,
});

//category
export const addCategory = data => ({
  type: actionTypes.ADD_CATEGORY,
  payload: data,
});

export const addCategorySuccess = data => ({
  type: actionTypes.ADD_CATEGORY_SUCCESS,
  payload: data,
});

export const addCategoryFail = data => ({
  type: actionTypes.ADD_CATEGORY_FAIL,
  payload: data,
});

export const listCategory = data => ({
  type: actionTypes.LIST_CATEGORY,
  payload: data,
});

export const listCategorySuccess = data => ({
  type: actionTypes.LIST_CATEGORY_SUCCESS,
  payload: data,
});

export const listCategoryFail = data => ({
  type: actionTypes.LIST_CATEGORY_FAIL,
  payload: data,
});

export const updateCategoryFeatured = data => ({
  type: actionTypes.UPDATE_CATEGORY_FEATURED,
  payload: data,
});

export const updateCategoryFeaturedSuccess = data => ({
  type: actionTypes.UPDATE_CATEGORY_FEATURED_SUCCESS,
  payload: data,
});

export const updateCategoryFeaturedFail = data => ({
  type: actionTypes.UPDATE_CATEGORY_FEATURED_FAIL,
  payload: data,
});

export const deleteCategory = data => ({
  type: actionTypes.DELETE_CATEGORY,
  payload: data,
});

export const deleteCategorySuccess = data => ({
  type: actionTypes.DELETE_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteCategoryFail = data => ({
  type: actionTypes.DELETE_CATEGORY_FAIL,
  payload: data,
});

//sub category
export const addSubCategory = data => ({
  type: actionTypes.ADD_SUB_CATEGORY,
  payload: data,
});

export const addSubCategorySuccess = data => ({
  type: actionTypes.ADD_SUB_CATEGORY_SUCCESS,
  payload: data,
});

export const addSubCategoryFail = data => ({
  type: actionTypes.ADD_SUB_CATEGORY_FAIL,
  payload: data,
});

export const listSubCategory = data => ({
  type: actionTypes.LIST_SUB_CATEGORY,
  payload: data,
});

export const listSubCategorySuccess = data => ({
  type: actionTypes.LIST_SUB_CATEGORY_SUCCESS,
  payload: data,
});

export const listSubCategoryFail = data => ({
  type: actionTypes.LIST_SUB_CATEGORY_FAIL,
  payload: data,
});

export const deleteSubCategory = data => ({
  type: actionTypes.DELETE_SUB_CATEGORY,
  payload: data,
});

export const deleteSubCategorySuccess = data => ({
  type: actionTypes.DELETE_SUB_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteSubCategoryFail = data => ({
  type: actionTypes.DELETE_SUB_CATEGORY_FAIL,
  payload: data,
});

//customer
export const listCustomer = data => ({
  type: actionTypes.LIST_CUSTOMER,
  payload: data,
});

export const listCustomerSuccess = data => ({
  type: actionTypes.LIST_CUSTOMER_SUCCESS,
  payload: data,
});

export const listCustomerFail = data => ({
  type: actionTypes.LIST_CUSTOMER_FAIL,
  payload: data,
});

export const getCustomerDetail = data => ({
  type: actionTypes.GET_CUSTOMER_DETAIL,
  payload: data,
});

export const getCustomerDetailSuccess = data => ({
  type: actionTypes.GET_CUSTOMER_DETAIL_SUCCESS,
  payload: data,
});

export const getCustomerDetailFail = data => ({
  type: actionTypes.GET_CUSTOMER_DETAIL_FAIL,
  payload: data,
});

export const updateCustomerStatus = (data, isRemoveRow) => ({
  type: actionTypes.UPDATE_CUSTOMER_STATUS,
  payload: data,
  isRemoveRow: isRemoveRow,
});


export const updateCustomerStatusSuccess = data => ({
  type: actionTypes.UPDATE_CUSTOMER_STATUS_SUCCESS,
  payload: data,
});

export const updateCustomerStatusFail = data => ({
  type: actionTypes.UPDATE_CUSTOMER_STATUS_FAIL,
  payload: data,
});

//dashboard
export const getDashboard = () => ({
  type: actionTypes.GET_DASHBOARD,
});

export const getDashboardSuccess = data => ({
  type: actionTypes.GET_DASHBOARD_SUCCESS,
  payload: data,
});

export const getDashboardFail = data => ({
  type: actionTypes.GET_DASHBOARD_FAIL,
  payload: data,
});

//cart
export const listCartReviewPending = data => ({
  type: actionTypes.LIST_CART_REVIEW_PENDING,
  payload: data,
});

export const listCartReviewPendingSuccess = data => ({
  type: actionTypes.LIST_CART_REVIEW_PENDING_SUCCESS,
  payload: data,
});

export const listCartReviewPendingFail = data => ({
  type: actionTypes.LIST_CART_REVIEW_PENDING_FAIL,
  payload: data,
});

export const listCartShippingCostPending = data => ({
  type: actionTypes.LIST_CART_SHIPPING_COST_PENDING,
  payload: data,
});

export const listCartShippingCostPendingSuccess = data => ({
  type: actionTypes.LIST_CART_SHIPPING_COST_PENDING_SUCCESS,
  payload: data,
});

export const listCartShippingCostPendingFail = data => ({
  type: actionTypes.LIST_CART_SHIPPING_COST_PENDING_FAIL,
  payload: data,
});

export const listCartCheckoutPending = data => ({
  type: actionTypes.LIST_CART_CHECKOUT_PENDING,
  payload: data,
});

export const listCartCheckoutPendingSuccess = data => ({
  type: actionTypes.LIST_CART_CHECKOUT_PENDING_SUCCESS,
  payload: data,
});

export const listCartCheckoutPendingFail = data => ({
  type: actionTypes.LIST_CART_CHECKOUT_PENDING_FAIL,
  payload: data,
});

export const deleteCart = data => ({
  type: actionTypes.DELETE_CART,
  payload: data,
});

export const deleteCartSuccess = data => ({
  type: actionTypes.DELETE_CART_SUCCESS,
  payload: data,
});

export const deleteCartFail = data => ({
  type: actionTypes.DELETE_CART_FAIL,
  payload: data,
});

export const getCartDetail = data => ({
  type: actionTypes.GET_CART_DETAIL,
  payload: data,
});

export const getCartDetailSuccess = data => ({
  type: actionTypes.GET_CART_DETAIL_SUCCESS,
  payload: data,
});

export const getCartDetailFail = data => ({
  type: actionTypes.GET_CART_DETAIL_FAIL,
  payload: data,
});

export const addCartShippingCost = data => ({
  type: actionTypes.ADD_CART_SHIPPING_COST,
  payload: data,
});

export const addCartShippingCostSuccess = data => ({
  type: actionTypes.ADD_CART_SHIPPING_COST_SUCCESS,
  payload: data,
});

export const addCartShippingCostFail = data => ({
  type: actionTypes.ADD_CART_SHIPPING_COST_FAIL,
  payload: data,
});

export const getCartCount = () => ({
  type: actionTypes.GET_CART_COUNT,
});

export const getCartCountSuccess = data => ({
  type: actionTypes.GET_CART_COUNT_SUCCESS,
  payload: data,
});

export const getCartCountFail = data => ({
  type: actionTypes.GET_CART_COUNT_FAIL,
  payload: data,
});

export const listOrder = data => ({
  type: actionTypes.LIST_ORDER,
  payload: data,
});

export const listOrderSuccess = data => ({
  type: actionTypes.LIST_ORDER_SUCCESS,
  payload: data,
});

export const listOrderFail = data => ({
  type: actionTypes.LIST_ORDER_FAIL,
  payload: data,
});

export const addOrderBookingDetails = data => ({
  type: actionTypes.ADD_ORDER_BOOKING_DETAILS,
  payload: data,
});

export const addOrderBookingDetailsSuccess = data => ({
  type: actionTypes.ADD_ORDER_BOOKING_DETAILS_SUCCESS,
  payload: data,
});

export const addOrderBookingDetailsFail = data => ({
  type: actionTypes.ADD_ORDER_BOOKING_DETAILS_FAIL,
  payload: data,
});

export const getOrderDetail = data => ({
  type: actionTypes.GET_ORDER_DETAIL,
  payload: data,
});

export const getOrderDetailSuccess = data => ({
  type: actionTypes.GET_ORDER_DETAIL_SUCCESS,
  payload: data,
});

export const getOrderDetailFail = data => ({
  type: actionTypes.GET_ORDER_DETAIL_FAIL,
  payload: data,
});

export const confirmPendingOrder = data => ({
  type: actionTypes.CONFIRM_PENDING_ORDER,
  payload: data,
});

export const confirmPendingOrderSuccess = data => ({
  type: actionTypes.CONFIRM_PENDING_ORDER_SUCCESS,
  payload: data,
});

export const confirmPendingOrderFail = data => ({
  type: actionTypes.CONFIRM_PENDING_ORDER_FAIL,
  payload: data,
});

export const uploadOrderDocuments = data => ({
  type: actionTypes.UPLOAD_ORDER_DOCUMENTS,
  payload: data,
});

export const uploadOrderDocumentsSuccess = data => ({
  type: actionTypes.UPLOAD_ORDER_DOCUMENTS_SUCCESS,
  payload: data,
});

export const uploadOrderDocumentsFail = data => ({
  type: actionTypes.UPLOAD_ORDER_DOCUMENTS_FAIL,
  payload: data,
});

export const confirmOrderPayment = data => ({
  type: actionTypes.CONFIRM_ORDER_PAYMENT,
  payload: data,
});

export const confirmOrderPaymentSuccess = data => ({
  type: actionTypes.CONFIRM_ORDER_PAYMENT_SUCCESS,
  payload: data,
});

export const confirmOrderPaymentFail = data => ({
  type: actionTypes.CONFIRM_ORDER_PAYMENT_FAIL,
  payload: data,
});

export const uploadOrderTrack = data => ({
  type: actionTypes.UPLOAD_ORDER_TRACK,
  payload: data,
});

export const uploadOrderTrackSuccess = data => ({
  type: actionTypes.UPLOAD_ORDER_TRACK_SUCCESS,
  payload: data,
});

export const uploadOrderTrackFail = data => ({
  type: actionTypes.UPLOAD_ORDER_TRACK_FAIL,
  payload: data,
});

export const confirmOrderDelivery = data => ({
  type: actionTypes.CONFIRM_ORDER_DELIVERY,
  payload: data,
});

export const confirmOrderDeliverySuccess = data => ({
  type: actionTypes.CONFIRM_ORDER_DELIVERY_SUCCESS,
  payload: data,
});

export const confirmOrderDeliveryFail = data => ({
  type: actionTypes.CONFIRM_ORDER_DELIVERY_FAIL,
  payload: data,
});

export const getOrderCount = () => ({
  type: actionTypes.GET_ORDER_COUNT,
});

export const getOrderCountSuccess = data => ({
  type: actionTypes.GET_ORDER_COUNT_SUCCESS,
  payload: data,
});

export const getOrderCountFail = data => ({
  type: actionTypes.GET_ORDER_COUNT_FAIL,
  payload: data,
});

export const messageToSeller = data => ({
  type: actionTypes.MESSAGE_TO_SELLER,
  payload: data,
});

export const messageToSellerSuccess = data => ({
  type: actionTypes.MESSAGE_TO_SELLER_SUCCESS,
  payload: data,
});

export const messageToSellerFail = data => ({
  type: actionTypes.MESSAGE_TO_SELLER_FAIL,
  payload: data,
});

//beneficiary details
export const getBeneficiaryDetails = () => ({
  type: actionTypes.GET_BENEFICIARY_DETAILS,
});

export const getBeneficiaryDetailsSuccess = data => ({
  type: actionTypes.GET_BENEFICIARY_DETAILS_SUCCESS,
  payload: data,
});

export const getBeneficiaryDetailsFail = data => ({
  type: actionTypes.GET_BENEFICIARY_DETAILS_FAIL,
  payload: data,
});

export const addBeneficiaryDetails = data => ({
  type: actionTypes.ADD_BENEFICIARY_DETAILS,
  payload: data,
});

export const addBeneficiaryDetailsSuccess = data => ({
  type: actionTypes.ADD_BENEFICIARY_DETAILS_SUCCESS,
  payload: data,
});

export const addBeneficiaryDetailsFail = data => ({
  type: actionTypes.ADD_BENEFICIARY_DETAILS_FAIL,
  payload: data,
});

export const updateBeneficiaryDetails = data => ({
  type: actionTypes.UPDATE_BENEFICIARY_DETAILS,
  payload: data,
});

export const updateBeneficiaryDetailsSuccess = data => ({
  type: actionTypes.UPDATE_BENEFICIARY_DETAILS_SUCCESS,
  payload: data,
});

export const updateBeneficiaryDetailsFail = data => ({
  type: actionTypes.UPDATE_BENEFICIARY_DETAILS_FAIL,
  payload: data,
});

export const getCurrencyRateList = () => ({
  type: actionTypes.GET_CURRENCY_RATE_LIST,
});

export const getCurrencyRateListSuccess = data => ({
  type: actionTypes.GET_CURRENCY_RATE_LIST_SUCCESS,
  payload: data,
});

export const getCurrencyRateListFail = data => ({
  type: actionTypes.GET_CURRENCY_RATE_LIST_FAIL,
  payload: data,
});

export const updateListLoading = data => ({
  type: actionTypes.UPDATE_LIST_LOADING,
  payload: data,
});

export const alertMsgEmpty = () => ({
  type: actionTypes.ALERT_MSG_EMPTY,
});

export const updateAlertMsg = data => ({
  type: actionTypes.UPDATE_ALERT_MSG,
  payload: data,
});

export const updateApiStatus = data => ({
  type: actionTypes.UPDATE_API_STATUS,
  payload: data,
});

export const logOut = () => ({
  type: actionTypes.LOG_OUT,
});
