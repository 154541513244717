export const ENV = 'PROD';
export const AUTHENTICATION_FAILED = 'Authentication Failed';
export const THEME_COLOR = '#f3f7fa';
// export const THEME_COLOR2 = '#009ef7';
export const THEME_COLOR2 = '#315CCB';
export const THEME_COLOR3 = '#f9f9f9';
export const THEME_COLOR4 = '#7e8299';
export const THEME_COLOR5 = '#D8F0D4';
export const THEME_COLOR6 = '#80B500';
export const THEME_COLOR7 = '#80B500';
// export const THEME_COLOR8 = 'orange';
export const THEME_COLOR8 = '#EA910D';
export const THEME_COLOR9 = '#e80707';
export const THEME_COLOR10 = '#999';
