import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import moment from "moment";

import styles from './OrderShippingDetailView.module.css'

const OrderShippingDetailView = (props) => {

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Processing Time</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Method of Shipping</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Cargo ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Container Type</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Carrier</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Port of Loading</Typography>
                <Typography className={styles.subTitleText}>Port of Discharge</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Ship by Date</Typography>
                <Typography className={styles.subTitleText}>Deliver by Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>P.S.I Required</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Pickup Schedule</Typography>
                {item?.sgs_preshipment_inspection == 1 ?
                  <Typography className={styles.subTitleText}>P.S.I Schedule</Typography>
                  : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.processing_time ? (item?.processing_time + ' Days') : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.mode_of_shipping ? item?.mode_of_shipping : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.cargo_id ? item?.cargo_id : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.container_type ? item?.container_type : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.carrier ? item?.carrier : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>Port of
                  Loading: {item?.loading_port ? item?.loading_port : '-'}</Typography>
                <Typography className={styles.subValueText} mt={1}>Port of
                  Discharge: {item?.discharge_port ? item?.discharge_port : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  Ship by
                  Date: {item?.shipping_date ? moment(item?.shipping_date).format('DD-MM-YYYY') : '-'}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  Delivery by
                  Date: {item?.delivery_date ? moment(item?.delivery_date).format('DD-MM-YYYY') : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.sgs_preshipment_inspection == 1 ? 'Yes' : 'No'}
                </Typography>
              </TableCell>
              <TableCell>
                {item?.order_booking_details_uid ?
                  <>
                    <Box>
                      <Typography className={styles.valueText}>
                        Pickup
                        Schedule: {item?.pickup_schedule_date ? moment(item?.pickup_schedule_date).format('DD-MM-YYYY') : '-'}
                      </Typography>
                      <Typography className={styles.valueText}>
                        {item?.pickup_schedule_time ?
                          item?.pickup_schedule_time == 0 ?
                            moment("0000", 'HHmm').format('hh:mm A')
                            : moment(item?.pickup_schedule_time, 'HHmm').format('hh:mm A')
                          : '-'}
                      </Typography>
                    </Box>
                    {item?.sgs_preshipment_inspection == 1 ?
                      (<Box mt={1}>
                        <Typography className={styles.subValueText}>
                          P.S.I
                          Schedule: {item?.preshipment_inspection_schedule_place ? item.preshipment_inspection_schedule_place : '-'}
                        </Typography>
                        <Typography className={styles.subValueText}>
                          {item?.preshipment_inspection_schedule_date ? moment(item?.preshipment_inspection_schedule_date).format('DD-MM-YYYY') : '-'}
                        </Typography>
                        <Typography className={styles.subValueText}>
                          {item?.preshipment_inspection_schedule_time ?
                            item?.preshipment_inspection_schedule_time == 0 ?
                              moment("0000", 'HHmm').format('hh:mm A')
                              : moment(item?.preshipment_inspection_schedule_time, 'HHmm').format('hh:mm A')
                            : '-'}
                        </Typography>
                      </Box>)
                      : null}
                  </>
                  : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default OrderShippingDetailView;
