import React from 'react';
import {Box, Typography} from "@mui/material";

import {ReactComponent as ImgIcon} from "../assets/img/img.svg";
import {ReactComponent as DownloadIcon} from "../assets/img/download.svg";
import {ReactComponent as PdfIcon} from "../assets/img/pdf.svg";

const ImageDownload = (props) => {

  const onDownloadImage = () => {
    props.onDownload(props.image, props.fileName);
  };

  return (
    <Box>
      <Box sx={styles.uploadMainImgView}>
        <Box display={"flex"}
             alignItems={"center"}
             justifyContent={"center"}
             sx={styles.uploadImgView}>
          {props.image ?
            (props.fileType == 'pdf' ?
              <PdfIcon height={50} width={50}/> :
              <img src={props.image} height="100" width="100"/>)
            :
            <>
              <ImgIcon height={50} width={50}/>
            </>}
        </Box>

        {props.image ?
          <Box display={"flex"}
               alignItems={"center"}
               justifyContent={"center"}
               sx={styles.downloadIconView}
               onClick={onDownloadImage}>
            <DownloadIcon height={13} width={13}/>
          </Box>
          : null}
      </Box>
    </Box>
  )
};

export default ImageDownload;

const styles = {
  uploadMainImgView: {
    position: 'relative',
    width: 'fit-content',
  },
  uploadImgView: {
    overflow: 'hidden',
    border: '3px solid #FFF',
    borderRadius: 3,
    boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
    height: 100,
    width: 100,
  },
  downloadIconView: {
    position: 'absolute',
    backgroundColor: '#FFF',
    cursor: 'pointer',
    border: '3px solid #FFF',
    borderRadius: 10,
    right: -10,
    bottom: -10,
    boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
    height: 24,
    width: 24,
  },
};
