import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, CircularProgress
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import OrderTabList from "./OrderTabList";
import Page from "./Page";

import {connect} from "react-redux";
import {
  listOrder,
  confirmPendingOrder,
  confirmOrderPayment,
  uploadOrderTrack,
  confirmOrderDelivery, getCurrencyRateList
} from "../redux/actions/userDataActions";

import styles from "./OrderTab.module.css"

const OrderTab = (props) => {
  const [pageNum, setPageNum] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    getOrderList(1)
  }, [props?.filterData])

  const getOrderList = (page) => {
    let filter = props?.filterData;
    let data = {
      page: page,
      status: props?.tab
    };
    if (filter != null) {
      data = {
        page: page,
        status: props?.tab,
        searchOrderId: filter?.searchOrderId ? filter?.searchOrderId : '',
        category: (filter?.category) ? filter?.category : '',
        subCategory: (filter?.category && filter?.subCategory) ? filter?.subCategory : ''
      };
    }
    props.listOrder(JSON.stringify(data))
    setPageNum(page)
  };

  const onPageSelect = (page) => {
    getOrderList(page);
  };

  const onOrderDetail = (order_uid) => {
    navigate('/order_detail/' + order_uid)
  };

  const onOrderCancelDetail = (order_uid) => {
    navigate('/order_detail/' + order_uid + '/cancel')
  }

  // const onConfirmOrder = (order_uid, current_status, new_status) => {
  //   let data = {
  //     orderUid: order_uid,
  //     current_status: current_status,
  //     status: new_status,
  //   };
  //   props.updateOrderStatus(JSON.stringify(data));
  // }

  const onConfirmPendingOrder = (order_uid) => {
    let data = {
      orderUid: order_uid,
    };
    props.confirmPendingOrder(JSON.stringify(data));
  };

  const onUploadDocuments = (order_uid) => {
    navigate('/upload_documents/' + order_uid)
  };

  const onConfirmPayment = (order_uid) => {
    let data = {
      orderUid: order_uid
    }
    props.confirmOrderPayment(JSON.stringify(data))
  };

  const onUploadTrack = (order_uid, trackLink) => {
    let data = {
      orderUid: order_uid,
      trackLink: trackLink,
    }
    props.uploadOrderTrack(JSON.stringify(data))
  }

  const onConfirmDelivery = (order_uid) => {
    let data = {
      orderUid: order_uid
    }
    props.confirmOrderDelivery(JSON.stringify(data))
  }

  if (props.orderLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>
      <Grid container item md={12} xs={12}>
        <TableContainer
          className={'tableContainer'}>
          <Table
            stickyHeader
            aria-label="sticky table">
            <TableHead
              className={'table'}>
              <TableRow
                className={styles.tableHeadRow}>
                <TableCell>
                  <Typography className={styles.labelText}>Seller</Typography>
                  <Typography className={styles.subLabelText} ml={1}>TGSIN</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>SKU</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Product ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Product</Typography>
                  <Typography className={styles.subLabelText} ml={1}>HS code</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Buyer</Typography>
                  <Typography className={styles.subLabelText} ml={1}>TGBIN</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Price/Unit</Typography>
                  <Typography className={styles.subLabelText} ml={1}>EXW/Unit</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order Quantity</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Pickup Location</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order ID</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Order Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>EXW Total</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Shipping Cost</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order Total</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>M. Shipping</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Cargo ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Delivery Date</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Date of Shipping</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order Status</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Payment Status</Typography>
                </TableCell>
                {props?.tab == 'cancelled' ?
                  <TableCell>
                    <Typography className={styles.labelText}>Reason</Typography>
                  </TableCell>
                  :
                  <TableCell>
                    <Typography className={styles.labelText}>Action</Typography>
                  </TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.orderList?.length > 0 ?
                props?.orderList?.map((item, index) => {
                  return (
                    <TableRow key={index} className={styles.tableRow}>
                      <OrderTabList
                        key={index}
                        tab={props?.tab}
                        currencyRateList={props?.currencyRateList}
                        index={index}
                        data={item}
                        onOrderDetail={onOrderDetail}
                        onConfirmPendingOrder={onConfirmPendingOrder}
                        onUploadDocuments={onUploadDocuments}
                        onConfirmPayment={onConfirmPayment}
                        onUploadTrack={onUploadTrack}
                        onConfirmDelivery={onConfirmDelivery}
                      />
                    </TableRow>
                  )
                }) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
        {props.orderTotalPages > 0 ?
          <Page
            totalPages={props.orderTotalPages}
            pageNum={pageNum}
            onSelectPage={onPageSelect}/>
          : null}
      </Box>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    orderTotalPages: state.userData.orderTotalPages,
    orderList: state.userData.orderList,
    orderLoading: state.userData.orderLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listOrder: data => dispatch(listOrder(data)),
    confirmPendingOrder: data => dispatch(confirmPendingOrder(data)),
    confirmOrderPayment: data => dispatch(confirmOrderPayment(data)),
    uploadOrderTrack: data => dispatch(uploadOrderTrack(data)),
    confirmOrderDelivery: data => dispatch(confirmOrderDelivery(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTab);
