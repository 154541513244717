import React, { useEffect } from 'react'
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import FormButton from "../components/FormButton";
import FormTextInput from '../components/FormTextInput';
import ImageUpload from "../components/ImageUpload"

import { ReactComponent as CloseIcon } from "../assets/img/close.svg";

const UploadTrackModal = (props) => {
  const [link, setLink] = useState('')
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!props.open) {
      setLink('');
      setError(null)
    }
  }, [props.open]);

  const onCloseClick = () => {
    props.onClose();
  };

  const onSubmitBtnClick = () => {
    let errorText = {};
    if (link == '') {
      errorText = { link: 'Link is required' };
    } else if(!link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
      errorText = { link: 'Invalid Link' };
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    props.onSubmit(link);
  };

  return (
    <Modal
      open={props.open}
      onClose={onCloseClick}
      style={styles.modelView}
      hideBackdrop={true}>
      <Box sx={styles.modalSize}>
        <Box sx={styles.headerView}>
          <Typography align="center" sx={styles.headerTitle}>
            Upload Track
          </Typography>
          <Box sx={styles.closeBtnView} onClick={props.onClose}>
            <CloseIcon width={23} height={23} />
          </Box>
        </Box>

        <Grid mt={3}>
          <FormTextInput
            page={'UploadTrackModal'}
            value={link}
            setValue={setLink}
            label={'Link'}
            error={error?.link}
            required={true}
          />
        </Grid>
        <Box display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box mr={2}>
            <FormButton
              btnType={'cancel'}
              title={"Cancel"}
              btnStyleView={{ height: 35 }}
              onSubmit={onCloseClick}
            />
          </Box>
          <Box>
            <FormButton
              title={"Submit"}
              btnStyleView={{ height: 35 }}
              onSubmit={onSubmitBtnClick}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UploadTrackModal

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalSize: {
    backgroundColor: '#FFF',
    width: { md: '30%', xs: '90%' },
    borderRadius: 2,
    padding: 4
  },
  headerView: {
    position: 'relative',
  },
  headerTitle: {
    fontFamily: 'InterBold',
    fontSize: 20
  },
  closeBtnView: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    top: 0,
    bottom: 0,
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  labelText: {
    color: '#000',
    fontSize: 15,
    fontFamily: 'InterMedium',
  },
};
