import React, {useState, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";

import Container from "../../components/Container";
import ReviewDeliveryAddress from "../../components/ReviewDeliveryAddress";
import ReviewShipping from "../../components/ReviewShipping";
import ReviewScheduleOrder from "../../components/ReviewScheduleOrder";
import ReviewPaymentMethod from "../../components/ReviewPaymentMethod";
import CartShippingCostPending from "../../components/CartShippingCostPending";

import {connect} from "react-redux";
import {getCartDetail, updateApiStatus} from "../../redux/actions/userDataActions";

import "../../assets/css/custom.css";

import styles from './CartShippingCostPendingDetail.module.css'

const CartShippingCostPendingDetail = (props) => {
  const location = useLocation();

  const [cartDetail, setCartDetail] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    getCartDetail();
  }, []);

  const getCartDetail = () => {
    let data = {
      cart_uid: location.pathname.split("/").pop(),
    };
    props.getCartDetail(JSON.stringify(data));
  };

  useEffect(() => {
    if (props.cartDetail != null) {
      setCartDetail(props.cartDetail)
    }
  }, [props.cartDetail])

  return (
    <Container page={"cart"}>
      <Grid
        item
        md={12}
        xs={12}
        container
        display={"flex"}
        alignItems={"center"}
      >
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <h3 style={{margin: 0}}>Cart Details</h3>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          my={3}
          className={styles.cardDiv}
        >
          <CartShippingCostPending
            page={'CartShippingCostPendingDetail'}
            type={'detail'}
            data={cartDetail}
          />

        </Grid>

        <Grid container
              item md={12} xs={12}
              display={"flex"} alignItems={"center"} justifyContent={"center"} mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>1.Delivery address</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewDeliveryAddress
              data={cartDetail}
            />
          </Grid>

        </Grid>

        <Grid container
              item md={12} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"} mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>2.Shipping</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewShipping
              data={cartDetail}
            />
          </Grid>

        </Grid>

        <Grid container
              item md={12} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
              mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>3.Schedule your order</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewScheduleOrder
              data={cartDetail}
            />
          </Grid>

        </Grid>

        <Grid container
              item md={12} xs={11}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
              mt={4}
        >
          <Grid item md={12} xs={12}
                display="flex" flexDirection="row" justifyContent="left">
            <Typography className={styles.addressMainText}>4.Payment Method</Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <ReviewPaymentMethod
              data={cartDetail}
            />
          </Grid>

        </Grid>


      </Grid>


    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    cartLoading: state.userData.cartLoading,
    cartDetail: state.userData.cartDetail,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: (data) => dispatch(getCartDetail(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartShippingCostPendingDetail);
