import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Container from "../../components/Container";
import CartShippingCostPending from "../../components/CartShippingCostPending";
import CartReviewPending from "../../components/CartReviewPending";
import CartCheckoutPending from "../../components/CartCheckoutPending";

import {THEME_COLOR2, THEME_COLOR9} from "../../constants";

import {connect} from "react-redux";
import {
  getCartCount, getCurrencyRateList
} from "../../redux/actions/userDataActions";
import ProductView from "../../components/ProductView";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Cart = (props) => {
  // const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  // const [type, setType] = useState('');
  // const [customerUid, setCustomerUid] = useState('');
  // const [filterOptions, setFilterOptions] = useState([]);
  const [value, setValue] = useState(0);
  const [cartTotalItem, setCartTotalItem] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    props?.getCurrencyRateList()
  },[])

  useEffect(() => {
    props.getCartCount()
  }, [])

  useEffect(() => {
    if (props?.reviewPendingCount || props?.shippingCostPendingCount || props?.checkoutPendingCount) {
      let total = parseInt(props?.reviewPendingCount) + parseInt(props?.shippingCostPendingCount) + parseInt(props?.checkoutPendingCount)
      setCartTotalItem(total)
    }
  }, [props?.reviewPendingCount, props?.shippingCostPendingCount, props?.checkoutPendingCount])


  //
  // const onViewDetails = (uid) => {
  //   navigate("/customer_detail?id=" + uid)
  // };

  // const onConfirmClick = () => {
  //   let data = {
  //     userUid: customerUid,
  //     status: type
  //   };
  //   props.updateCustomerStatus(JSON.stringify(data));
  //   setConfirmBoxVisible(false);
  //   setTimeout(() => {
  //     setType("");
  //     setCustomerUid('')
  //   }, 500);
  // };
  //
  // const onCancelClick = () => {
  //   setConfirmBoxVisible(false);
  //   setTimeout(() => {
  //     setType("");
  //     setCustomerUid('')
  //   }, 500);
  // };
  //
  // const onActionOptionClick = (type, uid) => {
  //   setType(type);
  //   setConfirmBoxVisible(true);
  //   setCustomerUid(uid);
  // };
  //
  // const onFilter = (data, val) => {
  //   let filterIndex = filterOptions.findIndex((x) => ((x.option == data.option) && (x.type == data.type)));
  //   if (filterIndex >= 0) {
  //     filterOptions[filterIndex]['value'] = val;
  //   }
  //   setFilterOptions(filterOptions);
  // };
  //
  // const onApplyFilter = (page) => {
  //   let search_user = '';
  //   let status = '';
  //   filterOptions.map((x) => {
  //     if ((x.type === 'input') && (x.option === 'User')) {
  //       search_user = x.value
  //     } else if ((x.type === 'select') && (x.option === 'Status')) {
  //       status = x.value
  //     }
  //   });
  //
  //   setFilterOptions(filterOptions);
  //
  //   let filter_data = {
  //     search_user: search_user,
  //     status: status,
  //   };
  //
  //   getCustomerList(page, filter_data);
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container page={"cart"}>
      <Grid item md={12} xs={12}
            container
            display={"flex"}
            alignItems={"center"}>
        <Grid item md={12} xs={12}
              container>
          <h2>Cart ({cartTotalItem + (cartTotalItem > 1 ? ' Items' : ' Item')})</h2>
        </Grid>


        <Grid container item md={12} xs={12} mt={1}
              display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
          <Grid item md={11} xs={11}>
            <Box>
              <Tabs scrollButtons="on" variant="scrollable" value={value} onChange={handleChange}
                    sx={{
                      "& .MuiTab-root": {
                        color: THEME_COLOR2,
                        fontSize: 15,
                        fontFamily: 'InterBold',
                        textTransform: 'capitalize',
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: THEME_COLOR9,
                      },
                      "& .MuiTab-root.Mui-selected": {
                        color: THEME_COLOR9,
                        fontSize: 15,
                        fontFamily: 'InterBold',
                        textTransform: 'capitalize'
                      }
                    }}
                    aria-label="basic tabs example">
                <Tab label={(props?.reviewPendingCount ? props.reviewPendingCount : 0) + ' Review Pending'} {...a11yProps(0)} />
                <Tab label={(props?.shippingCostPendingCount ? props.shippingCostPendingCount : 0) + ' Shipping Cost Pending'} {...a11yProps(1)} />
                <Tab label={(props?.checkoutPendingCount ? props.checkoutPendingCount  : 0 )+ ' Checkout Pending'} {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <TabPanel value={value} index={0}>
            <CartReviewPending/>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <CartShippingCostPending
             page={'Cart'}
             type={'list'}/>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <CartCheckoutPending/>
          </TabPanel>
        </Grid>

      </Grid>

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    reviewPendingCount: state.userData.reviewPendingCount,
    shippingCostPendingCount: state.userData.shippingCostPendingCount,
    checkoutPendingCount: state.userData.checkoutPendingCount,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getCartCount: data => dispatch(getCartCount(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

