import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';
import {useNavigate} from "react-router-dom";

import FormButton from "./FormButton";
import FormSelectBox from "./FormSelectBox";
import {getCurrencyConversionCalc, unshippedOrderStatus} from '../assets/functions/common'
import UploadTrackModal from "./UploadTrackModal";

import {connect} from "react-redux";
import {updateApiStatus} from "../redux/actions/userDataActions";

import styles from "./OrderTabList.module.css"

import {THEME_COLOR3, THEME_COLOR7} from "../constants";

import {COUNTRIES} from "../constants/countries";

const OrderTabList = (props) => {
  const [orderUid, setOrderUid] = useState('');
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pricePerKgWithoutReferralFee, setPricePerKgWithoutReferralFee] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [totalPriceWithoutReferralFee, setTotalPriceWithoutReferralFee] = useState(0);
  const [referralFeePerPackage, setReferralFeePerPackage] = useState(0);
  const [totalReferralFee, setTotalReferralFee] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [actualPrice, setActualPrice] = useState(0);
  const [actualPricePerKg, setActualPricePerKg] = useState(0);
  const [actualTotalPrice, setActualTotalPrice] = useState(0);
  const [actualPricePerKgWithoutReferralFee, setActualPricePerKgWithoutReferralFee] = useState(0);
  const [actualShippingCost, setActualShippingCost] = useState('')
  const [actualTotalPriceWithoutReferralFee, setActualTotalPriceWithoutReferralFee] = useState(0);
  const [actualReferralFeePerPackage, setActualReferralFeePerPackage] = useState(0);
  const [actualTotalReferralFee, setActualTotalReferralFee] = useState(0);
  const [actualCargoPrice, setActualCargoPrice] = useState(0);
  const [actualCurrencySymbol, setActualCurrencySymbol] = useState('')

  const [document, setDocument] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {
        let currency_symbol = '';
        let price_val = 0;
        let price_per_kg = 0;
        let total_price = 0;
        let shipping_cost = 0;
        let price_per_kg_without_referral_fee = 0;
        let total_price_without_referral_fee = 0;
        let referral_fee_per_package = 0;
        let total_referral_fee = 0;
        let cargo_price = 0;
        let actual_currency_symbol = '';
        let actual_price_val = 0;
        let actual_price_per_kg = 0;
        let actual_total_price = 0;
        let actual_shipping_cost = 0;
        let actual_price_per_kg_without_referral_fee = 0;
        let actual_total_price_without_referral_fee = 0;
        let actual_referral_fee_per_package = 0;
        let actual_total_referral_fee = 0;
        let actual_cargo_price = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props?.data?.shipping_cost ? props?.data?.shipping_cost : 0,
          currency: props?.data?.seller_currency,
          admin_currency: props?.data?.admin_currency,
          admin_currency2: props?.data?.admin_currency2,
          admin_rate: props?.data?.admin_rate,
          usd_rate: props?.data?.usd_rate,
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          actual_price_val = data?.price;
          actual_price_per_kg = data?.price_per_kg;
          actual_total_price = data?.total_price;
          actual_shipping_cost = data?.shipping_cost;
          actual_price_per_kg_without_referral_fee = data?.price_per_kg_without_referral_fee;
          actual_referral_fee_per_package = data?.referral_fee_per_package;
          actual_cargo_price = data?.cargo_price;
          actual_currency_symbol = data?.currency_symbol;
          actual_total_price_without_referral_fee = data?.total_price_without_referral_fee;
          actual_total_referral_fee = data?.total_referral_fee;
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          total_price_without_referral_fee = data?.converted_total_price_without_referral_fee;
          price_per_kg_without_referral_fee = data?.converted_price_per_kg_without_referral_fee;
          referral_fee_per_package = data?.converted_referral_fee_per_package;
          total_referral_fee = data?.converted_total_referral_fee;
          shipping_cost = data?.converted_shipping_cost;
          cargo_price = data?.converted_cargo_price;
          currency_symbol = data?.admin_currency_symbol;
        }

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setCargoPrice(cargo_price)
        setShippingCost(shipping_cost)
        setTotalPriceWithoutReferralFee(total_price_without_referral_fee);
        setPricePerKgWithoutReferralFee(price_per_kg_without_referral_fee)
        setReferralFeePerPackage(referral_fee_per_package);
        setTotalReferralFee(total_referral_fee);
        setCurrencySymbol(currency_symbol)

        setActualPrice(actual_price_val);
        setActualTotalPrice(actual_total_price)
        setActualPricePerKg(actual_price_per_kg);
        setActualCargoPrice(actual_cargo_price)
        setActualShippingCost(actual_shipping_cost)
        setActualTotalPriceWithoutReferralFee(actual_total_price_without_referral_fee);
        setActualPricePerKgWithoutReferralFee(actual_price_per_kg_without_referral_fee)
        setActualReferralFeePerPackage(actual_referral_fee_per_package);
        setActualTotalReferralFee(actual_total_referral_fee);
        setActualCurrencySymbol(actual_currency_symbol)
      }

      if (props?.tab == 'unshipped' || props?.tab == 'uploadDocument') {
        // if (props?.data?.unshipped_timestamp) {
        //   let order_status = unshippedOrderStatus(props?.data?.unshipped_timestamp);
        //   setOrderStatus(order_status);
        // }
        if (props?.data?.payment_status == 'paid') {
          setOrderStatus('Pickup Scheduled');
        } else {
          setOrderStatus('Confirmed')
        }
      } else {
        setOrderStatus(props.data?.order_status)
      }
    }
  }, [props.data, props?.currencyRateList, props?.data?.payment_status])

  useEffect(() => {
    if (props.apiStatus == 'UPLOAD_ORDER_TRACK_SUCCESS') {
      props.updateApiStatus('')
      setModalVisible(false);
      setOrderUid('')
    }
  }, [props.apiStatus])

  const onUploadBooking = (item) => {
    navigate('/booking_details/' + item?.order_uid)
  };

  const onOrderDetail = (item) => {
    props.onOrderDetail(item?.order_uid)
  };

  const onConfirmPendingOrder = (item) => {
    props.onConfirmPendingOrder(item?.order_uid)
  };

  const onUploadDocuments = (item) => {
    props.onUploadDocuments(item?.order_uid)
  };

  const onConfirmPayment = (item) => {
    props.onConfirmPayment(item?.order_uid)
  };

  const onUploadTracking = (item) => {
    if (item?.track_order) {
      window.open(item?.track_order, '_blank')
    } else {
      setOrderUid(item?.order_uid);
      setModalVisible(true)
    }
  };

  const onCloseTrack = () => {
    setOrderUid('');
    setModalVisible(false)
  }

  const onSubmitTrack = (trackLink) => {
    props.onUploadTrack(orderUid, trackLink)
  };

  const onConfirmDelivery = (item) => {
    props.onConfirmDelivery(item?.order_uid)
  };

  const onDocument = (val) => {
    setDocument(val);
    let list = [...props?.data?.document_list];
    let index = list.findIndex((x) => x.value == val);
    if (index >= 0) {
      window.open(list[index]['document_file'], '_blank')
    }
  }

  let item = props.data;

  return (
    <>
      <TableCell>
        <Typography
          className={styles.sellerText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}
          {item?.seller_country ? '(' + COUNTRIES.filter(
            (x) => x.code == item?.seller_country
          )?.[0]?.label + ')' : ''}</Typography>
        <Typography
          className={styles.tgsinText} mt={1}>TGSIN: {item?.tgsin ? item?.tgsin : ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.skuText}>{item?.sku}</Typography>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView}>
          {item?.product_image ?
            <Grid item md={4} xs={12} className={styles.imgView}>
              <img src={item.product_image} className={styles.imgStyle}/>
            </Grid>
            : null}
          <Typography className={styles.productNameTitleText}>
            {(item?.variety ? item.variety : 'NIL Variety')
            + ', ' + (item?.name ? item.name : 'NIL')
            + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
            + ', ' + (item?.package_net_weight ?
              (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
              : 'NIL Packing')
            + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
          </Typography>
          <Typography className={styles.pricePerKgPackageText} mt={1}>({currencySymbol}{pricePerKg?.toFixed(2)} / Kg)
            ({currencySymbol}{price?.toFixed(2)} /
            Package)</Typography>
          <Typography className={styles.hsCodeText} mt={1}>HS
            Code: {item?.hs_code ? (item.hs_code + ' - ' + item.hs_code_title) : ''}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography
            className={styles.sellerText}>{item?.user_name_entity ? item?.user_name_entity : ''}
            {item?.user_country ? '(' + COUNTRIES.filter(
              (x) => x.code == item?.user_country
            )?.[0]?.label + ')' : ''}</Typography>
          <Typography
            className={styles.tgsinText} mt={1}>TGBIN: {item?.tgbin ? item?.tgbin : ''}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.pricePerKgText}>{currencySymbol}{pricePerKgWithoutReferralFee?.toFixed(7)} /
            Kg</Typography>
          <Typography className={styles.pricePerPackageText} mt={1}>{currencySymbol}{pricePerKg?.toFixed(7)} /
            Kg</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.quantityText}>
            Order Quantity: {item?.quantity ? (item?.package_net_weight * item?.quantity) + item?.unit : 0}
          </Typography>
          <Typography className={styles.pickupLocationText} mt={1}>
            Pickup Location: {item?.pickup_location ? item?.pickup_location : ''}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView2}>
          <Typography
            className={styles.orderUidText}>{item?.order_uid ? '#' + item.order_uid : ''}</Typography>
          <Typography
            className={styles.OrderTimestampText}
            mt={1}>{item?.order_timestamp ? item.order_timestamp : ''}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.totalPriceText}>
            {currencySymbol}{totalPrice ? totalPrice?.toFixed(2) : 0}
          </Typography>
          <Typography className={styles.shippingCostText} mt={1}>
            Shipping Cost: {currencySymbol}{shippingCost ? shippingCost?.toFixed(2) : 0}
          </Typography>
          <Typography className={styles.shippingCostText} mt={1}>
            Referral
            Fee: {currencySymbol}{referralFeePerPackage ? (referralFeePerPackage * item?.quantity)?.toFixed(2) : 0}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.cargoPriceText}>
            {actualCurrencySymbol}{actualCargoPrice ? actualCargoPrice?.toFixed(2) : 0}
          </Typography>
          <Typography className={styles.cargoPriceText} pl={3}>
            ({currencySymbol}{cargoPrice ? cargoPrice?.toFixed(2) : 0})
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.shippingModeText}>
            Mode of Shipping: {item?.mode_of_shipping ? item.mode_of_shipping : ''}
          </Typography>
          <Typography className={styles.incotermText} mt={1}>
            Incoterm: {item?.incoterm ? item.incoterm : ''}
          </Typography>
          <Typography className={styles.incotermText} mt={1}>
            Cargo ID: {item?.cargo_id ? item.cargo_id : ''}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          {item?.delivery_date ?
            <>
              <Typography className={styles.deliveryDateText}>
                Delivery Date:
              </Typography>
              <Typography className={styles.deliveryDateText}>
                {item?.delivery_date ? moment(item?.delivery_date).format('DD-MM-YYYY') : '-'}
              </Typography>
            </>
            : null}
          <>
            <Typography className={styles.shippingDateText} mt={1}>
              Ship by Date:
            </Typography>
            <Typography className={styles.shippingDateText}>
              {item?.shipping_date ? moment(item?.shipping_date).format('DD-MM-YYYY') : '-'}
            </Typography>
          </>
        </Box>
      </TableCell>
      <TableCell>
        <Typography className={styles.orderStatusText}>
          Order Status: {orderStatus ? orderStatus?.replaceAll("_", " ") : ''}
        </Typography>
        <Typography className={styles.paymentStatusText} mt={1}>
          Payment Status: {item?.payment_status ?
          (item.payment_status == 'half_paid' ? '50% Paid' : item.payment_status)
          : ''}
        </Typography>
      </TableCell>
      {props?.tab != 'uploadDocument' ?
        <TableCell>
          {(props?.tab == 'unshipped') ?
            <Box className={styles.btnView} mt={1}>
              <FormButton
                loading={props.btnLoading}
                title={'Upload Documents'}
                width={'100%'}
                onSubmit={() => onUploadDocuments(item)}
              />
            </Box>
            : null}
          {!item?.order_booking_details_uid && (props?.tab == 'pending') ?
            <Box className={styles.btnView} mt={1}>
              <FormButton
                title={'Upload Booking'}
                btnType={'btn3'}
                width={'100%'}
                onSubmit={() => onUploadBooking(item)}
              />
            </Box>
            :
            props?.tab != 'cancelled' ?
              <Box className={styles.btnView} mt={1}>
                <FormSelectBox
                  page={'OrderTabList'}
                  placeholder={'Documents'}
                  value={document}
                  setValue={(val) => onDocument(val)}
                  placeholderColorOpacity={'1'}
                  textInputView={{
                    backgroundColor: THEME_COLOR7,
                    borderRadius: 1
                  }}
                  selectedItemView={{
                    backgroundColor: THEME_COLOR7,
                    color: '#000',
                    fontFamily: 'InterBold',
                    fontSize: 13,
                    px: 1.5,
                  }}
                  textInputValue={{
                    color: '#000',
                    fontSize: 13,
                    px: 1.5,
                    fontFamily: 'InterBold',
                  }}
                  data={item?.document_list}
                />
              </Box>
              : null
          }


          {props?.tab == 'cancelled' ?
            <Box className={styles.btnView}>
              <FormButton
                title={'View'}
                btnType={'btn3'}
                width={'100%'}
                onSubmit={() => onOrderDetail(item)}
              />
            </Box>
            :
            <Box className={styles.btnView} mt={1}>
              <FormButton
                title={'Order Details'}
                btnType={'btn3'}
                width={'100%'}
                onSubmit={() => onOrderDetail(item)}
              />
            </Box>
          }

          {item?.order_booking_details_uid && item?.order_payment && (props?.tab == 'pending') ?
            <Box className={styles.btnView} mt={1}>
              <FormButton
                loading={props.btnLoading}
                title={'Confirm Order'}
                btnType={'btn4'}
                width={'100%'}
                onSubmit={() => onConfirmPendingOrder(item)}
              />
            </Box>
            : null}
          {(item?.payment_term_amount == 50) && (props?.tab == 'unshipped') && (item?.payment_status == 'completed') ?
            <Box className={styles.btnView} mt={1}>
              <FormButton
                loading={props.btnLoading}
                title={'Confirm Payment'}
                btnType={'btn4'}
                width={'100%'}
                onSubmit={() => onConfirmPayment(item)}
              />
            </Box>
            : null}
          {props?.tab == 'shipped' ?
            <>
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  title={item?.track_order ? 'Track Order' : 'Upload Tracking'}
                  btnType={'btn5'}
                  width={'100%'}
                  onSubmit={() => onUploadTracking(item)}
                />
              </Box>
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  loading={props.btnLoading}
                  title={'Confirm Delivery'}
                  btnType={'btn4'}
                  width={'100%'}
                  onSubmit={() => onConfirmDelivery(item)}
                />
              </Box>
            </>
            : null}
        </TableCell>
        : null}

      <UploadTrackModal
        open={modalVisible}
        onClose={onCloseTrack}
        onSubmit={onSubmitTrack}/>

    </>
  )
};

const mapStateToProps = (state) => {
  return {
    btnLoading: state.userData.btnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderTabList);

