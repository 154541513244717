import React from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

import styles from './ProductDocumentView.module.css'

const ProductDocumentView = (props) => {

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Document Name</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Document ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Uploaded Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>View Document</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {props?.data?.productDocumentList?.length > 0 ?
              props?.data?.productDocumentList?.map((item, index) => {
                return (
                  <TableRow key={index} className={styles.tableRow}>
                    <TableCell>
                      {item?.documentName}
                    </TableCell>
                    <TableCell>
                      {item?.documentId?.split(".")?.[0]}
                    </TableCell>
                    <TableCell>
                      {item?.timestamp}
                    </TableCell>
                    <TableCell
                      style={{cursor: 'pointer'}}
                      onClick={() => window.open(item?.displayDocument, '_blank')}>
                      View
                    </TableCell>
                  </TableRow>
                );
              }) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ProductDocumentView;
