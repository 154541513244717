import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import moment from "moment";

import styles from "./ReviewScheduleOrder.module.css";

import {getCurrencyConversionCalc} from "../assets/functions/common";

import {connect} from "react-redux";

const ReviewScheduleOrder = (props) => {
  const [cartDetail, setCartDetail] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [currencySymbol, setCurrencySymbol] = useState('')

  useEffect(() => {
    if (props.data != null) {
      setCartDetail(props.data)
      // let price_val = 0;
      let total_price_val = 0;
      let currency_symbol = '';

      let data_list = {
        price_per_package: props?.data?.price_per_package,
        package_net_weight: props?.data?.package_net_weight,
        referral_fee: props?.data?.referral_fee,
        quantity: props?.data?.quantity,
        shipping_cost: 0,
        currency: props?.data?.seller_currency,
        admin_currency: props?.data?.admin_currency,
        admin_currency2: props?.data?.admin_currency2,
      }

      let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
      if (data != null) {
        total_price_val = data?.converted_total_price;
        currency_symbol = data?.admin_currency_symbol;
      }

      setTotalPrice(total_price_val)
      setCurrencySymbol(currency_symbol)

      // if (props.data?.price_per_package != '' && props.data?.package_net_weight != '') {
      //   price_val = (parseFloat((((props.data?.price_per_package / props.data?.package_net_weight) * props.data?.referral_fee) / 100) + (props.data?.price_per_package / props.data?.package_net_weight)) * props.data?.package_net_weight)?.toFixed(2)
      //   if (props?.data?.quantity != '') {
      //     total_price_val = price_val * props?.data?.quantity
      //     setTotalPrice(total_price_val)
      //   } else {
      //     setTotalPrice(0)
      //   }
      // }
    }
  }, [props.data])

  // useEffect(() => {
  //   if (props?.data?.containerTypeList?.length > 0 && props?.containerType != '') {
  //     let list = [...props?.data?.containerTypeList];
  //     let index = list?.findIndex((x) => x.value == props?.containerType);
  //     let label = '';
  //     if (index >= 0) {
  //       label = list?.[index]?.['label'];
  //       setContainerType(label);
  //     }
  //   }
  // }, [props.data, props?.containerType])

  return (
    <Grid item md={12} xs={12}>
      <Grid container item md={12} xs={12} className={styles.mainView}
            display={"flex"} flexDirection={"row"} justifyContent={"space-between"}
            mt={1}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Cargo ID</TableCell>
                <TableCell>Date of shipping</TableCell>
                <TableCell>Type of Container</TableCell>
                <TableCell>Total product quantity (kg)</TableCell>
                <TableCell>Total cargo weight (kg)</TableCell>
                <TableCell>Number of packages</TableCell>
                <TableCell>
                  Total cargo volume <Typography className={styles.volumeTitleText}>(m<sup>3</sup>)</Typography>
                </TableCell>
                <TableCell>Total product price (EXW)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography className={styles.valueText}>
                    {cartDetail?.shipping_details?.cargo_id ? '#' + cartDetail?.shipping_details?.cargo_id : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.valueText}>
                    {cartDetail?.shipping_details?.shipping_date ?
                      moment(cartDetail?.shipping_details?.shipping_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.valueText}>
                    {cartDetail?.shipping_details?.container_type ? cartDetail?.shipping_details?.container_type : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.valueText}>
                    {cartDetail?.package_net_weight ? (cartDetail?.package_net_weight * cartDetail?.quantity + ' ' + cartDetail?.unit) : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.valueText}>
                    {cartDetail?.package_gross_weight ? (cartDetail?.package_gross_weight * cartDetail?.quantity + ' ' + cartDetail?.unit) : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.valueText}>Required Packages
                    : {cartDetail?.quantity}</Typography>
                  <Typography className={styles.valueText} mt={1}>Order Quantity
                    : {cartDetail?.quantity * props?.data?.package_net_weight} kg</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.valueText}>
                    {cartDetail?.length ? (((cartDetail?.length * cartDetail?.width * cartDetail?.height) / 1000000) * cartDetail?.quantity) : '0'} m<sup>3</sup>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.valueText}>
                    {currencySymbol} {totalPrice?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    currencyRateList: state.userData.currencyRateList,
  };
};

export default connect(mapStateToProps, null)(ReviewScheduleOrder);
