import * as React from 'react';
import {Box, Typography, Button, Dialog, DialogContent, Grid} from '@mui/material';

import FormButton from "./FormButton";

const AlertDialogBox = (props) => {
  const onConfirmClick = () => {
    props.onConfirmClick(props.type)
  };

  const onCancelClick = () => {
    props.onCancelClick()
  };

  return (
    <Dialog
      open={props.confirmBox}
      onClose={onCancelClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={styles.mainBoxView}>
      <Box style={styles.boxView}>
        <DialogContent>
          <Box display={'flex'} justifyContent={"center"}>
            {props.icon != '' ? props.icon : ''}
          </Box>
          <Box mt={3}>
            <Typography sx={styles.contentText}>{props.content}</Typography>
          </Box>
        </DialogContent>
        <Grid item md={12} xs={12} container
              mt={{md: 2, xs: 1}}
              display="flex" flexDirection="row" alignItems={"center"} justifyContent="space-evenly">
          <FormButton
            // loading={props.btnLoading}
            title={props.firstBtn}
            btnType={'btn4'}
            onSubmit={onConfirmClick}
          />
          <FormButton
            title={props.secondBtn}
            btnType={'cancel'}
            onSubmit={onCancelClick}
          />
        </Grid>
      </Box>
    </Dialog>
  )
}

export default AlertDialogBox

const styles = {
  mainBoxView: {
    '& .MuiDialog-paper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 2,
      width: {md: '25%', xs: '90%'},
      minHeight: {md: '38%', xs: '38%'},
    },
  },
  boxView: {
    padding: 12,
  },
  contentText: {
    textAlign: 'center',
    fontSize: 15,
    color: '#000',
    fontFamily: 'InterMedium'
  },
}
