import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

import Container from "../../components/Container";
import ProductDetailView from "../../components/ProductDetailView";
import ProductPackingDetailView from "../../components/ProductPackingDetailView";
import ProductShippingDetailView from "../../components/ProductShippingDetailView";
import ProductDocumentView from "../../components/ProductDocumentView";
import ProductImageView from "../../components/ProductImageView";
import FormButton from "../../components/FormButton";

import styles from "./ProductDetail.module.css"

import {connect} from "react-redux";
import {getProductDetail, updateApiStatus} from "../../redux/actions/userDataActions";

const ProductDetail = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    var product_uid = location.pathname.split("/").pop();
    let data = {
      product_uid: product_uid,
    };
    props.getProductDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if(props.apiStatus == 'GET_PRODUCT_DETAIL_FAIL') {
      navigate('/product')
      props.updateApiStatus('')
    }
  },[props.apiStatus])

  return (
    <Container page={'product'}>

      <Grid container item md={12} xs={12} mt={4}>
        <Typography
          className={styles.mainLabelText}>Product Details</Typography>
      </Grid>

      <Grid container item md={12} xs={12} mt={1}>
        <FormButton
          title={'Back to Products'}
          btnStyleView={style.btnStyleView}
          bgColorStyleView={'#000'}
          onSubmit={() => navigate('/product')}
        />
      </Grid>


      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Product Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <ProductDetailView
            data={props?.productDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Packing Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <ProductPackingDetailView
            data={props?.productDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Product Shipping Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <ProductShippingDetailView
            data={props?.productDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Product Documents</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <ProductDocumentView
            data={props?.productDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Product Images</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <ProductImageView
            data={props?.productDetail}/>
        </Grid>
      </Grid>


    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    productDetail: state.userData.productDetail,
    listLoading: state.userData.listLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProductDetail: data => dispatch(getProductDetail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);

const style = {
  btnStyleView: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 5,
    height: 30,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #d3d3d3',
  },
}
