import React from 'react';
import Button from '@mui/material/Button';

import {THEME_COLOR2, THEME_COLOR3, THEME_COLOR4, THEME_COLOR7, THEME_COLOR8, THEME_COLOR5, THEME_COLOR9, THEME_COLOR10} from "../constants";

const FormButton = (props) => {

  const onAdd = () => {
    props.onSubmit();
  };

  let btnStyle = props?.buttonView ? props.buttonView : styles.buttonView;
  let bgColor = props?.bgColorStyleView ? props?.bgColorStyleView : THEME_COLOR2;
  if (props?.btnType === 'cancel') {
    btnStyle = styles.cancelButtonView;
    bgColor = '#f0efef'
  } else if (props?.btnType === 'btn2') {
    btnStyle = styles.buttonView2;
    bgColor = '#f0efef'
  } else if (props?.btnType === 'btn3') {
    btnStyle = styles.buttonView3;
    bgColor = THEME_COLOR7
  } else if (props?.btnType === 'btn4') {
    btnStyle = styles.buttonView4;
    bgColor = THEME_COLOR8
  } else if (props?.btnType === 'btn5') {
    btnStyle = styles.buttonView5;
    bgColor = THEME_COLOR5
  } else if (props?.btnType === 'btn6') {
    btnStyle = styles.buttonView6;
    bgColor = THEME_COLOR9
  } else if (props?.btnType === 'btn7') {
    btnStyle = styles.buttonView7;
    bgColor = '#fff'
  } else if (props?.btnType === 'btn8') {
    btnStyle = styles.buttonView8;
    bgColor = '#000';
  } else if (props?.btnType === 'btn9') {
    btnStyle = styles.buttonView9;
    bgColor = THEME_COLOR10;
  }

  return (
    <Button
      disableElevation={true}
      disabled={props?.loading}
      sx={[btnStyle, styles.btnView, props?.btnStyleView, {
        ':hover': {
          backgroundColor: bgColor,
        },
        width: props?.width ? props?.width : 'unset',
      }]}
      onClick={() => onAdd()}
      variant="contained"
      size="medium">
      {props?.loading ? 'Please Wait' : props.title}
    </Button>
  )
};

export default FormButton;

const styles = {
  buttonView: {
    backgroundColor: THEME_COLOR2,
    color: '#FFF',
  },
  cancelButtonView: {
    backgroundColor: THEME_COLOR3,
    color: THEME_COLOR4,
  },
  buttonView2: {
    backgroundColor: '#f0efef',
    color: THEME_COLOR4,
  },
  buttonView3: {
    backgroundColor: THEME_COLOR7,
    color: '#000',
    border: '1px solid #aaa',
  },
  buttonView4: {
    backgroundColor: THEME_COLOR8,
    color: '#000',
    border: '1px solid #aaa',
  },
  buttonView5: {
    backgroundColor: THEME_COLOR5,
    color: '#000',
    border: '1px solid #aaa',
  },
  buttonView6: {
    backgroundColor: THEME_COLOR9,
    color: '#000',
    border: '1px solid #aaa',
  },
  btnView: {
    fontFamily: 'InterBold',
    fontSize: 12,
    textTransform: 'capitalize'
  },
  buttonView7: {
    backgroundColor: '#fff',
    color: THEME_COLOR2,
    border: '1px solid #aaa',
  },
  buttonView8: {
    backgroundColor: '#000',
    color: '#fff',
  },
  buttonView9: {
    backgroundColor: THEME_COLOR10,
    color: '#fff',
  }
};
