import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField} from '@mui/material';

import styles from "./ReviewShipping.module.css"

const ReviewShipping = (props) => {

  return (

    <Grid container item md={12} xs={12} className={styles.addressMainView} p={3}
          mt={1}>
      <Grid item md={12} xs={12} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>Mode of Shipping</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.shipping_details?.shipping_mode}</Typography>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} mt={2} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>Incoterm</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.shipping_details?.incoterm}</Typography>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} mt={2} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>Type of Container</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>{props?.data?.shipping_details?.container_type}</Typography>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12} mt={2} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>
        <Grid item md={6} xs={6}>
          <Typography className={styles.labelText}>Pre-shipment Inspection</Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography className={styles.valueText}>
            {props?.data?.shipping_details?.sgs_preshipment_inspection == 1 ? 'Yes' : 'No'}
          </Typography>
        </Grid>
      </Grid>

    </Grid>

  )
};

export default ReviewShipping;
