//login
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

//seller
export const LIST_VENDOR = 'LIST_VENDOR';
export const LIST_VENDOR_SUCCESS = 'LIST_VENDOR_SUCCESS';
export const LIST_VENDOR_FAIL = 'LIST_VENDOR_FAIL';
export const GET_SELLER_DETAIL = 'GET_SELLER_DETAIL';
export const GET_SELLER_DETAIL_SUCCESS = 'GET_SELLER_DETAIL_SUCCESS';
export const GET_SELLER_DETAIL_FAIL = 'GET_SELLER_DETAIL_FAIL';
export const UPDATE_SELLER_STATUS = 'UPDATE_SELLER_STATUS';
export const UPDATE_SELLER_STATUS_SUCCESS = 'UPDATE_SELLER_STATUS_SUCCESS';
export const UPDATE_SELLER_STATUS_FAIL = 'UPDATE_SELLER_STATUS_FAIL';

//product
export const LIST_PRODUCT_CATEGORY = 'LIST_PRODUCT_CATEGORY';
export const LIST_PRODUCT_CATEGORY_SUCCESS = 'LIST_PRODUCT_CATEGORY_SUCCESS';
export const LIST_PRODUCT_CATEGORY_FAIL = 'LIST_PRODUCT_CATEGORY_FAIL';
export const LIST_PRODUCT = 'LIST_PRODUCT';
export const LIST_PRODUCT_SUCCESS = 'LIST_PRODUCT_SUCCESS';
export const LIST_PRODUCT_FAIL = 'LIST_PRODUCT_FAIL';
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAIL = 'GET_PRODUCT_DETAIL_FAIL';
export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS';
export const UPDATE_PRODUCT_STATUS_SUCCESS = 'UPDATE_PRODUCT_STATUS_SUCCESS';
export const UPDATE_PRODUCT_STATUS_FAIL = 'UPDATE_PRODUCT_STATUS_FAIL';
export const ADD_PRODUCT_IMAGE = 'ADD_PRODUCT_IMAGE';
export const ADD_PRODUCT_IMAGE_SUCCESS = 'ADD_PRODUCT_IMAGE_SUCCESS';
export const ADD_PRODUCT_IMAGE_FAIL = 'ADD_PRODUCT_IMAGE_FAIL';
export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE';
export const DELETE_PRODUCT_IMAGE_SUCCESS = 'DELETE_PRODUCT_IMAGE_SUCCESS';
export const DELETE_PRODUCT_IMAGE_FAIL = 'DELETE_PRODUCT_IMAGE_FAIL';
export const UPDATE_MAIN_PRODUCT_IMAGE = 'UPDATE_MAIN_PRODUCT_IMAGE';
export const UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS = 'UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS';
export const UPDATE_MAIN_PRODUCT_IMAGE_FAIL = 'UPDATE_MAIN_PRODUCT_IMAGE_FAIL';
export const GET_ALL_CATEGORY_LIST = 'GET_ALL_CATEGORY_LIST';
export const GET_ALL_CATEGORY_LIST_SUCCESS = 'GET_ALL_CATEGORY_LIST_SUCCESS';
export const GET_ALL_CATEGORY_LIST_FAIL = 'GET_ALL_CATEGORY_LIST_FAIL';

//category
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL';
export const LIST_CATEGORY = 'LIST_CATEGORY';
export const LIST_CATEGORY_SUCCESS = 'LIST_CATEGORY_SUCCESS';
export const LIST_CATEGORY_FAIL = 'LIST_CATEGORY_FAIL';
export const UPDATE_CATEGORY_FEATURED = 'UPDATE_CATEGORY_FEATURED';
export const UPDATE_CATEGORY_FEATURED_SUCCESS = 'UPDATE_CATEGORY_FEATURED_SUCCESS';
export const UPDATE_CATEGORY_FEATURED_FAIL = 'UPDATE_CATEGORY_FEATURED_FAIL';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

//sub category
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS';
export const ADD_SUB_CATEGORY_FAIL = 'ADD_SUB_CATEGORY_FAIL';
export const LIST_SUB_CATEGORY = 'LIST_SUB_CATEGORY';
export const LIST_SUB_CATEGORY_SUCCESS = 'LIST_SUB_CATEGORY_SUCCESS';
export const LIST_SUB_CATEGORY_FAIL = 'LIST_SUB_CATEGORY_FAIL';
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS';
export const DELETE_SUB_CATEGORY_FAIL = 'DELETE_SUB_CATEGORY_FAIL';

//customer
export const LIST_CUSTOMER = 'LIST_CUSTOMER';
export const LIST_CUSTOMER_SUCCESS = 'LIST_CUSTOMER_SUCCESS';
export const LIST_CUSTOMER_FAIL = 'LIST_CUSTOMER_FAIL';
export const GET_CUSTOMER_DETAIL = 'GET_CUSTOMER_DETAIL';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_DETAIL_FAIL = 'GET_CUSTOMER_DETAIL_FAIL';
export const UPDATE_CUSTOMER_STATUS = 'UPDATE_CUSTOMER_STATUS';
export const UPDATE_CUSTOMER_STATUS_SUCCESS = 'UPDATE_CUSTOMER_STATUS_SUCCESS';
export const UPDATE_CUSTOMER_STATUS_FAIL = 'UPDATE_CUSTOMER_STATUS_FAIL';

//dashboard
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';

//cart
export const LIST_CART_REVIEW_PENDING = 'LIST_CART_REVIEW_PENDING';
export const LIST_CART_REVIEW_PENDING_SUCCESS = 'LIST_CART_REVIEW_PENDING_SUCCESS';
export const LIST_CART_REVIEW_PENDING_FAIL = 'LIST_CART_REVIEW_PENDING_FAIL';
export const LIST_CART_SHIPPING_COST_PENDING = 'LIST_CART_SHIPPING_COST_PENDING';
export const LIST_CART_SHIPPING_COST_PENDING_SUCCESS = 'LIST_CART_SHIPPING_COST_PENDING_SUCCESS';
export const LIST_CART_SHIPPING_COST_PENDING_FAIL = 'LIST_CART_SHIPPING_COST_PENDING_FAIL';
export const LIST_CART_CHECKOUT_PENDING = 'LIST_CART_CHECKOUT_PENDING';
export const LIST_CART_CHECKOUT_PENDING_SUCCESS = 'LIST_CART_CHECKOUT_PENDING_SUCCESS';
export const LIST_CART_CHECKOUT_PENDING_FAIL = 'LIST_CART_CHECKOUT_PENDING_FAIL';
export const DELETE_CART = 'DELETE_CART';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';
export const DELETE_CART_FAIL = 'DELETE_CART_FAIL';
export const GET_CART_DETAIL = 'GET_CART_DETAIL';
export const GET_CART_DETAIL_SUCCESS = 'GET_CART_DETAIL_SUCCESS';
export const GET_CART_DETAIL_FAIL = 'GET_CART_DETAIL_FAIL';
export const ADD_CART_SHIPPING_COST = 'ADD_CART_SHIPPING_COST';
export const ADD_CART_SHIPPING_COST_SUCCESS = 'ADD_CART_SHIPPING_COST_SUCCESS';
export const ADD_CART_SHIPPING_COST_FAIL = 'ADD_CART_SHIPPING_COST_FAIL';
export const GET_CART_COUNT = 'GET_CART_COUNT';
export const GET_CART_COUNT_SUCCESS = 'GET_CART_COUNT_SUCCESS';
export const GET_CART_COUNT_FAIL = 'GET_CART_COUNT_FAIL';

//orders
export const LIST_ORDER = 'LIST_ORDER';
export const LIST_ORDER_SUCCESS = 'LIST_ORDER_SUCCESS';
export const LIST_ORDER_FAIL = 'LIST_ORDER_FAIL';
export const ADD_ORDER_BOOKING_DETAILS = 'ADD_ORDER_BOOKING_DETAILS';
export const ADD_ORDER_BOOKING_DETAILS_SUCCESS = 'ADD_ORDER_BOOKING_DETAILS_SUCCESS';
export const ADD_ORDER_BOOKING_DETAILS_FAIL = 'ADD_ORDER_BOOKING_DETAILS_FAIL';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAIL = 'GET_ORDER_DETAIL_FAIL';
export const CONFIRM_PENDING_ORDER = 'CONFIRM_PENDING_ORDER';
export const CONFIRM_PENDING_ORDER_SUCCESS = 'CONFIRM_PENDING_ORDER_SUCCESS';
export const CONFIRM_PENDING_ORDER_FAIL = 'CONFIRM_PENDING_ORDER_FAIL';
export const UPLOAD_ORDER_DOCUMENTS = 'UPLOAD_ORDER_DOCUMENTS';
export const UPLOAD_ORDER_DOCUMENTS_SUCCESS = 'UPLOAD_ORDER_DOCUMENTS_SUCCESS';
export const UPLOAD_ORDER_DOCUMENTS_FAIL = 'UPLOAD_ORDER_DOCUMENTS_FAIL';
export const CONFIRM_ORDER_PAYMENT = 'CONFIRM_ORDER_PAYMENT';
export const CONFIRM_ORDER_PAYMENT_SUCCESS = 'CONFIRM_ORDER_PAYMENT_SUCCESS';
export const CONFIRM_ORDER_PAYMENT_FAIL = 'CONFIRM_ORDER_PAYMENT_FAIL';
export const UPLOAD_ORDER_TRACK = 'UPLOAD_ORDER_TRACK';
export const UPLOAD_ORDER_TRACK_SUCCESS = 'UPLOAD_ORDER_TRACK_SUCCESS';
export const UPLOAD_ORDER_TRACK_FAIL = 'UPLOAD_ORDER_TRACK_FAIL';
export const CONFIRM_ORDER_DELIVERY = 'CONFIRM_ORDER_DELIVERY';
export const CONFIRM_ORDER_DELIVERY_SUCCESS = 'CONFIRM_ORDER_DELIVERY_SUCCESS';
export const CONFIRM_ORDER_DELIVERY_FAIL = 'CONFIRM_ORDER_DELIVERY_FAIL';
export const GET_ORDER_COUNT = 'GET_ORDER_COUNT';
export const GET_ORDER_COUNT_SUCCESS = 'GET_ORDER_COUNT_SUCCESS';
export const GET_ORDER_COUNT_FAIL = 'GET_ORDER_COUNT_FAIL';
export const MESSAGE_TO_SELLER = 'MESSAGE_TO_SELLER';
export const MESSAGE_TO_SELLER_SUCCESS = 'MESSAGE_TO_SELLER_SUCCESS';
export const MESSAGE_TO_SELLER_FAIL = 'MESSAGE_TO_SELLER_FAIL';

//beneficiary details
export const GET_BENEFICIARY_DETAILS = 'GET_BENEFICIARY_DETAILS';
export const GET_BENEFICIARY_DETAILS_SUCCESS = 'GET_BENEFICIARY_DETAILS_SUCCESS';
export const GET_BENEFICIARY_DETAILS_FAIL = 'GET_BENEFICIARY_DETAILS_FAIL';
export const ADD_BENEFICIARY_DETAILS = 'ADD_BENEFICIARY_DETAILS';
export const ADD_BENEFICIARY_DETAILS_SUCCESS = 'ADD_BENEFICIARY_DETAILS_SUCCESS';
export const ADD_BENEFICIARY_DETAILS_FAIL = 'ADD_BENEFICIARY_DETAILS_FAIL';
export const UPDATE_BENEFICIARY_DETAILS = 'UPDATE_BENEFICIARY_DETAILS';
export const UPDATE_BENEFICIARY_DETAILS_SUCCESS = 'UPDATE_BENEFICIARY_DETAILS_SUCCESS';
export const UPDATE_BENEFICIARY_DETAILS_FAIL = 'UPDATE_BENEFICIARY_DETAILS_FAIL';

export const GET_CURRENCY_RATE_LIST = 'GET_CURRENCY_RATE_LIST';
export const GET_CURRENCY_RATE_LIST_SUCCESS = 'GET_CURRENCY_RATE_LIST_SUCCESS';
export const GET_CURRENCY_RATE_LIST_FAIL = 'GET_CURRENCY_RATE_LIST_FAIL';

export const UPDATE_LIST_LOADING = 'UPDATE_LIST_LOADING';
export const ALERT_MSG_EMPTY = 'ALERT_MSG_EMPTY';
export const UPDATE_ALERT_MSG = 'UPDATE_ALERT_MSG';
export const UPDATE_API_STATUS = 'UPDATE_API_STATUS';
export const LOG_OUT = 'LOG_OUT';
