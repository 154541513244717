import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Typography} from "@mui/material";

import Container from "../../components/Container";
import Page from '../../components/Page';
import AlertDialogBox from "../../components/AlertDialogBox";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import FormSelectBox from "../../components/FormSelectBox";
import FormCountrySelectBox from "../../components/FormCountrySelectBox";

import {connect} from "react-redux";
import {listCustomer, updateCustomerStatus} from "../../redux/actions/userDataActions";

import {ReactComponent as TickIcon} from "../../assets/img/tick.svg"
import {ReactComponent as DeclineIcon} from "../../assets/img/decline.svg"

import {THEME_COLOR2, THEME_COLOR8, THEME_COLOR9} from "../../constants";
import {COUNTRIES} from "../../constants/countries";

import styles from './Customers.module.css'

const buttonList = {
  approved: {
    icon: <TickIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Approve?',
    firstBtn: 'Yes, Approve!',
    secondBtn: 'No, cancel',
  },
  suspended: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Suspend?',
    firstBtn: 'Yes, Suspend!',
    secondBtn: 'No, cancel',
  },
  active: {
    icon: <TickIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Activate?',
    firstBtn: 'Yes, Activate!',
    secondBtn: 'No, cancel',
  },
};

const status_list = {
  pending: {
    color: THEME_COLOR9,
  },
  pending_verification: {
    color: THEME_COLOR8,
  },
  active: {
    color: THEME_COLOR2,
  },
  suspended: {
    color: THEME_COLOR9,
  }
}

const accountStatusList = [
  {
    value: 'pending',
    label: 'Pending'
  },
  {
    value: 'pending_verification',
    label: 'Pending Verification'
  },
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'suspended',
    label: 'Suspended'
  }
]

const Customers = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [type, setType] = useState('');
  const [customerUid, setCustomerUid] = useState('');
  const [searchAgbin, setSearchAgbin] = useState('');
  const [country, setCountry] = useState(null);
  const [accountStatus, setAccountStatus] = useState('');
  const [filterData, setFilterData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let customer_status = params.get('status');
    if (customer_status) {
      setAccountStatus(customer_status);
      let filter = {...filterData}
      filter['accountStatus'] = customer_status;
      setFilterData(filter);
    }
  }, [])

  useEffect(() => {
    getCustomerList(1);
  }, [filterData]);

  const getCustomerList = (page) => {
    let data = {
      page: page,
    };
    if (filterData != null) {
      data = {
        page: page,
        searchAgbin: filterData?.searchAgbin ? filterData?.searchAgbin : '',
        country: (filterData?.country) ? filterData?.country : '',
        accountStatus: (filterData?.accountStatus) ? filterData?.accountStatus : ''
      };
    }
    props.listCustomer(JSON.stringify(data))
    setPageNum(page)
  };

  const onViewDetails = (uid) => {
    navigate("/customer_detail?id=" + uid)
  };

  const onConfirmClick = () => {
    let isRemoveRow = false;
    if (filterData != null) {
      if (filterData?.accountStatus) {
        isRemoveRow = true
      }
    }
    let data = {
      userUid: customerUid,
      status: type
    };
    props.updateCustomerStatus(JSON.stringify(data), isRemoveRow);
    setConfirmBoxVisible(false);
    setTimeout(() => {
      setType("");
      setCustomerUid('')
    }, 500);
  };

  const onCancelClick = () => {
    setConfirmBoxVisible(false);
    setTimeout(() => {
      setType("");
      setCustomerUid('')
    }, 500);
  };

  const onActionOptionClick = (type, uid) => {
    setType(type);
    setConfirmBoxVisible(true);
    setCustomerUid(uid);
  };

  const onSelectPage = (page) => {
    getCustomerList(page);
  };

  const onSearchBtnClick = () => {
    let filter = {...filterData}
    filter['searchAgbin'] = searchAgbin;
    setFilterData(filter);
  };

  const onRefreshBtnClick = () => {
    let filter = {...filterData}
    filter['country'] = country?.code;
    filter['accountStatus'] = accountStatus;
    setFilterData(filter);
  };

  return (
    <Container page={"customers"}>
      <Grid item md={12} xs={12}
            container
            display={"flex"}
            alignItems={"center"}>
        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
          <Typography className={styles.customerText} mb={2}>Buyers</Typography>
        </Grid>

        <Grid container item md={12} xs={12} className={styles.borderBottomView}/>

        <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={2}>
          <Grid item md={7} xs={7}/>
          <Grid item md={1} xs={1}
                className={styles.agbinBoxView}
                display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography className={styles.agbinText}>AGBIN</Typography>
          </Grid>
          <Grid item md={3} xs={3} pl={1}>
            <FormTextInput
              page={'Customers'}
              value={searchAgbin}
              setValue={setSearchAgbin}
              textInputViewStyle={style.textInputViewStyle}
            />
          </Grid>
          <Grid item md={1} xs={1} pl={1}>
            <FormButton
              btnType={'btn9'}
              width={'100%'}
              title={'Search'}
              onSubmit={onSearchBtnClick}
            />
          </Grid>
        </Grid>

        <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={4}>
          <Grid item md={1.5} xs={1.5} className={styles.countView} display={'flex'} flexDirection={'row'}
                alignItems={'center'} justifyContent={'center'} px={1}>
            <Typography
              className={styles.countText}>{props?.customerTotalCount ? props?.customerTotalCount : 0}</Typography>
            <Typography className={styles.countTitleText} pl={1}>
              {props?.customerTotalCount > 1 ? 'Buyers' : 'Buyer'}
            </Typography>
          </Grid>
          <Grid item md={3.5} xs={3.5}/>
          <Grid item md={3} xs={3}>
            <FormCountrySelectBox
              page={'Customers'}
              type={"code"}
              value={country}
              setValue={setCountry}
              textInputViewStyle={style.textInputViewStyle}
              placeholder={'Country'}
            />
          </Grid>
          <Grid item md={3} xs={3} pl={1}>
            <FormSelectBox
              page={'Customers'}
              value={accountStatus}
              setValue={setAccountStatus}
              placeholderColorOpacity={'1'}
              textInputView={style.textInputViewStyle}
              data={accountStatusList}
              placeholder={'Account Status'}
            />
          </Grid>
          <Grid item md={1} xs={1} pl={1}>
            <FormButton
              btnType={'btn8'}
              width={'100%'}
              title={'Refresh'}
              onSubmit={onRefreshBtnClick}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              mt={3}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sl.No</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Buyer</TableCell>
                  <TableCell>TGBIN</TableCell>
                  <TableCell>Account Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.customerList.length > 0 ?
                  props.customerList.map((item, index) => (
                    <TableRow
                      key={index}>
                      <TableCell>{parseInt(index) + 1}</TableCell>
                      <TableCell>{item?.country ? COUNTRIES.filter(
                        (x) => x.code == item?.country
                      )?.[0]?.label : '-'}</TableCell>
                      <TableCell>{item?.name_entity ? item.name_entity : '-'}</TableCell>
                      <TableCell>{item?.tgbin ? item.tgbin : '-'}</TableCell>
                      <TableCell>
                        <Box>
                          <Typography
                            sx={[style.statusText, status_list[item.status]]}>{item?.status?.replaceAll("_", " ")}</Typography>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <FormButton
                          btnType={'btn2'}
                          width={'70%'}
                          title={'View'}
                          onSubmit={() => onViewDetails(item.uid)}/>
                        {item?.status == 'pending_verification' ?
                          <Box mt={1}>
                            <FormButton
                              title={'Approve'}
                              btnType={'btn4'}
                              width={'70%'}
                              onSubmit={() => onActionOptionClick('approved', item.uid)}
                            />
                          </Box>
                          : null}
                        {item?.status != 'suspended' && (item?.status != 'pending') ?
                          <Box mt={1}>
                            <FormButton
                              title={'Suspend'}
                              btnType={'btn6'}
                              width={'70%'}
                              onSubmit={() => onActionOptionClick('suspended', item.uid)}
                            />
                          </Box>
                          : null}
                        {item?.status == 'suspended' ?
                          <Box mt={1}>
                            <FormButton
                              title={'Activate'}
                              btnType={'btn3'}
                              width={'70%'}
                              onSubmit={() => onActionOptionClick('active', item.uid)}
                            />
                          </Box>
                          : null}
                      </TableCell>

                    </TableRow>
                  )) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
            {props.customerTotalPages > 0 ?
              <Page
                totalPages={props.customerTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}/>
              : null}
          </Box>
        </Grid>
      </Grid>

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={type}
        icon={type != '' ? buttonList[type]['icon'] : ''}
        content={type != '' ? buttonList[type]['content'] : ''}
        firstBtn={type != '' ? buttonList[type]['firstBtn'] : ''}
        secondBtn={type != '' ? buttonList[type]['secondBtn'] : ''}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    customerTotalPages: state.userData.customerTotalPages,
    customerList: state.userData.customerList,
    customerTotalCount: state.userData.customerTotalCount,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listCustomer: data => dispatch(listCustomer(data)),
    updateCustomerStatus: (data, isRemoveRow) => dispatch(updateCustomerStatus(data, isRemoveRow)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);

const style = {
  statusText: {
    fontFamily: 'InterBold',
    fontSize: 13,
    textTransform: 'capitalize'
  },
  textInputViewStyle: {
    marginTop: 0
  },
}
