import React, {useEffect, useState} from 'react'
import {Grid, Typography, Box} from '@mui/material';
import dayjs from "dayjs";
import {v4 as uuidv4} from "uuid";
import {useNavigate, useLocation} from 'react-router-dom'

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import FormDatePicker from "../../components/FormDatePicker";
import FormTimePicker from "../../components/FormTimePicker";
import UploadDocument from "../../components/UploadDocument";

import {connect} from "react-redux";
import {updateApiStatus, addOrderBookingDetails, getOrderDetail} from "../../redux/actions/userDataActions";

import styles from './BookingDetails.module.css'

const BookingDetails = (props) => {
  const [transportationBookingNumber, setTransportationBookingNumber] = useState('');
  const [containerId, setContainerId] = useState('');
  const [pickupDate, setPickupDate] = useState(dayjs());
  const [pickupTime, setPickupTime] = useState(dayjs());
  const [preshipmentInspectionPlace, setPreshipmentInspectionPlace] = useState('');
  const [preshipmentInspectionDate, setPreshipmentInspectionDate] = useState(dayjs());
  const [preshipmentInspectionTime, setPreshipmentInspectionTime] = useState(dayjs());
  const [document, setDocument] = useState([{id: uuidv4()}]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    let order_uid = location.pathname.split("/").pop()
    let data = {
      orderUid: order_uid,
    };
    props.getOrderDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (props.apiStatus == 'ADD_ORDER_BOOKING_DETAILS_SUCCESS') {
      props.updateApiStatus('')
      navigate('/orders')
    }
  }, [props?.apiStatus])

  const onRemoveImage = (id) => {
    let document_list = [...document];
    let findIndex = document_list.findIndex((item) => item?.id == id);
    if (findIndex >= 0) {
      document_list[findIndex] = {
        id: id,
        name: document_list[findIndex]?.name
      }
    }
    setDocument(document_list)
  };

  const onChangeImage = (id, fileList) => {
    let document_list = [...document];
    let findIndex = document_list.findIndex((item) => item?.id == id);
    if (findIndex >= 0) {
      document_list[findIndex] = {
        ...document_list[findIndex],
        image: fileList[0].file,
        display_image: fileList[0].image,
      }
    }
    setDocument(document_list)
  };

  const onSetDocumentName = (id, val) => {
    let document_list = [...document];
    let findIndex = document_list.findIndex((item) => item?.id == id);
    if (findIndex >= 0) {
      document_list[findIndex] = {
        ...document_list[findIndex],
        name: val
      }
    }
    setDocument(document_list)
  };

  const onAddMoreClick = () => {
    setDocument([...document, {id: uuidv4()}])
    // let errorText = {};
    // if (documentName == '') {
    //   errorText = {documentName: 'Document Name is required'};
    // }
    // if (documentImage == null) {
    //   errorText = {...errorText, documentImage: 'Document is required'};
    // }
    // if (Object.keys(errorText).length != 0) {
    //   setError(errorText);
    //   return;
    // } else {
    //   setError(null)
    // }
    //
    // let document_list = [...document];
    // let data = {
    //   id: uuidv4(),
    //   document_name: documentName,
    //   document_image: documentImage,
    //   display_document_image: displayDocumentImage,
    // }
    //
    // document_list.push(data);
    // setDocument(document_list);
  };

  const onUploadBookingClick = () => {
    let errorText = {};
    if (transportationBookingNumber == '') {
      errorText = {transportationBookingNumber: 'Transportation Booking Number is required'};
    }
    if (containerId == '') {
      errorText = {...errorText, containerId: 'Container ID is required'};
    }
    if (pickupDate == '' || pickupDate == null) {
      errorText = {...errorText, pickupDate: 'Pickup Date is required'};
    }
    if (pickupTime == '' || pickupTime == null) {
      errorText = {...errorText, pickupTime: 'Pickup Time is required'};
    }
    if(props?.orderDetail?.sgs_preshipment_inspection == 1) {
      if (preshipmentInspectionPlace == '') {
        errorText = {...errorText, preshipmentInspectionPlace: 'Place is required'};
      }
      if (preshipmentInspectionDate == '' || preshipmentInspectionDate == null) {
        errorText = {...errorText, preshipmentInspectionDate: 'Date is required'};
      }
      if (preshipmentInspectionTime == '' || preshipmentInspectionTime == null) {
        errorText = {...errorText, preshipmentInspectionTime: 'Time is required'};
      }
    }
    if (document.length == 0) {
      errorText = {...errorText, document: 'Documents is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    } else {
      setError(null)
    }

    let document_list = [];
    let doc_err = 0;
    document.map((x) => {
      let item = x;
      let err = {};
      if ((typeof item?.name == 'undefined') || item?.name == "") {
        err = {
          name: "Required",
        };
        doc_err++;
      }
      if ((typeof item?.image == 'undefined') || item?.image == null) {
        err = {
          ...err,
          image: "Required",
        };
        doc_err++;
      }
      document_list.push({...item, error: err});
    });

    if (doc_err > 0) {
      setDocument(document_list);
      return;
    }

    let document_name = [];
    let document_image = [];
    document.map((item, index) => {
      document_name.push(item?.name);
      document_image.push(item?.image);
    });

    let formData = new FormData();
    let orderUid = location.pathname.split("/").pop()
    formData.append('orderUid', orderUid);
    formData.append('transportationBookingNumber', transportationBookingNumber);
    formData.append('containerId', containerId);
    formData.append('pickupDate', dayjs(pickupDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    formData.append('pickupTime', dayjs(pickupTime, 'HH:mm A').format('HHmm'));
    if(props?.orderDetail?.sgs_preshipment_inspection == 1) {
      formData.append('preshipmentInspectionPlace', preshipmentInspectionPlace);
      formData.append('preshipmentInspectionDate', dayjs(preshipmentInspectionDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
      formData.append('preshipmentInspectionTime', dayjs(preshipmentInspectionTime, 'HH:mm A').format('HHmm'));
    }
    if (document_name.length > 0) {
      formData.append('documentName', JSON.stringify(document_name));
    }
    if (document_image.length > 0) {
      document_image.forEach(y => {
        formData.append("documentImage", y);
      });
    }

    props.addOrderBookingDetails(formData)
  }

  const onRemoveDocument = (id) => {
    setDocument([...document].filter(x => x.id != id));
  }

  return (
    <Container page={"BookingDetails"}>
      <Grid item md={12} xs={12}
            container
            display={"flex"}
            alignItems={"center"}
            className={styles.cardDiv} p={2}>

        <Grid item md={12} xs={12}>
          <Typography className={styles.bookingDetailText}>Booking Details</Typography>
        </Grid>

        <Grid container item md={12} xs={12}
              display={'flex'} flexDirection={'row'} alignItems={'center'}
              justifyContent={'space-between'}
              mt={2}>
          <Grid container item md={5.8} xs={12}>
            <FormTextInput
              page={'BookingDetails'}
              error={error?.transportationBookingNumber}
              value={transportationBookingNumber}
              setValue={setTransportationBookingNumber}
              label={'Transportation Booking Number'}
              textInputViewStyle={style.textInputViewStyle}
              required={true}
            />
          </Grid>

          <Grid container item md={5.8} xs={12}>
            <FormTextInput
              page={'BookingDetails'}
              error={error?.containerId}
              value={containerId}
              setValue={setContainerId}
              label={'Container ID (in case of sea freight)'}
              textInputViewStyle={style.textInputViewStyle}
              required={true}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>

        <Grid item md={12} xs={12} mt={4}>
          <Typography className={styles.pickupScheduleText}>Pickup Schedule</Typography>
        </Grid>

        <Grid container item md={12} xs={12}
              display={'flex'} flexDirection={'row'} alignItems={'center'}
              justifyContent={'space-between'}
              mt={2}>
          <Grid container item md={5.8} xs={12}>
            <FormDatePicker
              page={"BookingDetails"}
              value={pickupDate}
              minDate={dayjs()}
              label={'Pickup Date'}
              inputFormat="DD-MM-YYYY"
              onChange={(newValue) => {
                setPickupDate(newValue);
              }}
              error={error?.pickupDate}
              required={true}
            />
          </Grid>

          <Grid container item md={5.8} xs={12}>
            <FormTimePicker
              page={"BookingDetails"}
              value={pickupTime}
              label={'Pickup Time'}
              inputFormat="hh:mm A"
              onChange={(newValue) => {
                setPickupTime(newValue);
              }}
              error={error?.pickupTime}
              required={true}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>


        {props?.orderDetail?.sgs_preshipment_inspection == 1 ?
          <>
            <Grid item md={12} xs={12} mt={4}>
              <Typography className={styles.preshipmentInspectionText}>Pre-Shipment Inspection Schedule</Typography>
            </Grid>

            <Grid container item md={12} xs={12}
                  display={'flex'} flexDirection={'row'} alignItems={'center'}
                  justifyContent={'space-between'}
                  mt={2}>

              <Grid container item md={12} xs={12}>
                <FormTextInput
                  page={'BookingDetails'}
                  error={error?.preshipmentInspectionPlace}
                  value={preshipmentInspectionPlace}
                  setValue={setPreshipmentInspectionPlace}
                  label={'Place'}
                  placeholder={'Loading Location'}
                  textInputViewStyle={style.textInputViewStyle}
                  required={true}
                />
              </Grid>

              <Grid container item md={5.8} xs={12} mt={2}>
                <FormDatePicker
                  page={"BookingDetails"}
                  value={preshipmentInspectionDate}
                  minDate={dayjs()}
                  label={'Date'}
                  inputFormat="DD-MM-YYYY"
                  onChange={(newValue) => {
                    setPreshipmentInspectionDate(newValue);
                  }}
                  error={error?.preshipmentInspectionDate}
                  required={true}
                />
              </Grid>

              <Grid container item md={5.8} xs={12} mt={2}>
                <FormTimePicker
                  page={"BookingDetails"}
                  value={preshipmentInspectionTime}
                  label={'Time'}
                  inputFormat="hh:mm A"
                  onChange={(newValue) => {
                    setPreshipmentInspectionTime(newValue);
                  }}
                  error={error?.preshipmentInspectionTime}
                  required={true}
                />
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>
          </>
          : null}

        <Grid item md={12} xs={12} mt={4}>
          <Typography className={styles.uploadDocumentText}>Upload Document</Typography>
        </Grid>

        <Grid container item md={12} xs={12}
              display={'flex'} flexDirection={'row'} alignItems={'center'}
              justifyContent={'space-between'}
              mt={2}>
          <Grid container item md={5} xs={5}>
            <Grid display={"flex"}
                  flexDirection={"row"}
                  alignItems={'flex-start'}
                  item md={12} xs={12}
                  container>
              <Typography className={styles.labelText}>Document Name</Typography>
              <Box pl={1}>
                <Typography className={styles.requiredText}>*</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={3} xs={3}>
            <Grid display={"flex"}
                  flexDirection={"row"}
                  alignItems={'flex-start'}
                  item md={12} xs={12}
                  container>
              <Typography className={styles.labelText}>File</Typography>
              <Box pl={1}>
                <Typography className={styles.requiredText}>*</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={3} xs={3}>
            <Typography className={styles.labelText}>Action</Typography>
          </Grid>
        </Grid>

        {document.map((item, index) => {
          return (
            <UploadDocument
              key={item?.id}
              documentId={item?.id}
              documentName={item?.name}
              setDocumentName={onSetDocumentName}
              displayDocumentImage={item?.display_image}
              errorDocumentName={item?.error?.name}
              errorDocumentImage={item?.error?.image}
              onRemoveImage={onRemoveImage}
              onChangeImage={onChangeImage}
              onRemoveDocument={onRemoveDocument}
            />
          )
        })}

        {/*{document.map((item, index) => {*/}
        {/*  return (*/}
        {/*    <UploadDocument*/}
        {/*      key={index}*/}
        {/*      disabled={true}*/}
        {/*      documentName={item?.document_name}*/}
        {/*      displayDocumentImage={item?.display_document_image}*/}
        {/*    />*/}
        {/*  )*/}
        {/*})}*/}

        {/*<UploadDocument*/}
        {/*  disabled={false}*/}
        {/*  documentName={documentName}*/}
        {/*  setDocumentName={(val) => onSetDocumentName(val)}*/}
        {/*  displayDocumentImage={displayDocumentImage}*/}
        {/*  errorDocumentName={error?.documentName}*/}
        {/*  errorDocumentImage={error?.documentImage}*/}
        {/*  onRemoveImage={onRemoveImage}*/}
        {/*  onChangeImage={onChangeImage}*/}
        {/*/>*/}

        {error?.document ?
          <Grid item md={12} xs={12} mt={3}>
            <Typography className={styles.errorDocument}>{error?.document}</Typography>
          </Grid>
          : null}

        <Grid item md={12} xs={12} mt={3}>
          <Typography className={styles.addMoreText} onClick={() => onAddMoreClick()}>Add more</Typography>
        </Grid>

        <Grid item md={12} xs={12} mt={3}
              container
              display={'flex'} justifyContent={'flex-end'}>
          <FormButton
            title={"Upload"}
            loading={props?.btnLoading}
            btnStyleView={{height: 35}}
            onSubmit={onUploadBookingClick}
          />
        </Grid>
      </Grid>

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
    btnLoading: state.userData.btnLoading,
    orderDetail: state.userData.orderDetail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    addOrderBookingDetails: data => dispatch(addOrderBookingDetails(data)),
    getOrderDetail: data => dispatch(getOrderDetail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);


const style = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
};
