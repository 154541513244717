import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';

const FormSelectBox = (props) => {

  return (
    <Grid item md={12} xs={12}
          container>
      <Grid item md={12} xs={12}
            container display={"flex"} flexDirection={"row"}>
        <Typography sx={[styles.labelText, props?.labelTextStyle]}>{props.label}</Typography>
        {props?.required ?
          <Box pl={1}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
          : null}
      </Grid>
      <Grid item md={12} xs={12}
            container sx={[styles.textInputView, props?.textInputView ? props.textInputView : '']}>
        <FormControl style={{width: '100%'}}>
          <Select
            required={props?.required ? true : false}
            variant={props?.variant ? props?.variant : "standard"}
            value={props.value}
            onChange={e => props.setValue(e.target.value)}
            sx={{
              '& .MuiSelect-select .notranslate::after': props?.placeholder
                ? {
                  content: `"${props?.placeholder}"`,
                  opacity: props?.placeholderColorOpacity ? props?.placeholderColorOpacity : 0.42,
                }
                : {},
              ...props?.selectedItemView ? props.selectedItemView : styles.selectedItemView,
            }}
            MenuProps={{
              disableScrollLock: true,
            }}
            disableUnderline>
            {props.data.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
                style={props?.textInputValue ? props.textInputValue : styles.textInputValue}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {props.error ?
        <Box mt={1} ml={0.5} styles={styles.errorView}>
          <Typography sx={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Grid>
  )
}

export default FormSelectBox;

const styles = {
  labelText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium'
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  textInputView: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
    borderRadius: 2,
    py: 0.8,
    marginTop: 1
  },
  selectedItemView: {
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 13,
    fontFamily: 'InterMedium',
    px: 1.5,
  },
  textInputValue: {
    color: '#000',
    fontSize: 13,
    fontFamily: 'InterMedium',
    px: 1.5,
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'InterMedium'
  }
};
