import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useNavigate} from "react-router-dom";

import Page from "./Page";
import CartCheckoutPendingList from "./CartCheckoutPendingList";
import AlertDialogBox from "./AlertDialogBox";

import {connect} from "react-redux";
import {
  deleteCart,
  listCartCheckoutPending, updateApiStatus,
} from "../redux/actions/userDataActions";

import styles from './CartCheckoutPending.module.css'

import {ReactComponent as DeclineIcon} from "../assets/img/decline.svg"
import CartReviewPendingList from "./CartReviewPendingList";
import {CircularProgress} from "@mui/material";

const buttonList = {
  removed: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  }
};

const CartCheckoutPending = (props) => {
  const [cartData, setCartData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [cartUid, setCartUid] = useState('');
  const [type, setType] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == 'DELETE_CART_SUCCESS') {
      setType('');
      setCartUid('');
      setConfirmBoxVisible(false);
      props.updateApiStatus();
    }
  }, [props.apiStatus])

  useEffect(() => {
    getCartCheckoutPendingList(1);
  }, []);

  useEffect(() => {
    setCartData(props?.cartCheckoutPendingList);
  }, [props.cartCheckoutPendingList])

  const getCartCheckoutPendingList = (page) => {
    let data = {
      page: page,
      status: 'checkout_pending'
    };
    props.listCartCheckoutPending(JSON.stringify(data))
    setPageNum(page)
  };

  const onPageSelect = (page) => {
    getCartCheckoutPendingList(page);
  };

  const onDeleteCart = (cart_uid) => {
    setType('removed');
    setCartUid(cart_uid);
    setConfirmBoxVisible(true);
  };

  const onConfirmClick = () => {
    let data = {
      cartUid: cartUid,
      currentStatus: 'checkout_pending'
    };
    props.deleteCart(JSON.stringify(data))
  };

  const onCancelClick = () => {
    setType('');
    setCartUid('');
    setConfirmBoxVisible(false);
  };

  if (props.cartLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cart ID</TableCell>
              <TableCell>Cargo ID</TableCell>
              <TableCell className={styles.dataView}>Product Details</TableCell>
              <TableCell className={styles.dataView}>Delivery Address</TableCell>
              <TableCell className={styles.dataView}>Shipping Details</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>Total Product Price</TableCell>
              <TableCell>Total Shipping Cost</TableCell>
              <TableCell>Total Cargo Cost</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartData?.length > 0 ?
              cartData?.map((item, index) => (
                <TableRow
                  className={styles.tableRow}
                  key={index}>
                  <CartCheckoutPendingList
                    key={index}
                    index={index}
                    data={item}
                    currencyRateList={props?.currencyRateList}
                    onDeleteCart={onDeleteCart}
                  />
                </TableRow>
              )) : null}
          </TableBody>
        </Table>
      </TableContainer>


        <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
          {props.cartCheckoutPendingTotalPages > 0 ?
            <Page
              totalPages={props.cartCheckoutPendingTotalPages}
              pageNum={pageNum}
              onSelectPage={onPageSelect}/>
            : null}
        </Box>

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={type}
        icon={type != '' ? buttonList[type]['icon'] : ''}
        content={type != '' ? buttonList[type]['content'] : ''}
        firstBtn={type != '' ? buttonList[type]['firstBtn'] : ''}
        secondBtn={type != '' ? buttonList[type]['secondBtn'] : ''}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cartCheckoutPendingTotalPages: state.userData.cartCheckoutPendingTotalPages,
    cartCheckoutPendingList: state.userData.cartCheckoutPendingList,
    cartLoading: state.userData.cartLoading,
    apiStatus: state.userData.apiStatus,
    currencyRateList: state.userData.currencyRateList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listCartCheckoutPending: data => dispatch(listCartCheckoutPending(data)),
    deleteCart: data => dispatch(deleteCart(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartCheckoutPending);
