import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import moment from "moment";

import styles from "./FormTimePicker.module.css";

const FormTimePicker = (props) => {
  const [timePickerOpen, setTimePickerOpen] = useState(false);

  let today = dayjs();
  let todayStartOfTheDay = null;
  // if (props?.date) {
  //   if (props?.date == moment().format("DD/MM/YYYY")) {
  //     todayStartOfTheDay = today.startOf("day");
  //     if (props.value) {
  //       if (dayjs(props.value).format("HH:mm") < dayjs().format("HH:mm")) {
  //         props.onChange(dayjs());
  //       }
  //     }
  //   }
  // }

  return (
    <Grid item md={12} xs={12} container display={"flex"}>
      {props.label ? (
        <Grid
          display={"flex"}
          flexDirection={"row"}
          item
          md={12}
          xs={12}
          container
        >
          <Typography className={styles.labelText}>{props.label}</Typography>
          {props?.required ? (
            <Box>
              <Typography className={styles.requiredText} mt={-0.3} pl={0.3}>
                *
              </Typography>
            </Box>
          ) : null}
        </Grid>
      ) : null}
      <Grid
        sx={[
          style.textInputView,
          props?.disabled
            ? style.disabledTextInputBgView
            : style.textInputBgView,
        ]}
        className={[props.error ? styles.errorBorderStyle : styles.borderStyle]}
        item
        md={12}
        xs={12}
        container
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            minTime={props?.minTime}
            maxTime={props?.maxTime}
            // defaultValue={todayStartOfTheDay}
            // disablePast={todayStartOfTheDay != null}
            value={props.value}
            // format={props.inputFormat}
            onChange={props.onChange}
            open={timePickerOpen}
            onClose={() => setTimePickerOpen(false)}
            slotProps={{
              textField: {
                variant: props?.variant ? props?.variant : "standard",
                disabled: props?.disabled ? props?.disabled : false,
                sx: {
                  "&.MuiTextField-root": {
                    width: "100%",
                  },
                  "& .MuiInput-root": {
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: 0,
                    },
                    transition: "0.2s",
                    paddingRight: 2,
                  },
                  "& .Mui-focused": {
                    border: "1px solid #5a8dee",
                    height: props?.height ? props.height : 36,
                    borderRadius: 1,
                    transition: "0.2s",
                    paddingLeft: 2,
                  },
                  input: {
                    "&::placeholder": {
                      color: "#CCC",
                      fontSize: 14,
                      fontFamily: 'InterMedium',
                      opacity: 1,
                    },
                  },
                },
                InputProps: {
                  autoComplete: "none",
                  sx: style.textInputValue,
                  endAdornment: null,
                },
                onClick: () => setTimePickerOpen(true),
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      {props.error ? (
        <Box mt={0.7} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

export default FormTimePicker;

const style = {
  textInputView: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFF",
    border: "1px solid #CCC",
    height: 36,
    borderRadius: 1,
  },
  textInputBgView: {
    backgroundColor: "#FFF",
  },
  disabledTextInputBgView: {
    backgroundColor: "#e9ecee",
  },
  textInputValue: {
    color: "#000",
    fontSize: 14,
    fontFamily: 'InterMedium',
    px: 1.5,
  },
};
