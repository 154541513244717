import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Grid, Box, Card, CardContent, Typography} from '@mui/material';

import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {loginUser} from "../../redux/actions/userDataActions";

import Logo from "../../assets/img/logo.png";
import LoginBgImg from "../../assets/img/login_bg_image.jpg"

const SignIn = (props) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const onSignIn = () => {
    let errorText = {};
    if (userName == '') {
      errorText = {userName: 'username is required'};
    }
    if (password == '') {
      errorText = {...errorText, password: 'password is required'};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      username: userName,
      password: password
    };
    props.loginUser(JSON.stringify(data))
  };

  return (
    <Grid container item md={12} xs={12}>
      <Box sx={styles.bgImgView}>
        <img alt="" src={LoginBgImg} style={styles.imgStyle}/>
      </Box>
      <Grid sx={styles.contentView}
            container item md={12} xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
        <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          {/*<Box sx={styles.imgView} mr={2}>*/}
          {/*  <img alt="" src={Logo} style={styles.imgStyle}/>*/}
          {/*</Box>*/}
          <Box>
            <h2 style={styles.mainTitleText}>Trebbs</h2>
          </Box>
        </Grid>
        <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Card sx={[styles.cardView, {
            width: {md: '70%', sm: '90%', xs: '90%'},
            minHeight: {md: '40vw', sm: '100vw', xs: '100vw'}
          }]}>
            <Typography style={styles.titleText}>Sign In</Typography>
            <Typography style={styles.subTitleText} mt={1}>Trebbs</Typography>
            <CardContent style={styles.cardContentView}>

              <Grid container item md={12} xs={12}
                    sx={styles.textInputView} mt={4}>
                <FormTextInput
                  page={'signIn'}
                  error={error?.userName}
                  value={userName}
                  setValue={setUserName}
                  placeholder={'Username'}
                  textInputViewStyle={styles.textInputViewStyle}
                  required={true}
                />
              </Grid>

              <Grid container item md={12} xs={12} mt={1}
                    sx={styles.textInputView}>
                <FormTextInput
                  page={'signIn'}
                  error={error?.password}
                  value={password}
                  setValue={setPassword}
                  placeholder={'Password'}
                  type={'password'}
                  textInputViewStyle={styles.textInputViewStyle}
                  required={true}
                />
              </Grid>

              <Grid container
                    item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mt={4}>
                <FormButton
                  loading={props.loginLoading}
                  title={'Sign In'}
                  btnStyleView={styles.btnStyleView}
                  onSubmit={onSignIn}
                />
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AlertMsg/>

    </Grid>
  )
};


const mapStateToProps = (state) => {
  return {
    loginLoading: state.userData.loginLoading,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    loginUser: data => dispatch(loginUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

const styles = {
  bgImgView: {
    position: 'fixed',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  contentView: {
    zIndex: 10,
    minHeight: window.innerHeight
  },
  imgView: {
    height: 100,
    width: 100,
  },
  mainTitleText: {
    color: '#FFF',
    fontFamily: 'InterBold',
    fontSize: 45
  },
  cardView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 2,
  },
  titleText: {
    textAlign: 'center',
    fontFamily: 'InterBold',
    fontSize: 30
  },
  subTitleText: {
    textAlign: 'center',
    color: '#a1a5b7',
    fontFamily: 'InterBold',
    fontSize: 20
  },
  cardContentView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textInputView: {
    width: '80%'
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  btnStyleView: {
    fontSize: 14,
    height: 40,
    width: '80%'
  }
};
