import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {useNavigate} from "react-router-dom";

import FormButton from "./FormButton";
import AlertDialogBox from "./AlertDialogBox";

import {connect} from "react-redux";
import {updateApiStatus, updateProductStatus} from "../redux/actions/userDataActions";

import {ReactComponent as TickIcon} from "../assets/img/tick.svg"
import {ReactComponent as DeclineIcon} from "../assets/img/decline.svg";

const buttonList = {
  approved: {
    icon: <TickIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Approve?',
    firstBtn: 'Yes, Approve!',
    secondBtn: 'No, cancel',
  },
  removed: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  }
};

const ProductViewAction = (props) => {
  const [productUid, setProductUid] = useState('');
  const [confirmBoxType, setConfirmBoxType] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props?.apiStatus == 'UPDATE_PRODUCT_STATUS_SUCCESS') {
      setConfirmBoxVisible(false);
      setTimeout(() => {
        setConfirmBoxType("");
        setProductUid('')
      }, 500);
      if (confirmBoxType == 'removed' && props?.page == 'detail') {
        navigate('/product')
      }
    }
  }, [props?.apiStatus])

  const onActionOptionClick = (type, data) => {
    if (type == "detail") {
      navigate("/product/" + data.uid);
    } else {
      setProductUid(data?.uid)
      setConfirmBoxType(type)
      setConfirmBoxVisible(true);
    }
  };

  const onConfirmClick = () => {
    let data = {
      productUid: productUid,
      status: confirmBoxType
    };
    props.updateProductStatus(JSON.stringify(data));
  };

  const onActivateInactivateClick = (status, item) => {
    let data = {
      productUid: item?.uid,
      status: status
    };
    props.updateProductStatus(JSON.stringify(data));
  }

  const onCancelClick = () => {
    setConfirmBoxVisible(false);
    setTimeout(() => {
      setConfirmBoxType("");
      setProductUid('')
    }, 500);
  };

  let item = props?.item

  return (
    <>
      {item?.status == 'pending' ?
        <FormButton
          title={'Approve'}
          btnType={'btn4'}
          width={'100%'}
          onSubmit={() => onActionOptionClick('approved', item)}
        />
        : null}
      {item?.status == 'active' ?
        <Box mt={1}>
          <FormButton
            title={'Inactivate'}
            width={'100%'}
            onSubmit={() => onActivateInactivateClick('inactive', item)}
          />
        </Box>
        : null}
      {item?.status == 'inactive' ?
        <Box mt={1}>
          <FormButton
            title={'Activate'}
            width={'100%'}
            onSubmit={() => onActivateInactivateClick('active', item)}
          />
        </Box>
        : null}
      <Box mt={1}>
        <FormButton
          title={'Delete'}
          btnType={'btn6'}
          width={'100%'}
          onSubmit={() => onActionOptionClick('removed', item)}
        />
      </Box>
      {props?.page == 'list' ?
        <Box mt={1}>
          <FormButton
            title={'Detail'}
            btnType={'btn5'}
            width={'100%'}
            onSubmit={() => onActionOptionClick('detail', item)}
          />
        </Box>
        : null}

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={confirmBoxType}
        icon={confirmBoxType != '' ? buttonList[confirmBoxType]['icon'] : ''}
        content={confirmBoxType != '' ? buttonList[confirmBoxType]['content'] : ''}
        firstBtn={confirmBoxType != '' ? buttonList[confirmBoxType]['firstBtn'] : ''}
        secondBtn={confirmBoxType != '' ? buttonList[confirmBoxType]['secondBtn'] : ''}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProductStatus: (data) => dispatch(updateProductStatus(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductViewAction);
