import * as React from 'react';
import { Box, Toolbar, CssBaseline, CircularProgress } from '@mui/material';

import Sidebar from "./../components/Sidebar";
import Footer from "./../components/Footer";

import { connect } from "react-redux";

import { THEME_COLOR } from "../constants";

function Container(props) {
  return (
    <Box sx={{ display: { md: 'flex' } }}>
      <CssBaseline />
      <Sidebar {...props} />
      <Box component="main"
        sx={styles.mainDiv}>
        <Toolbar />

        {props.listLoading ?
          <Box style={styles.contentView}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <CircularProgress />
          </Box>
          :
          <Box style={styles.contentView}>
            {props.children}
          </Box>}

        <Footer />
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    listLoading: state.userData.listLoading,
  };
};

export default connect(mapStateToProps, null)(Container);

const styles = {
  mainDiv: {
    backgroundColor: '#fff',
    flexGrow: 1,
    minWidth: '100px',
    p: 3,
  },
  contentView: {
    minHeight: window.innerHeight - 140,
  }
};
