import React, {useEffect} from 'react'
import {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import {Card, CardContent} from "@mui/material";
import {COUNTRIES} from "../constants/countries";

const SubTitleView = ({title, subtitle}) => {
  return (
    <Grid container item mt={2} display={"flex"} flexDirection={"row"} alignItems={"center"} md={12} xs={12}>
      <Grid container item md={5} xs={12}>
        <Typography color="text.secondary" style={styles.titleText}>
          {title}
        </Typography>
      </Grid>
      <Grid container item md={7} xs={12}>
        <Typography color="text.secondary" style={styles.subTitleText}>
          {subtitle ? subtitle : '-'}
        </Typography>
      </Grid>
    </Grid>
  )
};

const CustomerCorporateDetails = (props) => {

  return (
    <Grid item md={12} xs={12}>
      <Box>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
            <SubTitleView title={'Corporate Country'} subtitle={
              COUNTRIES.filter(
                (x) => x.code == props.corporateDetail?.corporateCountry
              )?.[0]?.label
            }/>
            <SubTitleView title={'Type of Entity'} subtitle={props.corporateDetail?.typeOfEntity}/>
            <SubTitleView title={'Name of Entity'} subtitle={props.corporateDetail?.nameOfEntity}/>
          {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

    </Grid>
  )
}

export default CustomerCorporateDetails

const styles = {
  titleText: {
    fontFamily: 'InterBold',
    fontSize: 15,
  },
  subTitleText: {
    fontFamily: 'InterBold',
    fontSize: 13,
  },
};
