import React, {useEffect} from 'react'
import {useState} from 'react';
import Grid from '@mui/material/Grid';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import AddCategoryModal from "../../components/AddCategoryModal";
import Page from "../../components/Page";
import AlertDialogBox from "../../components/AlertDialogBox";

import '../../assets/css/custom.css';

import {connect} from "react-redux";
import {addCategory, listCategory, updateCategoryFeatured, deleteCategory, updateApiStatus} from "../../redux/actions/userDataActions";

import {ReactComponent as CheckBoxIcon} from "../../assets/img/checkbox.svg";
import {ReactComponent as UnCheckBoxIcon} from "../../assets/img/uncheckbox.svg";
import {ReactComponent as DeclineIcon} from "../../assets/img/decline.svg";

const buttonList = {
  removed: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  },
};

const Category = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [open, setOpen] = useState(false);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [categoryUid, setCategoryUid] = useState('');
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    getCategoryList(1);
  }, []);

  useEffect(() => {
    setCategoryList(props.categoryList)
  }, [props.categoryList])

  useEffect(() => {
    if(props?.apiStatus == 'DELETE_CATEGORY_SUCCESS') {
      setCategoryUid('')
      setConfirmBoxVisible(false)
      props?.updateApiStatus('')
    } else if(props?.apiStatus == 'DELETE_CATEGORY_FAIL') {
      setCategoryUid('')
      setConfirmBoxVisible(false)
      props?.updateApiStatus('')
    }
  },[props?.apiStatus])

  const getCategoryList = (page) => {
    let data = {
      page: page
    };
    props.listCategory(JSON.stringify(data))
    setPageNum(page)
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmitBtnClick = (data) => {
    props.addCategory(data)
    setOpen(false);
  };

  const onFeaturedClick = (item, index) => {
    let featured_val = !item.featured;
    if (featured_val) {
      featured_val = 1
    } else {
      featured_val = 0
    }
    let data = {
      category_uid: item.uid,
      featured: featured_val
    };
    props.updateCategoryFeatured(JSON.stringify(data));
    let categoryListArray = [...categoryList];
    categoryListArray[index]['featured'] = featured_val;
    setCategoryList(categoryListArray);
  };

  const onDeleteCategory = (uid) => {
    setCategoryUid(uid);
    setConfirmBoxVisible(true);
  };

  const onConfirmClick = () => {
    let data = {
      categoryUid: categoryUid
    };
    props?.deleteCategory(JSON.stringify(data))
  };

  const onCancelClick = () => {
    setCategoryUid('');
    setConfirmBoxVisible(false);
  };

  return (
    <Container page={"category"}>
      <Grid item md={12} xs={12}
            container
            display={"flex"}
            alignItems={"center"}>
        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
          <h2>Category</h2>
          <FormButton
            btnType={'btn5'}
            onSubmit={handleOpen}
            title={"Add Category"}
          />
        </Grid>

        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              mt={3}
              style={styles.cardDiv}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryList.length > 0 ?
                  categoryList.map((item, index) => (
                    <TableRow
                      key={index}>
                      <TableCell
                        onClick={() => onFeaturedClick(item, index)}
                        sx={styles.featuredNameView}>
                        {item.featured ?
                          <CheckBoxIcon height={20} width={20}/>
                          : <UnCheckBoxIcon height={20} width={20}/>}
                        <Box ml={2}>
                          {item.name}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <img src={item.image} height={50} width={50} style={styles.listImageView}/>
                      </TableCell>
                      <TableCell>{item.timestamp}</TableCell>
                      <TableCell>
                        <Box sx={styles.pointerView}>
                          <FormButton
                            title={'Delete'}
                            btnType={'btn7'}
                            width={'50%'}
                            onSubmit={() => onDeleteCategory(item.uid)}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
            {props.categoryTotalPages > 0 ?
              <Page
                totalPages={props.categoryTotalPages}
                pageNum={pageNum}
                onSelectPage={getCategoryList}/>
              : null}
          </Box>
        </Grid>
      </Grid>
      <div>
        <AddCategoryModal
          open={open}
          onClose={handleClose}
          onSubmit={onSubmitBtnClick}/>
      </div>

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={'removed'}
        icon={buttonList['removed']['icon']}
        content={buttonList['removed']['content']}
        firstBtn={buttonList['removed']['firstBtn']}
        secondBtn={buttonList['removed']['secondBtn']}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    categoryLoading: state.userData.categoryLoading,
    categoryTotalPages: state.userData.categoryTotalPages,
    categoryList: state.userData.categoryList,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCategory: data => dispatch(addCategory(data)),
    listCategory: data => dispatch(listCategory(data)),
    updateCategoryFeatured: data => dispatch(updateCategoryFeatured(data)),
    deleteCategory: data => dispatch(deleteCategory(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);

const styles = {
  cardDiv: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFF',
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  listImageView: {
    borderRadius: 10
  },
  featuredNameView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  pointerView: {
    cursor: 'pointer'
  }
};

