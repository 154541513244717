import React, {useEffect} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Card, CardContent} from "@mui/material";
import {COUNTRIES} from "../constants/countries";

const SubTitleView = ({title, subtitle}) => {
  return (
    <Grid container item mt={2} display={"flex"} flexDirection={"row"} alignItems={"center"} md={12} xs={12}>
      <Grid container item md={5} xs={12}>
        <Typography color="text.secondary" style={styles.titleText}>
          {title}
        </Typography>
      </Grid>
      <Grid container item md={7} xs={12}>
        <Typography color="text.secondary" style={styles.subTitleText}>
          {subtitle ? subtitle : '-'}
        </Typography>
      </Grid>
    </Grid>
  )
};

const SellerContactDetails = (props) => {

  return (
    <Grid item md={12} xs={12}>
      <Box>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        <SubTitleView title={'First Name'} subtitle={props.contactDetail?.firstName}/>
        <SubTitleView title={'Last Name'} subtitle={props.contactDetail?.lastName}/>
        <SubTitleView title={'Country'} subtitle={
          COUNTRIES.filter(
            (x) => x.code == props.contactDetail?.corporateCountry
          )?.[0]?.label
        }
        />
        <SubTitleView title={'Designation'} subtitle={props.contactDetail?.designation}/>
        <SubTitleView title={'BusinessAddress'} subtitle={props.contactDetail?.businessAddress}/>
        <SubTitleView title={'City'} subtitle={props.contactDetail?.city}/>
        <SubTitleView title={'State/Province'} subtitle={props.contactDetail?.stateProvince}/>
        <SubTitleView title={'Zip Code'} subtitle={props.contactDetail?.zipCode}/>
        <SubTitleView title={'Email'} subtitle={props.contactDetail?.email}/>
        <SubTitleView title={'Mobile'}
                      subtitle={props.contactDetail?.mobileNo ? (props.contactDetail?.mobileNoCountry + ' ' +
                        props.contactDetail?.mobileNoCode + ' ' + props.contactDetail?.mobileNo) : ''}/>
        <SubTitleView title={'WhatsApp'}
                      subtitle={props.contactDetail?.whatsAppNo ? (props.contactDetail?.whatsAppNoCountry + ' ' +
                        props.contactDetail?.whatsAppNoCode + ' ' + props.contactDetail?.whatsAppNo) : ''}/>
        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

    </Grid>
  )
}

export default SellerContactDetails

const styles = {
  titleText: {
    fontFamily: 'InterBold',
    fontSize: 15,
  },
  subTitleText: {
    fontFamily: 'InterBold',
    fontSize: 13,
  },
};
