import React, {useEffect} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Card, CardContent} from "@mui/material";


const SellerProductDetails = (props) => {

  return (
    <Grid item md={12} xs={12}>
      <Box>
        {/*<Card elevation={0}>*/}
        {/*  <CardContent>*/}
        {props?.productDetail?.categoryList?.length > 0 ?
          <Grid container item mt={2} display={"flex"} flexDirection={"row"} alignItems={"center"} md={12} xs={12}>
            <Grid container item md={5} xs={12}>
              <Typography color="text.secondary" style={styles.titleText}>
                Categories
              </Typography>
            </Grid>
            <Grid container item md={7} xs={12} display={'flex'} flexDirection={'column'}>
              {props?.productDetail?.categoryList.map((item, index) => {
                return (
                  <Box key={index} mt={0.5}>
                    <Typography color="text.secondary" style={styles.subTitleText}>
                      {item?.label}
                    </Typography>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
          : null}
        {/*</CardContent>*/}
        {/*</Card>*/}
      </Box>

    </Grid>
  )
}

export default SellerProductDetails

const styles = {
  titleText: {
    fontFamily: 'InterBold',
    fontSize: 15,
  },
  subTitleText: {
    fontFamily: 'InterBold',
    fontSize: 13,
  },
};
