import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

const Footer = () => {

  return (
    <Grid container
          direction="row"
          mt={2}>
      <Typography style={styles.footerText}>
        2023 ©
      </Typography>
      <Box ml={1}>
        <Typography style={styles.footerTitleText}>
          Trebbs
        </Typography>
      </Box>
    </Grid>
  );
};

export default Footer;

const styles = {
  footerText: {
    color: '#a1a5b7',
    fontSize: 13,
    fontFamily: 'InterMedium',
  },
  footerTitleText:{
    color: '#3f4254',
    fontSize: 13,
    fontFamily: 'InterMedium',
  }
};
