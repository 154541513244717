import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Card, CardContent, CircularProgress} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import Page from "./Page";
import CartReviewPendingList from "./CartReviewPendingList";
import AlertDialogBox from "./AlertDialogBox";

import {connect} from "react-redux";
import {deleteCart, listCartReviewPending, updateApiStatus} from "../redux/actions/userDataActions";

import styles from './CartReviewPending.module.css'

import {ReactComponent as DeclineIcon} from "../assets/img/decline.svg";
import CartCheckoutPending from "./CartCheckoutPending";

const buttonList = {
  removed: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to Delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  }
};

const CartReviewPending = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [cartUid, setCartUid] = useState('');
  const [type, setType] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  useEffect(() => {
    if (props.apiStatus == 'DELETE_CART_SUCCESS') {
      setType('');
      setCartUid('');
      setConfirmBoxVisible(false);
      props.updateApiStatus();
    }
  }, [props.apiStatus])

  useEffect(() => {
    getCartReviewPendingList(1);
  }, []);

  const getCartReviewPendingList = (page) => {
    let data = {
      page: page,
      status: 'review_pending'
    };
    props.listCartReviewPending(JSON.stringify(data))
    setPageNum(page)
  };

  const onPageSelect = (page) => {
    getCartReviewPendingList(page);
  };

  const onDeleteCart = (cart_uid) => {
    setType('removed');
    setCartUid(cart_uid);
    setConfirmBoxVisible(true);
  };

  const onConfirmClick = () => {
    let data = {
      cartUid: cartUid,
      currentStatus: 'review_pending'
    };
    props.deleteCart(JSON.stringify(data))
  };

  const onCancelClick = () => {
    setType('');
    setCartUid('');
    setConfirmBoxVisible(false);
  };

  if (props.cartLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cart ID</TableCell>
              <TableCell className={styles.dataView}>Product</TableCell>
              <TableCell>Buyer</TableCell>
              <TableCell>Seller</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Total Quantity</TableCell>
              <TableCell>Total Product Price</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.cartReviewPendingList.length > 0 ?
              props.cartReviewPendingList.map((item, index) => (
                <TableRow
                  className={styles.tableRow}
                  key={index}>

                  <CartReviewPendingList
                    key={index}
                    index={index}
                    data={item}
                    currencyRateList={props?.currencyRateList}
                    onDeleteCart={onDeleteCart}
                  />
                  {/*<Grid className={styles.borderBottomLine}/>*/}
                </TableRow>
              )) : null}
          </TableBody>
        </Table>
      </TableContainer>


      <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
        {props.cartReviewPendingTotalPages > 0 ?
          <Page
            totalPages={props.cartReviewPendingTotalPages}
            pageNum={pageNum}
            onSelectPage={onPageSelect}/>
          : null}
      </Box>

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={type}
        icon={type != '' ? buttonList[type]['icon'] : ''}
        content={type != '' ? buttonList[type]['content'] : ''}
        firstBtn={type != '' ? buttonList[type]['firstBtn'] : ''}
        secondBtn={type != '' ? buttonList[type]['secondBtn'] : ''}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cartReviewPendingTotalPages: state.userData.cartReviewPendingTotalPages,
    cartReviewPendingList: state.userData.cartReviewPendingList,
    cartLoading: state.userData.cartLoading,
    apiStatus: state.userData.apiStatus,
    currencyRateList: state.userData.currencyRateList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listCartReviewPending: data => dispatch(listCartReviewPending(data)),
    deleteCart: data => dispatch(deleteCart(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartReviewPending);
