import React, {useEffect, useState} from 'react'
import {Grid, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import {v4 as uuidv4} from "uuid";
import {useNavigate, useLocation} from 'react-router-dom'

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import UploadDocument from "../../components/UploadDocument";

import {connect} from "react-redux";
import {updateApiStatus, getOrderDetail, uploadOrderDocuments} from "../../redux/actions/userDataActions";

import styles from './UploadDocuments.module.css'
import OrderTabList from "../../components/OrderTabList";

const UploadDocuments = (props) => {
  const [document, setDocument] = useState([{id: uuidv4()}]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let order_uid = location.pathname.split("/").pop()
    let data = {
      orderUid: order_uid,
    };
    props.getOrderDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (props.apiStatus == 'UPLOAD_ORDER_DOCUMENTS_SUCCESS') {
      props.updateApiStatus('')
      navigate('/orders')
    }
  }, [props?.apiStatus])

  const onRemoveImage = (id) => {
    let document_list = [...document];
    let findIndex = document_list.findIndex((item) => item?.id == id);
    if (findIndex >= 0) {
      document_list[findIndex] = {
        id: id,
        name: document_list[findIndex]?.name
      }
    }
    setDocument(document_list)
  };

  const onChangeImage = (id, fileList) => {
    let document_list = [...document];
    let findIndex = document_list.findIndex((item) => item?.id == id);
    if (findIndex >= 0) {
      document_list[findIndex] = {
        ...document_list[findIndex],
        image: fileList[0].file,
        display_image: fileList[0].image,
      }
    }
    setDocument(document_list)
  };

  const onSetDocumentName = (id, val) => {
    let document_list = [...document];
    let findIndex = document_list.findIndex((item) => item?.id == id);
    if (findIndex >= 0) {
      document_list[findIndex] = {
        ...document_list[findIndex],
        name: val
      }
    }
    setDocument(document_list)
  };

  const onAddMoreClick = () => {
    setDocument([...document, {id: uuidv4()}])
  };

  const onUploadClick = () => {
    let errorText = {};
    if (document.length == 0) {
      errorText = {...errorText, document: 'Documents is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    } else {
      setError(null)
    }

    let document_list = [];
    let doc_err = 0;
    document.map((x) => {
      let item = x;
      let err = {};
      if ((typeof item?.name == 'undefined') || item?.name == "") {
        err = {
          name: "Required",
        };
        doc_err++;
      }
      if ((typeof item?.image == 'undefined') || item?.image == null) {
        err = {
          ...err,
          image: "Required",
        };
        doc_err++;
      }
      document_list.push({...item, error: err});
    });

    if (doc_err > 0) {
      setDocument(document_list);
      return;
    }

    let document_name = [];
    let document_image = [];
    document.map((item, index) => {
      document_name.push(item?.name);
      document_image.push(item?.image);
    });

    let formData = new FormData();
    let orderUid = location.pathname.split("/").pop()
    formData.append('orderUid', orderUid);
    if (document_name.length > 0) {
      formData.append('documentName', JSON.stringify(document_name));
    }
    if (document_image.length > 0) {
      document_image.forEach(y => {
        formData.append("documentImage", y);
      });
    }

    props.uploadOrderDocuments(formData)
  }

  const onRemoveDocument = (id) => {
    setDocument([...document].filter(x => x.id != id));
  }

  return (
    <Container page={"UploadDocuments"}>
      <Grid item md={12} xs={12}
            container
            display={"flex"}
            alignItems={"center"}
            className={styles.cardDiv} p={2}>

        <Grid item md={12} xs={12}>
          <Typography className={styles.uploadDocumentText} mb={4}>Upload Document</Typography>
        </Grid>


        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography className={styles.labelText}>Seller</Typography>
                  <Typography className={styles.subLabelText} ml={1}>TGSIN</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>SKU</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Product ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Product</Typography>
                  <Typography className={styles.subLabelText} ml={1}>HS code</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Buyer</Typography>
                  <Typography className={styles.subLabelText} ml={1}>TGBIN</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Price/Unit</Typography>
                  <Typography className={styles.subLabelText} ml={1}>EXW/Unit</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order Quantity</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Pickup Location</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order ID</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Order Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>EXW Total</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Shipping Cost</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order Total</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>M. Shipping</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Cargo ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Delivery Date</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Date of Shipping</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={styles.labelText}>Order Status</Typography>
                  <Typography className={styles.subLabelText} ml={1}>Payment Status</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.orderDetail != null ?
                <TableRow>
                  <OrderTabList
                    tab={'uploadDocument'}
                    data={props.orderDetail}
                  />
                </TableRow>
                : null}
            </TableBody>
          </Table>
        </TableContainer>


        <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>

        <Grid container item md={12} xs={12}
              display={'flex'} flexDirection={'row'} alignItems={'center'}
              justifyContent={'space-between'}
              mt={4}>
          <Grid container item md={5} xs={5}>
            <Grid display={"flex"}
                  flexDirection={"row"}
                  alignItems={'flex-start'}
                  item md={12} xs={12}
                  container>
              <Typography className={styles.docLabelText}>Document Name</Typography>
              <Box pl={1}>
                <Typography className={styles.requiredText}>*</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={3} xs={3}>
            <Grid display={"flex"}
                  flexDirection={"row"}
                  alignItems={'flex-start'}
                  item md={12} xs={12}
                  container>
              <Typography className={styles.docLabelText}>File</Typography>
              <Box pl={1}>
                <Typography className={styles.requiredText}>*</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={3} xs={3}>
            <Typography className={styles.docLabelText}>Action</Typography>
          </Grid>
        </Grid>

        {document.map((item, index) => {
          return (
            <UploadDocument
              key={item?.id}
              documentId={item?.id}
              documentName={item?.name}
              setDocumentName={onSetDocumentName}
              displayDocumentImage={item?.display_image}
              errorDocumentName={item?.error?.name}
              errorDocumentImage={item?.error?.image}
              onRemoveImage={onRemoveImage}
              onChangeImage={onChangeImage}
              onRemoveDocument={onRemoveDocument}
            />
          )
        })}

        {error?.document ?
          <Grid item md={12} xs={12} mt={3}>
            <Typography className={styles.errorDocument}>{error?.document}</Typography>
          </Grid>
          : null}

        <Grid item md={12} xs={12} mt={3}>
          <Typography className={styles.addMoreText} onClick={() => onAddMoreClick()}>Add more</Typography>
        </Grid>

        <Grid item md={12} xs={12} className={styles.borderBottomLine} mt={4}/>

        <Grid item md={12} xs={12} mt={3}
              container
              display={'flex'} justifyContent={'flex-end'}>
          <FormButton
            title={"Upload"}
            loading={props?.btnLoading}
            btnStyleView={{height: 35}}
            onSubmit={onUploadClick}
          />
        </Grid>
      </Grid>

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
    btnLoading: state.userData.btnLoading,
    orderDetail: state.userData.orderDetail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    getOrderDetail: data => dispatch(getOrderDetail(data)),
    uploadOrderDocuments: data => dispatch(uploadOrderDocuments(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);


const style = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
};
