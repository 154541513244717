import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';

import Grid from '@mui/material/Grid';
import {Typography, Box} from '@mui/material';

import Container from "../../components/Container";
import OrderDetailView from "../../components/OrderDetailView";
import OrderPackingDetailView from "../../components/OrderPackingDetailView";
import OrderShippingDetailView from "../../components/OrderShippingDetailView";
import OrderPaymentDetailView from "../../components/OrderPaymentDetailView";
import FormButton from "../../components/FormButton";
import FormTextArea from "../../components/FormTextArea";

import styles from "./OrderDetail.module.css"

import {connect} from "react-redux";
import {getOrderDetail, messageToSeller, getCurrencyRateList, updateApiStatus} from "../../redux/actions/userDataActions";
import OrderTab from "../../components/OrderTab";

const OrderDetail = (props) => {
  const [orderUid, setOrderUid] = useState('');
  const [pageType, setPageType] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    props?.getCurrencyRateList()
  },[])

  useEffect(() => {
    if (props.apiStatus == 'GET_ORDER_DETAIL_FAIL') {
      props.updateApiStatus('');
      navigate('/orders')
    }
  }, [props.apiStatus])

  useEffect(() => {
    let order_uid = location.pathname.split("/").pop();
    setOrderUid(order_uid);
    let data = {
      orderUid: order_uid,
    };
    props.getOrderDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    if (props?.orderDetail != null) {
      let data = props?.orderDetail;
      let page_type = 'detail'
      if (data?.cancelled_reason) {
        page_type = 'cancel'
      }
      setPageType(page_type)
    }
  }, [props?.orderDetail])

  const onMessageToSeller = () => {
    let errorText = {};
    if (message == '') {
      errorText = {...errorText, message: 'Message is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    setError(null)
    let data = {
      orderUid: orderUid,
      message: message
    };
    props.messageToSeller(JSON.stringify(data));
  }

  return (
    <Container page={'orders'}>

      <Grid container item md={12} xs={12} mt={4}>
        <Typography
          className={styles.mainLabelText}>{pageType == 'cancel' ? 'Cancelled Orders' : 'Order Details'}</Typography>
      </Grid>

      <Grid container item md={12} xs={12} mt={1}>
        <FormButton
          title={'Back to Orders'}
          btnStyleView={style.btnStyleView}
          bgColorStyleView={'#000'}
          onSubmit={() => navigate('/orders')}
        />
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Order Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderDetailView
            currencyRateList={props?.currencyRateList}
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Packing Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderPackingDetailView
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Shipping Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderShippingDetailView
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Payment Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderPaymentDetailView
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      {pageType == 'cancel' ?
        <>
          <Grid container item md={12} xs={12} mt={4}
                display={'flex'}
                flexDirection={'column'}
                className={styles.borderView} p={2}>
            <Grid display={"flex"}
                  flexDirection={"row"} item md={12} xs={12} mb={2}
                  container>
              <Typography className={styles.labelText}>Reason for cancellation</Typography>
            </Grid>

            <Grid container item md={12} xs={12}
                  display={'flex'}
                  alignItems={'center'} justifyContent={'flex-start'}>
              <Grid container item md={7} xs={7}>
                <FormTextArea
                  disabled={true}
                  page={'OrderDetail'}
                  placeholder={'Reason'}
                  value={props?.orderDetail?.cancelled_reason}
                  textInputViewStyle={style.textInputViewStyle}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item md={12} xs={12} mt={4}
                display={'flex'}
                flexDirection={'column'}
                className={styles.borderView} p={2}>
            <Grid display={"flex"}
                  flexDirection={"row"} item md={12} xs={12} mb={2}
                  container>
              <Typography className={styles.labelText}>Message to seller</Typography>
            </Grid>

            <Grid container item md={12} xs={12}
                  display={'flex'}
                  alignItems={'center'} justifyContent={'flex-start'}>
              <Grid container item md={7} xs={7}>
                <FormTextArea
                  disabled={props?.orderDetail?.message_to_seller}
                  page={'OrderDetail'}
                  placeholder={'Type here'}
                  value={props?.orderDetail?.message_to_seller ? props?.orderDetail.message_to_seller : message}
                  setValue={setMessage}
                  error={error?.message}
                  textInputViewStyle={style.textInputViewStyle}
                />
              </Grid>
            </Grid>
          </Grid>

          {!props?.orderDetail?.message_to_seller ?
            <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}
                  justifyContent={'center'}
                  mt={3}>
              <Box className={styles.btnView}>
                <FormButton
                  loading={props?.orderBtnLoading}
                  title={'Submit Message'}
                  btnType={'btn4'}
                  onSubmit={() => onMessageToSeller()}
                />
              </Box>
            </Grid>
            : null}
        </>
        : null}

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    orderDetail: state.userData.orderDetail,
    orderBtnLoading: state.userData.orderBtnLoading,
    currencyRateList: state.userData.currencyRateList,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderDetail: data => dispatch(getOrderDetail(data)),
    messageToSeller: data => dispatch(messageToSeller(data)),
    getCurrencyRateList: data => dispatch(getCurrencyRateList(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);

const style = {
  btnStyleView: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 5,
    height: 30,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #d3d3d3',
  },
}
