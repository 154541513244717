import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import moment from "moment";

import styles from './OrderDetailView.module.css'

import {getCurrencyConversionCalc, priceCalculation, unshippedOrderStatus} from "../assets/functions/common";

import {COUNTRIES} from "../constants/countries";

const OrderDetailView = (props) => {
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [totalPriceWithoutReferralFee, setTotalPriceWithoutReferralFee] = useState(0);
  const [pricePerKgWithoutReferralFee, setPricePerKgWithoutReferralFee] = useState(0);
  const [referralFeePerPackage, setReferralFeePerPackage] = useState(0);
  const [totalReferralFee, setTotalReferralFee] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [actualPrice, setActualPrice] = useState(0);
  const [actualPricePerKg, setActualPricePerKg] = useState(0);
  const [actualTotalPrice, setActualTotalPrice] = useState(0);
  const [actualShippingCost, setActualShippingCost] = useState('');
  const [actualTotalPriceWithoutReferralFee, setActualTotalPriceWithoutReferralFee] = useState(0);
  const [actualPricePerKgWithoutReferralFee, setActualPricePerKgWithoutReferralFee] = useState(0);
  const [actualReferralFeePerPackage, setActualReferralFeePerPackage] = useState(0);
  const [actualTotalReferralFee, setActualTotalReferralFee] = useState(0);
  const [actualCargoPrice, setActualCargoPrice] = useState(0);
  const [actualCurrencySymbol, setActualCurrencySymbol] = useState('')

  const [orderStatus, setOrderStatus] = useState('');

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {
        let currency_symbol = '';
        let price_val = 0;
        let price_per_kg = 0;
        let total_price = 0;
        let shipping_cost = 0;
        let total_price_without_referral_fee = 0;
        let price_per_kg_without_referral_fee = 0;
        let referral_fee_per_package = 0;
        let total_referral_fee = 0;
        let cargo_price = 0;
        let actual_currency_symbol = '';
        let actual_price_val = 0;
        let actual_price_per_kg = 0;
        let actual_total_price = 0;
        let actual_shipping_cost = 0;
        let actual_cargo_price = 0;
        let actual_total_price_without_referral_fee = 0;
        let actual_price_per_kg_without_referral_fee = 0;
        let actual_referral_fee_per_package = 0;
        let actual_total_referral_fee = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props?.data?.shipping_cost ? props?.data?.shipping_cost : 0,
          currency: props?.data?.seller_currency,
          admin_currency: props?.data?.admin_currency,
          admin_currency2: props?.data?.admin_currency2,
          admin_rate: props?.data?.admin_rate,
          usd_rate: props?.data?.usd_rate
        }

        let data = getCurrencyConversionCalc(data_list, props?.currencyRateList)
        if (data != null) {
          actual_price_val = data?.price;
          actual_price_per_kg = data?.price_per_kg;
          actual_total_price = data?.total_price;
          actual_shipping_cost = data?.shipping_cost;
          actual_cargo_price = data?.cargo_price;
          actual_currency_symbol = data?.currency_symbol;
          actual_total_price_without_referral_fee = data?.total_price_without_referral_fee;
          actual_price_per_kg_without_referral_fee = data?.price_per_kg_without_referral_fee;
          actual_referral_fee_per_package = data?.referral_fee_per_package;
          actual_total_referral_fee = data?.total_referral_fee;
          price_val = data?.converted_price;
          price_per_kg = data?.converted_price_per_kg;
          total_price = data?.converted_total_price;
          total_price_without_referral_fee = data?.converted_total_price_without_referral_fee;
          price_per_kg_without_referral_fee = data?.converted_price_per_kg_without_referral_fee;
          referral_fee_per_package = data?.converted_referral_fee_per_package;
          total_referral_fee = data?.converted_total_referral_fee;
          shipping_cost = data?.converted_shipping_cost;
          cargo_price = data?.converted_cargo_price;
          currency_symbol = data?.admin_currency_symbol;
        }

        setPrice(price_val);
        setTotalPrice(total_price);
        setPricePerKg(price_per_kg);
        setCargoPrice(cargo_price)
        setShippingCost(shipping_cost)
        setTotalPriceWithoutReferralFee(total_price_without_referral_fee);
        setPricePerKgWithoutReferralFee(price_per_kg_without_referral_fee)
        setReferralFeePerPackage(referral_fee_per_package);
        setTotalReferralFee(total_referral_fee);
        setCurrencySymbol(currency_symbol)

        setActualPrice(actual_price_val);
        setActualTotalPrice(actual_total_price)
        setActualPricePerKg(actual_price_per_kg);
        setActualCargoPrice(actual_cargo_price)
        setActualShippingCost(actual_shipping_cost)
        setActualTotalPriceWithoutReferralFee(actual_total_price_without_referral_fee);
        setActualPricePerKgWithoutReferralFee(actual_price_per_kg_without_referral_fee)
        setActualReferralFeePerPackage(actual_referral_fee_per_package);
        setActualTotalReferralFee(actual_total_referral_fee);
        setActualCurrencySymbol(actual_currency_symbol)

      }

      if (props?.data?.order_status == 'unshipped') {
        // if (props?.data?.unshipped_timestamp) {
        //   let order_status = unshippedOrderStatus(props?.data?.unshipped_timestamp);
        //   setOrderStatus(order_status);
        // }
        if(props?.data?.payment_status == 'paid') {
          setOrderStatus('Pickup Scheduled');
        } else {
          setOrderStatus('Confirmed')
        }
      } else {
        setOrderStatus(props?.data?.order_status)
      }
    }

  }, [props.data, props?.currencyRateList])

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Order ID</Typography>
                <Typography className={styles.subTitleText} ml={1}>Order Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Buyer</Typography>
                <Typography className={styles.subTitleText} ml={1}>Seller</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Product</Typography>
                <Typography className={styles.subTitleText} ml={1}>Product ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>HS Code</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Variety</Typography>
                <Typography className={styles.subTitleText} ml={1}>Packing</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Pickup Location</Typography>
                <Typography className={styles.subTitleText} ml={1}>Delivery Location</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Quantity</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Price/Unit</Typography>
                <Typography className={styles.subTitleText} ml={1}>EXW/Unit</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>EXW Total</Typography>
                <Typography className={styles.subTitleText} ml={1}>Shipping Cost</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Total</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Status</Typography>
                <Typography className={styles.subTitleText} ml={1}>Payment Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.order_uid ? '#' + item.order_uid : '-'}</Typography>
                <Typography
                  className={styles.subValueText}
                  mt={1}>{item?.order_timestamp ? item.order_timestamp : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  Buyer: {item?.user_name_entity ? item?.user_name_entity : ''}
                  {item?.user_country ? '(' + COUNTRIES.filter(
                    (x) => x.code == item?.user_country
                  )?.[0]?.label + ')' : '-'}
                </Typography>
                <Typography
                  className={styles.subValueText} mt={1}>
                  Seller: {item?.seller_name_entity ? item?.seller_name_entity : ''}
                  {item?.seller_country ? '(' + COUNTRIES.filter(
                    (x) => x.code == item?.seller_country
                  )?.[0]?.label + ')' : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.name ? item.name : '-'}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  {item?.product_uid ?  '#' + item.product_uid : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.hs_code ? (item.hs_code + ' - ' + item.hs_code_title) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.variety ? item.variety : '-'}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  {item?.package_net_weight ? (item?.package_net_weight + item?.unit + ' ' + item?.packing_material) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  Pickup Location : {item?.pickup_location ? item.pickup_location : '-'}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  Delivery Location : {item?.address_line_1 ?
                    (item.address_line_1 + ', ' + item.address_line_2 + ', ' + item.city
                    + ', ' + item.state_province + ', ' + item.zip_code)
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.quantity ? (item?.package_net_weight * item?.quantity) + item?.unit : 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>Pirce/Unit: {currencySymbol}{pricePerKgWithoutReferralFee?.toFixed(2)} / Kg</Typography>
                <Typography className={styles.subValueText} mt={1}>EXW/Unit: {currencySymbol}{pricePerKg?.toFixed(2)} / Kg</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {currencySymbol}{totalPrice ? totalPrice?.toFixed(2) : 0}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  Shipping Cost: {currencySymbol}{shippingCost ? shippingCost?.toFixed(2) : 0}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  Referral Fee: {currencySymbol}{referralFeePerPackage ? (referralFeePerPackage * item?.quantity)?.toFixed(2) : 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.subValueText}>
                  {actualCurrencySymbol}{actualCargoPrice ? actualCargoPrice?.toFixed(2) : 0}
                </Typography>
                <Typography className={styles.valueText} pl={3}>
                  ({currencySymbol}{cargoPrice ? cargoPrice?.toFixed(2) : 0})
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  Order Status: {orderStatus ? orderStatus?.replaceAll("_", " ") : '-'}
                </Typography>
                <Typography className={styles.subPayValueText} mt={1}>
                  Payment Status: {item?.payment_status ?  (item.payment_status == 'half_paid' ? '50% Paid' : item.payment_status) : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default OrderDetailView;
